import {FileFilterType, FileFolderTree, FileOrFolder, MinimalFileObject} from '@components/file-object/types';
import {orderBy, sortBy} from 'lodash';
import {Box, Collapse, Grid, Grow} from '@mui/material';
import * as React from 'react'
import {useMemo} from 'react'
import {fieldChooser} from "@components/file-object/utils";
import {generateDebug} from "@/utils";
import {
  DocumentGridItem,
  DraggableGridItem,
  FolderGridItem,
  ImageGridItem,
  VideoGridItem
} from "@components/file-object/FileGridItems";
import {
  DataRef, defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent, DragOverlay, DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {useFolderUtils} from "@/hooks/use-folder-utils";

const debug = generateDebug('FileGrid')

interface FileGridProps {
  organizationId: string
  filter?: FileFilterType
  sortMode?: 'name' | 'date'
  sortDirection?: 'asc' | 'desc'

  selectType?: FileFilterType,
  onSelect?: (val: FileOrFolder) => void
  onRename?: (val: FileOrFolder) => void
  onDelete?: (val: FileOrFolder) => void
  folder: FileFolderTree
  onGoToFolder: (folder: FileFolderTree) => void

}


export function FileGrid<T extends MinimalFileObject>(props: FileGridProps) {
  const pointer = useSensor(PointerSensor, {activationConstraint: {distance: 5}})
  const touch = useSensor(TouchSensor, {activationConstraint: {distance: 5}})
  const mouse = useSensor(MouseSensor, {activationConstraint: {distance: 5}})
  const sensors = useSensors(touch, mouse, pointer)
  const [movingItem, setMovingItem] = React.useState<FileOrFolder | undefined>()
  const [movedItemId, setMovedItemId] = React.useState<string | undefined>()
  // Setup video preview
  const [playing, setPlaying] = React.useState<MinimalFileObject>()

  const startPlaying = (item: MinimalFileObject) => {
    if (playing) {
      const curr = (document.getElementById('video_' + playing.id) as HTMLVideoElement)
      if (curr) {
        curr.onpause = null
        curr.onended = null
        curr.pause()
      }
    }
    if (playing === item) {
      setPlaying(undefined)
      return
    }
    const next = document.getElementById('video_' + item.id) as HTMLVideoElement
    if (next) {
      next.onpause = () => {
        setPlaying(undefined);
        next.onpause = null
      }
      next.onended = () => {
        setPlaying(undefined);
        next.onended = null
      }
      next.play().then(() => setPlaying(item))
    }
  }

  // Create folder content, parent folder first, then folders, then files
  const mixedItems = useMemo(() => [
      ...(props.folder.parent ? [props.folder.parent] : []),
      ...orderBy(props.folder.folders, props.sortMode=='date'?['created']:['name'], [props.sortDirection||'asc']),
      ...orderBy(props.folder.files, fieldChooser(props.sortMode||'name'), [props.sortDirection||'asc'])]
      .filter((item) => !(movedItemId === item.id || (props.filter && 'url' in item && item.type !== props.filter))),
    [props.folder, movedItemId, props.filter, props.sortMode, props.sortDirection])

  const {handleMove, handleFileMove} = useFolderUtils(props.organizationId)

  const onDragEnd = (result: DragEndEvent) => {
    debug('onDragEnd', result)
    const {over, active} = result

    const toMove: FileFolderTree | MinimalFileObject | undefined = (active.data as DataRef<FileFolderTree | MinimalFileObject>).current;
    const moveTo = (over?.data as DataRef<FileFolderTree>)?.current;
    if (toMove && moveTo && toMove.id != moveTo.id) {
      setMovedItemId(toMove.id)
      if ('url' in toMove) {
        handleFileMove(toMove, moveTo).then(() => {
          setMovingItem(undefined);
          setMovedItemId(undefined)
        })
      } else {
        handleMove(toMove, moveTo!).then(() => {
          setMovingItem(undefined);
          setMovedItemId(undefined)
        })

      }
    } else {
      setMovingItem(undefined)
    }
  }

  const onDragStart = (result: DragStartEvent) => {
    const {active} = result
    const current = active.data.current as FileOrFolder
    setMovingItem(current)
    debug('onDragStart', current)
  }

  const renderGridItem = (fileOrFolder: FileFolderTree | MinimalFileObject, asOverlay?: boolean) => {
    debug('renderGridItem', fileOrFolder)
    const onDelete = asOverlay ? undefined : props.onDelete
    const onSelect = asOverlay ? undefined : props.onSelect
    const onRename = asOverlay ? undefined : props.onRename

    if ('url' in fileOrFolder) {
      switch (fileOrFolder.type) {
        case 'image':
          return <ImageGridItem
            file={fileOrFolder}
            onDelete={onDelete}
            disabled={props.selectType && props.selectType != 'image'}
            onSelect={onSelect}
            onRename={onRename}/>
        case 'video':
          return <VideoGridItem
            key={fileOrFolder.id}
            file={fileOrFolder}
            disabled={props.selectType && props.selectType != 'video'}
            onSelect={onSelect}
            onDelete={onDelete}
            onRename={onRename}
            isPlaying={playing == fileOrFolder}
            onStartPlaying={startPlaying}/>
        case 'document':
          return <DocumentGridItem
            key={fileOrFolder.id}
            file={fileOrFolder}
            disabled={props.selectType && props.selectType != 'document'}
            onDelete={onDelete}
            onSelect={onSelect}
            onRename={onRename}/>
        default:
          return null
      }
    } else {

      return <FolderGridItem
        key={fileOrFolder.id}
        isParent={fileOrFolder === props.folder.parent}
        onDelete={fileOrFolder === props.folder.parent ? undefined : onDelete}
        folder={fileOrFolder}
        onRename={onRename}
        isDroppable={!asOverlay}
        onGoToFolder={asOverlay ? undefined : props.onGoToFolder}
      />
    }
  };

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div>

        <div style={{
          alignItems: 'stretch',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          rowGap: 8,
          columnGap: 8
        }}>


          {mixedItems.map((fileOrFolder, index) => {
              return (
                <DraggableGridItem
                  key={fileOrFolder.id}
                  item={fileOrFolder}
                >
                  {renderGridItem(fileOrFolder)}
                </DraggableGridItem>
              )
            }
          )}
        </div>
        <DragOverlay dropAnimation={{
          duration: 250,
          easing: 'ease',
          sideEffects: defaultDropAnimationSideEffects({
            styles: {
              active: {
                opacity: '0.5',
              },
            }
          })
        }}>{movingItem && renderGridItem(movingItem, true)}</DragOverlay>
      </div>
    </DndContext>
  )
}
