import {useEffect, useMemo, useState} from "react";
import {generateDebug} from "@/utils";
const debug = generateDebug("useKeydown");

// resolves to true if all of the keys are down, or if any==true if any keys are down
export const useKeydown = (keys: string[], any?: boolean) => {
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const isPressed = (key: string) => pressedKeys.includes(key);
  const isDown = useMemo(() => any?keys.every(k => pressedKeys.includes(k)):keys.some(k => pressedKeys.includes(k)), [pressedKeys, any, keys])
  const onKeyDown = (event: KeyboardEvent) => {
    if (!isPressed(event.key)) {
      setPressedKeys((pressedKeys) => [...pressedKeys, event.key]);
    }
  };
  const onKeyUp = (event: KeyboardEvent) => {
    setPressedKeys((pressedKeys) =>
      pressedKeys.filter((pressedKey) => pressedKey !== event.key)
    );
  };
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);
  return isDown;
}
