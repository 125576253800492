import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLCourseOccasionsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
}>;


export type IGQLCourseOccasionsQuery = { __typename: 'Query', jrnyCourseOccasionsForCourse: Array<{ __typename: 'JrnyCourseOccasion', id: string, courseId: string, date: string, duration: number }> };

export type IGQLCreateCourseOccasionMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateCourseOccasionInput;
}>;


export type IGQLCreateCourseOccasionMutation = { __typename: 'Mutation', jrnyCreateCourseOccasion: { __typename: 'JrnyCourseOccasion', id: string, courseId: string, date: string, duration: number } };

export type IGQLCreateCourseOccasionsMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateCourseOccasionsInput;
}>;


export type IGQLCreateCourseOccasionsMutation = { __typename: 'Mutation', jrnyCreateCourseOccasions: Array<{ __typename: 'JrnyCourseOccasion', id: string, courseId: string, date: string, duration: number }> };

export type IGQLUpdateCourseOccasionMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateCourseOccasionInput;
}>;


export type IGQLUpdateCourseOccasionMutation = { __typename: 'Mutation', jrnyUpdateCourseOccasion: { __typename: 'JrnyCourseOccasion', id: string, courseId: string, date: string, duration: number } };

export type IGQLRemoveCourseOccasionMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCourseOccasionInput;
}>;


export type IGQLRemoveCourseOccasionMutation = { __typename: 'Mutation', jrnyRemoveCourseOccasion: { __typename: 'JrnyCourseOccasion', id: string, courseId: string, date: string, duration: number } };


export const CourseOccasionsDocument = gql`
    query courseOccasions($courseId: ID!) {
  jrnyCourseOccasionsForCourse(courseId: $courseId) {
    id
    courseId
    date
    duration
  }
}
    `;

export function useCourseOccasionsQuery(options: Omit<Urql.UseQueryArgs<IGQLCourseOccasionsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLCourseOccasionsQuery, IGQLCourseOccasionsQueryVariables>({ query: CourseOccasionsDocument, ...options });
};
export const CreateCourseOccasionDocument = gql`
    mutation createCourseOccasion($input: JrnyCreateCourseOccasionInput!) {
  jrnyCreateCourseOccasion(input: $input) {
    id
    courseId
    date
    duration
  }
}
    `;

export function useCreateCourseOccasionMutation() {
  return Urql.useMutation<IGQLCreateCourseOccasionMutation, IGQLCreateCourseOccasionMutationVariables>(CreateCourseOccasionDocument);
};
export const CreateCourseOccasionsDocument = gql`
    mutation createCourseOccasions($input: JrnyCreateCourseOccasionsInput!) {
  jrnyCreateCourseOccasions(input: $input) {
    id
    courseId
    date
    duration
  }
}
    `;

export function useCreateCourseOccasionsMutation() {
  return Urql.useMutation<IGQLCreateCourseOccasionsMutation, IGQLCreateCourseOccasionsMutationVariables>(CreateCourseOccasionsDocument);
};
export const UpdateCourseOccasionDocument = gql`
    mutation updateCourseOccasion($input: JrnyUpdateCourseOccasionInput!) {
  jrnyUpdateCourseOccasion(input: $input) {
    id
    courseId
    date
    duration
  }
}
    `;

export function useUpdateCourseOccasionMutation() {
  return Urql.useMutation<IGQLUpdateCourseOccasionMutation, IGQLUpdateCourseOccasionMutationVariables>(UpdateCourseOccasionDocument);
};
export const RemoveCourseOccasionDocument = gql`
    mutation removeCourseOccasion($input: JrnyCourseOccasionInput!) {
  jrnyRemoveCourseOccasion(input: $input) {
    id
    courseId
    date
    duration
  }
}
    `;

export function useRemoveCourseOccasionMutation() {
  return Urql.useMutation<IGQLRemoveCourseOccasionMutation, IGQLRemoveCourseOccasionMutationVariables>(RemoveCourseOccasionDocument);
};