import {OrganizationContext} from '@/context/OrganizationProvider';
import * as React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import TimerIcon from '@mui/icons-material/Timer';
import {AbilityContext, CurrentUserContext, ShiroContext} from "@/context/CurrentUserProvider";
import {JrnyPermissions} from "@/JrnyPermissions";
import {IconCourse, IconFolder, IconOrganization, IconPerks, IconUsers, IconSettings} from "@/icons";

type MenuShape = { name: string, children: {name: string, icon: any, to: string}[]}
export const useSiteNavigation = () => {
  const {currentUser} = React.useContext(CurrentUserContext);
  const {organization} = React.useContext(OrganizationContext);
  const shiro = React.useContext(ShiroContext);
  const ability = React.useContext(AbilityContext);
  return React.useMemo(() => {
    const result = [];
    const admin: MenuShape = {
      name: 'Admin',
      children: [
        // {
        //   name: 'Maintenance',
        //   icon: <SettingsIcon />,
        //   to: '/admin/maintain'
        // },
      ],
    };
    if (shiro?.check(JrnyPermissions.JRNY_ORGANIZATION_LIST)) {
      admin.children.push({
          name: 'Organizations',
          icon: <IconOrganization/>,
          to: '/admin/organizations'
        }
      );
    }
    if (shiro?.check(JrnyPermissions.JRNY_SETTINGS_VIEW)) {
      admin.children.push({
          name: 'Settings',
          icon: <IconSettings/>,
          to: '/admin/settings'
        }
      );
    }

    const myOrganization: MenuShape|undefined = organization && {
      name: 'Organization: ' + organization.name,
      children: [
        // {name: 'Structure', icon: <IconStructure/>, to: '/organization/structure'},
        {name: 'Files', icon: <IconFolder/>, to: '/organization/files'},
        {name: 'Courses', icon: <IconCourse/>, to: '/organization/courses'},
        {name: 'Perks', icon: <IconPerks/>, to: '/organization/perks'},
        {name: 'Users', icon: <IconUsers/>, to: '/organization/users'},
      ],
    };
    const myStuff: MenuShape = {
      name: 'Me',
      children: [
        {name: 'Invitations', icon: <TimerIcon/>, to: '/me/invitations'},
      ],
    };
    if( admin.children.length) {
      result.push(admin)
    }
    if( currentUser?.organizationIds && currentUser?.organizationIds.length>1
      && ability?.can('list', 'JrnyOrganization')
      && !shiro?.check('journey-admin:organization:list')) {
      myStuff.children.push({
          name: 'Organizations',
          icon: <PeopleIcon/>,
          to: '/admin/organizations'
        }
      );
    }
    if (myOrganization) {
      result.push(myOrganization);
    }
    result.push(myStuff);
    return result;
  }, [organization, shiro, ability]);
};
