import * as React from 'react'
import {FileFolderTree, MinimalFileObject} from "@components/file-object/types";
import {Box, styled} from "@mui/material";

interface RenamableNameProps<T extends MinimalFileObject|FileFolderTree> {
  item: T
  onRename?: ((val:T) => void)
  nameStyle?: React.CSSProperties

}

const Wrapper = styled(Box)(({
  flexFlow: 'nowrap',
  display: 'flex',
}))
const Name = styled('div')(({
  flexShrink: 1,
  flexGrow: 1,
  fontSize: '0.8em',
  overflow: 'hidden',
  whiteSpace: 'normal',
  overflowWrap: 'break-word',
  textAlign: 'center',
  ['.reneameble &:hover ']: {
    textDecoration: 'underline',
  }

}))

export function NonRenamableName(props: {name: string,   nameStyle?: React.CSSProperties
}) {
  return (<Wrapper>
    <Name style={props.nameStyle} >{props.name}</Name>
  </Wrapper>)

}
export function RenamableName<T extends MinimalFileObject|FileFolderTree>(props: RenamableNameProps<T>) {
  const {item, onRename} = props
  const name = 'url' in item? (item.name || item.originalName) : item.name
  const onClick = (evt: { stopPropagation: () => void; }) => {
    if( onRename) {
      onRename(item)
      evt.stopPropagation()
    }
  }

  return (<Wrapper  className={onRename?'reneameble':undefined}>
    <Name onClick={onClick} style={props.nameStyle}>{name}</Name>

  </Wrapper>)
}
