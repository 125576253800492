import {generateDebug} from '@/utils';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import {required} from '@components/form/validation';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {SimpleLesson} from '../types';

const debug = generateDebug('EditLessonDialog');

interface EditLessonDialogProps {
  lesson?: SimpleLesson
  onSave: (item: SimpleLesson, name: string) => void
  onCancel: () => void
}

export default function EditLessonDialog(props: EditLessonDialogProps) {
  const validate = (values: { [index: string]: string }) => {
    const errors = required(['name'], values);
    return errors;
  };

  const handleSubmit = async (values: { name: string }) => {
    debug('handleSubmit', values);
    const result = await props.onSave(props.lesson!, values.name);
  };

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        subscription={{ submitting: true }}
        validate={validate}
        initialValues={{name: props.lesson?.name}}
      >
        {({ handleSubmit: handleSubmit2, form }) => (<>
          <Dialog open={!!props.lesson} onClose={props.onCancel}>
            <DialogTitle>Rename lesson</DialogTitle>
            <DialogContent>
              <Box component='form' onSubmit={handleSubmit2} noValidate sx={{ m: 1 }}>
                <Field
                  autoFocus
                  component={RFTextField}
                  fullWidth
                  label='Lesson name'
                  margin='dense'
                  name='name'
                  type='text'
                  required
                  size='normal'
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onCancel}>Cancel</Button>
              <Button onClick={form.submit}>Rename</Button>
            </DialogActions>
          </Dialog>
        </>)}
      </Form>
    </div>
  );
}
