import { IconContextMenu } from '@/icons';
import {IconButton, Menu, MenuItem} from '@mui/material';
import * as React from 'react'
import ListItemIcon from "@mui/material/ListItemIcon";

export type ContextMenuActionTuple = [string, () => void, React.ReactNode]
interface ItemContextMenuProps {
 items: ContextMenuActionTuple[]
}

export function ItemContextMenu(props: ItemContextMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (action?:() => void) => {
    setAnchorEl(null);
    action?.()
  };

  return (
    <div>
      <IconButton
        onClick={handleOpen}
      >
        <IconContextMenu/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClick()}
      >
        {props.items.map(i => (<MenuItem key={i[0]} onClick={() => handleClick(i[1])}>{i[2]&&<ListItemIcon>{i[2]}</ListItemIcon>}{i[0]}</MenuItem>))}
      </Menu>
    </div>
  );
}
