import {Box, ListItemAvatar, ListItemSecondaryAction, ListItemText, styled, useTheme} from "@mui/material";
import {FileFolderTree, MinimalFileObject} from "@components/file-object/types";
import {MimeTypeIcon} from "@components/file-object/MimeTypeIcon";
import {NonRenamableName, RenamableName} from "@components/file-object/RenamableName";
import {FileInfo} from "@components/file-object/FileInfo";
import IconButton from "@mui/material/IconButton";
import {IconDelete, IconGridFolder} from "@/icons";
import * as React from "react";
import {useDraggable, useDroppable} from "@dnd-kit/core";
import useMergedRef from "@react-hook/merged-ref";

const DividedListItem = styled(Box)({
  borderBottom: '1px solid rgba(0,0,0,.1)',
  display: 'flex',
  flexFlow: 'row nowrap',
  padding: '8px 16px',
  position: 'relative',
  alignItems: 'center',
  backgroundColor: 'white',
  transition: 'background-color .2s ease-in-out',
})
type FolderListItemProps = {
  item: FileFolderTree
  onRename?: (val: (MinimalFileObject | FileFolderTree)) => void
  onGoToFolder: (folder: FileFolderTree) => void
  onDelete?: (val: MinimalFileObject | FileFolderTree) => void
  isParent: boolean
  isDroppable?: boolean
  style?: React.CSSProperties
}

export function DroppableFolderListItem(props: FolderListItemProps) {
  const {item} = props

  const theme = useTheme()
  const {isOver, setNodeRef, active} = useDroppable({
    id: item.id,
    data: item,
  });

  const style = React.useMemo(() => ({
    backgroundColor: isOver && active?.id != item.id ? theme.palette.primary.main : undefined,
  }), [isOver, active])
  return (<div ref={setNodeRef}><FolderListItem style={style} {...props}></FolderListItem></div>)
}

export function FolderListItem(props: FolderListItemProps) {
  const {item, onRename, onDelete, onGoToFolder, isParent, style} = props

  const folder = item as FileFolderTree

  return (<DividedListItem
    style={style}

    onClick={() => onGoToFolder?.(folder)}
    key={folder.id}>

    <ListItemAvatar><IconGridFolder color={'primary'}/></ListItemAvatar>
    <ListItemText primary={<div>
      {isParent
        ? <NonRenamableName nameStyle={{textAlign: 'left', flexGrow: 0}} name={'..'}/>
        : <RenamableName nameStyle={{textAlign: 'left', flexGrow: 0}} onRename={onRename} item={folder}/>}
      <FileInfo item={folder}/>
    </div>
    }/>
    <ListItemSecondaryAction>
      {!isParent && onDelete &&
        <IconButton onClick={() => onDelete?.(folder)} edge='end'><IconDelete color={'warning'}/></IconButton>}
    </ListItemSecondaryAction>
  </DividedListItem>)

}

type FileListItemProps = {
  item: FileFolderTree | MinimalFileObject
  onSelect?: (val: MinimalFileObject | null) => void
  onRename?: (val: (MinimalFileObject | FileFolderTree)) => void
  onDelete?: (val: MinimalFileObject | FileFolderTree) => void
  disabled?: boolean
}

export function FileListItem(props: FileListItemProps) {
  const {item, onRename, onSelect, onDelete, disabled} = props
  const file = item as MinimalFileObject

  return (<DividedListItem
    style={{opacity: disabled ? 0.5 : 1}}
    onClick={() => !disabled && onSelect?.(file)}
    key={file.id}>

    <ListItemAvatar><MimeTypeIcon width={32} height={32} color='yellow'
                                  contentType={file.contentType}/></ListItemAvatar>
    <ListItemText
      primary={<RenamableName nameStyle={{textAlign: 'left', flexGrow: 0}} onRename={onRename} item={file}/>}
      secondary={<FileInfo item={file}/>}/>
    <ListItemSecondaryAction>
      {onDelete &&
        <IconButton onClick={() => onDelete?.(file)} edge='end'><IconDelete color={'warning'}/></IconButton>}
    </ListItemSecondaryAction>
  </DividedListItem>)
}

type DraggableListItemProps = {
  children: React.ReactNode
  item: FileFolderTree | MinimalFileObject,
}

export const DraggableListItem = React.forwardRef((props: DraggableListItemProps, ref) => {
  const {attributes, listeners, setNodeRef, active} = useDraggable({
    id: props.item.id,
    data: props.item,
  });
  const combineRef = useMergedRef(setNodeRef, ref);
  return (
    <div ref={combineRef} {...listeners} {...attributes}
         style={{
           opacity: active?.id == props.item.id ? 0.5 : 1,
         }}>
      {props.children}
    </div>
  );
});
