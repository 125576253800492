import {useUploadHandler} from '@/hooks/use-upload-handler';
import {generateDebug} from '@/utils';
import * as React from 'react';

const debug = generateDebug('GenericUploader');

interface IFileWithMetadata {
  file: File;
  type: 'image'|'video'|'document'
  duration?: number
  width?: number|null;
  height?: number|null;
}

const extractMetadata = async (f: File): Promise<IFileWithMetadata>=>  {
  if( /image\/(jpeg|png)/.test(f.type)) {
    return extractMetadataImage(f)
  } else if(f.type=='image/svg+xml' ) {
    return {file: f, type: 'image', height: null, width: null};

  } else if(/video\/(mp4|quicktime)/.test(f.type)) {
    return extractMetadataVideo(f)

  }
  return {file: f, type: 'document'};

}
const extractMetadataImage = async (f: File) => {
  let handlers: ((param: IFileWithMetadata)=> void)[]
  const p = new Promise<IFileWithMetadata>((resolve) =>  {
    handlers = [resolve]
  })
  const img = document.createElement('img')
  const src = URL.createObjectURL(f);
  img.addEventListener('load', (evt) => {
    debug(evt)
    debug(img)
    handlers[0]({file: f, type: 'image', height: img.height, width: img.width})
    URL.revokeObjectURL(src)
  })
  img.src= src
  return p
}
const extractMetadataVideo = async (f: File) => {
  let handlers: ((param: IFileWithMetadata)=> void)[]
  const p = new Promise<IFileWithMetadata>((resolve) =>  {
    handlers = [resolve]
  })
  const video = document.createElement('video')
  const src = URL.createObjectURL(f);
  video.preload='metadata'
  video.addEventListener('loadedmetadata', (evt) => {
    debug(evt)
    debug(video)
    handlers[0]({file: f, type: 'video', duration: video.duration, height: video.videoHeight, width: video.videoWidth})
    URL.revokeObjectURL(src)
  })
  video.src= src
  return p

}

interface GenericUploaderProps {
  organizationId: string
  uploadDisabled?: boolean;
  folderId: string
}

function GenericUploader(props: GenericUploaderProps, ref: React.Ref<any>) {
  const fileInput = React.useRef<HTMLInputElement>(null);
  const handleUpload = useUploadHandler()
  const uploadFiles = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    debug('Event: %O', evt);
    const fileList: File[] = Array.from(evt.target.files || []);
    debug('uploadFiles %O', fileList);

    if (!fileList.length) {
      debug('No files chosen');
      return;
    }
    let images: IFileWithMetadata[];
    images = await Promise.all(fileList.map((f) => {
      return extractMetadata(f)
    }));

    const formData = new FormData();
    images.forEach((i) => {
      const {duration, height,width, type} = i
      const metadata = {type, duration, width,height};
      debug('Adding file to upload ', i.file.name, metadata);
      formData.append('files', i.file, i.file.name);
      formData.append('meta', JSON.stringify(metadata));
    });

    await handleUpload(
      formData,
      '__auto__',
      props.organizationId,
      props.folderId,
    );

  };

 React.useImperativeHandle(ref, () => ({
    chooseFiles: () => {
      debug('chooseFiles called', fileInput.current)
      fileInput.current?.click();
    }
  }));
  return (<form encType="multipart/form-data"
                noValidate style={{display: 'none'}}>
      <input ref={fileInput} type="file" multiple disabled={props.uploadDisabled}
             onChange={uploadFiles}
             accept="*"/>
    </form>
  )
}

export default React.forwardRef( GenericUploader)
