import React, { useCallback, useEffect, useRef } from 'react';

interface IDraggable {
  children: JSX.Element[] | JSX.Element
  style?: Object,
  className?: string,
  onDragStart?: (o: [number, number]) => void,
  onDragEnd?: (o: [number, number]) => void
}

function Draggable({ children, style, className, onDragStart, onDragEnd }: IDraggable) {
  const dragRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isMouseDown: boolean = false;
    let offset: [number, number] = [0, 0];

    const onMouseDown = (e: any) => {
      isMouseDown = true;
      const dragDiv = dragRef.current;
      if (!dragDiv) return;

      const isTouch: boolean = /touch/g.test(e.type);
      const x: number = isTouch ? e.touches[0].clientX : e.clientX;
      const y: number = isTouch ? e.touches[0].clientY : e.clientY;

      offset = [
        dragDiv.offsetLeft - x,
        dragDiv.offsetTop - y
      ];

      if (onDragStart) {
        const rect = dragDiv?.getBoundingClientRect() as DOMRect;
        onDragStart(offset);
      }

      dragDiv.addEventListener('mouseup', onMouseUp, {capture: true, passive: true});
      dragDiv.addEventListener('touchend', onMouseUp, {capture: true, passive: true});

      document.addEventListener('contextmenu', onContextMenu, {capture: false, passive: true});
      document.addEventListener('touchmove', onMouseMove, true);
      document.addEventListener('mousemove', onMouseMove, true);
    }

    const onMouseUp = (e: any) => {
      isMouseDown = false;
      if (!isMouseDown && onDragEnd) {
        const isTouch: boolean = /touch/g.test(e.type);
        const x: number = isTouch ? e.touches[0].clientX : e.clientX;
        const y: number = isTouch ? e.touches[0].clientY : e.clientY;
        onDragEnd([x + offset[0], y + offset[1]])
      }

      document.removeEventListener('touchmove', onMouseMove, true);
      document.removeEventListener('mousemove', onMouseMove, true);
      document.removeEventListener('contextmenu', onContextMenu, false);
    }

    const onMouseMove = (e: any) => {
      const isTouch: boolean = /touch/g.test(e.type);

      if (!isTouch) {
        e.preventDefault();
      }

      if (isMouseDown && dragRef.current) {
        const x: number = isTouch ? e.touches[0].clientX : e.clientX;
        const y: number = isTouch ? e.touches[0].clientY : e.clientY;

        dragRef.current.style.left = (x + offset[0]) + 'px';
        dragRef.current.style.top = (y + offset[1]) + 'px';
      }
    }

    const onContextMenu = () => {
      document.removeEventListener('mousemove', onMouseMove, true);
      document.removeEventListener('touchmove', onMouseMove, true);
    }

    const dragDiv = dragRef.current;

    dragDiv?.addEventListener('touchstart', onMouseDown, {capture: true, passive: true});
    dragDiv?.addEventListener('mousedown', onMouseDown, {capture: true, passive: true});

    return () => {
      dragDiv?.removeEventListener('mousedown', onMouseDown, true);
      dragDiv?.removeEventListener('mouseup', onMouseUp, true);
      document.removeEventListener('mousemove', onMouseMove, true);

      dragDiv?.removeEventListener('touchstart', onMouseDown, true);
      dragDiv?.removeEventListener('touchend', onMouseUp, true);
      document.removeEventListener('touchmove', onMouseMove, true);

      document.removeEventListener('contextmenu', onContextMenu, false);
    }
  }, [onDragEnd, onDragStart]);

  return <div
    ref={dragRef}
    className={className || "drag-react"}
    style={{ position: 'absolute', left: '0px', top: '0px', zIndex: 1099, cursor: 'move', ...style }}
  >{children}</div>
}

export { Draggable }
