import * as Types from '../../graphql-types';

import { gql } from 'urql';
export type IGQLCurrentUserFieldsFragment = { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } };

export const CurrentUserFieldsFragmentDoc = gql`
    fragment CurrentUserFields on JrnyLoginResult {
  user {
    firstName
    lastName
    id
  }
  permissions
  organizationIds
  tokenResponse {
    token
    tokenValidTo
    refreshToken
    refreshTokenValidTo
  }
}
    `;