import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLCheckpointQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLCheckpointQuery = { __typename: 'Query', jrnyCheckpoint: { __typename: 'JrnyCheckpoint', courseId: string, id: string, name: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, name: string, order: number }> } };

export type IGQLUpdateCheckpointMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateCheckpointInput;
}>;


export type IGQLUpdateCheckpointMutation = { __typename: 'Mutation', jrnyUpdateCheckpoint: { __typename: 'JrnyCheckpoint', courseId: string, id: string, name: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, name: string, order: number }> } };

export type IGQLMoveCheckpointMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyMoveCheckpointInput;
}>;


export type IGQLMoveCheckpointMutation = { __typename: 'Mutation', jrnyMoveCheckpoint: { __typename: 'JrnyMoveCheckpointPayload', source: { __typename: 'JrnyCourse', id: string, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, order: number }> }, target: { __typename: 'JrnyCourse', id: string, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, order: number }> } } };

export type IGQLCloneCheckpointMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCloneCheckpointInput;
}>;


export type IGQLCloneCheckpointMutation = { __typename: 'Mutation', jrnyCloneCheckpoint: { __typename: 'JrnyCourse', id: string, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, name: string, order: number, lessons: Array<{ __typename: 'JrnyLesson', id: string, name: string, checkpointId: string, order: number, contentItems: Array<{ __typename: 'JrnyMedia', id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> }> }> } };

export type IGQLCreateCheckpointMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateCheckpointInput;
}>;


export type IGQLCreateCheckpointMutation = { __typename: 'Mutation', jrnyCreateCheckpoint: { __typename: 'JrnyCourse', id: string, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, name: string, order: number }> } };

export type IGQLRemoveCheckpointMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRemoveCheckpointInput;
}>;


export type IGQLRemoveCheckpointMutation = { __typename: 'Mutation', jrnyRemoveCheckpoint: { __typename: 'JrnyCourse', id: string, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, name: string, order: number }> } };


export const CheckpointDocument = gql`
    query checkpoint($id: ID!) {
  jrnyCheckpoint(id: $id) {
    courseId
    id
    name
    lessons {
      id
      name
      order
    }
  }
}
    `;

export function useCheckpointQuery(options: Omit<Urql.UseQueryArgs<IGQLCheckpointQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLCheckpointQuery, IGQLCheckpointQueryVariables>({ query: CheckpointDocument, ...options });
};
export const UpdateCheckpointDocument = gql`
    mutation updateCheckpoint($input: JrnyUpdateCheckpointInput!) {
  jrnyUpdateCheckpoint(input: $input) {
    courseId
    id
    name
    lessons {
      id
      name
      order
    }
  }
}
    `;

export function useUpdateCheckpointMutation() {
  return Urql.useMutation<IGQLUpdateCheckpointMutation, IGQLUpdateCheckpointMutationVariables>(UpdateCheckpointDocument);
};
export const MoveCheckpointDocument = gql`
    mutation moveCheckpoint($input: JrnyMoveCheckpointInput!) {
  jrnyMoveCheckpoint(input: $input) {
    source {
      __typename
      id
      checkpoints {
        __typename
        id
        courseId
        order
      }
    }
    target {
      __typename
      id
      checkpoints {
        __typename
        id
        courseId
        order
      }
    }
  }
}
    `;

export function useMoveCheckpointMutation() {
  return Urql.useMutation<IGQLMoveCheckpointMutation, IGQLMoveCheckpointMutationVariables>(MoveCheckpointDocument);
};
export const CloneCheckpointDocument = gql`
    mutation cloneCheckpoint($input: JrnyCloneCheckpointInput!) {
  jrnyCloneCheckpoint(input: $input) {
    __typename
    id
    checkpoints {
      __typename
      id
      courseId
      name
      order
      lessons {
        __typename
        id
        name
        checkpointId
        order
        contentItems {
          ... on JrnyPrompt {
            __typename
            id
            name
            order
            categoryOrder
            category
          }
          ... on JrnyMedia {
            __typename
            id
            name
            order
            categoryOrder
          }
          ... on JrnyResource {
            __typename
            id
            name
            order
            type
            categoryOrder
            category
          }
        }
      }
    }
  }
}
    `;

export function useCloneCheckpointMutation() {
  return Urql.useMutation<IGQLCloneCheckpointMutation, IGQLCloneCheckpointMutationVariables>(CloneCheckpointDocument);
};
export const CreateCheckpointDocument = gql`
    mutation createCheckpoint($input: JrnyCreateCheckpointInput!) {
  jrnyCreateCheckpoint(input: $input) {
    __typename
    id
    checkpoints {
      __typename
      id
      courseId
      name
      order
    }
  }
}
    `;

export function useCreateCheckpointMutation() {
  return Urql.useMutation<IGQLCreateCheckpointMutation, IGQLCreateCheckpointMutationVariables>(CreateCheckpointDocument);
};
export const RemoveCheckpointDocument = gql`
    mutation removeCheckpoint($input: JrnyRemoveCheckpointInput!) {
  jrnyRemoveCheckpoint(input: $input) {
    __typename
    id
    checkpoints {
      __typename
      id
      courseId
      name
      order
    }
  }
}
    `;

export function useRemoveCheckpointMutation() {
  return Urql.useMutation<IGQLRemoveCheckpointMutation, IGQLRemoveCheckpointMutationVariables>(RemoveCheckpointDocument);
};