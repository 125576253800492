// a wrapper hook that executes a mutation and displays a snackbar on error or success
import {OperationResult} from "@urql/core";
import {useSnackbar} from "notistack";
import * as React from "react";
import {debug} from "@/pages/structure/utils";

export const useWrapperHook = <T extends (...args: any[]) => Promise<OperationResult>>(fn: T, messageBase: string, resetLocal: () => void, deps?: any[]): (...args: Parameters<T>) => Promise<void> => {
  const {enqueueSnackbar} = useSnackbar()
  return React.useCallback(async (...args: Parameters<T>) => {
    try {
      const result = await fn(...args)
      if (result.error) {
        resetLocal()
        enqueueSnackbar(`${messageBase} ERROR: ${result.error.message}`, {variant: 'error'})
      } else {
        enqueueSnackbar(`${messageBase} Succeeded`, {variant: 'success'})
      }
      debug(`${messageBase} result`, result)
    } catch (e) {
      resetLocal()
      enqueueSnackbar(`${messageBase} ERROR: ${(e as Error).message}`, {variant: 'error'})
    }
  }, [fn, enqueueSnackbar, messageBase, ...(deps||[])])
}
