import AcceptSiteInvitation from '@/pages/accept-invitation/AcceptSiteInvitation';
import AdminMain from '@/pages/admin/AdminMain';
import CourseDetailsView from '@/pages/course/CourseDetailsView';
import LessonDetailsView from '@/pages/lesson/LessonDetailsView';
import OrganizationListView from '@/pages/organization/OrganizationListView';
import {ResourceDetailsView} from '@/pages/resource/ResourceDetailsView';
import UserInvitationsView from '@/pages/user/UserInvitationsView';
import MediaDetailsView from '@/pages/video/MediaDetailsView';
import RequireAuth from '@auth/components/RequireAuth';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import RequireNotAuth from './auth/components/RequireNotAuth';
import CheckpointDetailsView from './pages/checkpoint/CheckpointDetailsView';
import {ForgotPassword, ResetPassword} from './pages/forgot-password';
import Main from './pages/main/Main';
import OrganizationUsersView from './pages/organization/OrganizationUsersView';
import PromptDetailsView from './pages/prompt/PromptDetailsView';
import {SignIn} from './pages/sign-in';
import {TopNavigation} from './top-navigation';
import {PerkListView} from "@/pages/perks/PerkListView";
import {PerkDetailsView} from "@/pages/perks/PerkDetailsView";
import {SnackbarProvider} from "notistack";
import {StructureView} from "@/pages/structure/StructureView";
import {FilesViewPage} from "@/pages/files/FilesPageView";
import CourseUsersView from "@/pages/course-users/CourseUsersView";
import * as Sentry from "@sentry/react";
import CourseOccasionsView from './pages/course-occasions/CourseOccasionsView';
import { SettingsView } from './pages/settings/SettingsView';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  return (
    <div className="App">
      <SnackbarProvider
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}>

        <SentryRoutes>
          <Route path='/' element={<RequireAuth><Main/></RequireAuth>}>
            <Route path='admin'>
              <Route path='organizations' element={<OrganizationListView/>}/>
              <Route path='maintain' element={<AdminMain/>}/>
              <Route path='settings' element={<SettingsView/>}/>
            </Route>
            <Route path='organization'>
              <Route path='courses' element={<StructureView/>}/>
              <Route path='files' element={<FilesViewPage/>}/>
              <Route path='users' element={<OrganizationUsersView/>}/>
              <Route path='perks' element={<PerkListView/>}/>
              <Route path='perk/:perkId' element={<PerkDetailsView/>}/>
              <Route path='course/:courseId' element={<CourseDetailsView/>}/>
              <Route path='checkpoint/:checkpointId' element={<CheckpointDetailsView/>}/>
              <Route path='lesson/:lessonId' element={<LessonDetailsView/>}/>
              <Route path='prompt/:promptId' element={<PromptDetailsView/>}/>
              <Route path='challenge/:promptId' element={<PromptDetailsView/>}/>
              <Route path='media/:mediaId' element={<MediaDetailsView/>}/>
              <Route path='resource/:resourceId' element={<ResourceDetailsView/>}/>
              <Route path='course-users/:courseId' element={<CourseUsersView/>}/>
              <Route path='course-occasions/:courseId' element={<CourseOccasionsView/>}/>
            </Route>
            <Route path='me'>
              <Route path='invitations' element={<UserInvitationsView/>}/>
              <Route path='invitations/:invitationId' element={<UserInvitationsView/>}/>

            </Route>
          </Route>
          <Route path={TopNavigation.SignIn} element={<RequireNotAuth><SignIn/></RequireNotAuth>}/>
          <Route path={TopNavigation.AcceptInvitation + '/:tokenId'} element={<AcceptSiteInvitation/>}/>
          <Route path={TopNavigation.AcceptInvitation + '/:tokenId/:invitationId'} element={<AcceptSiteInvitation/>}/>
          <Route path={TopNavigation.ForgotPassword} element={<RequireNotAuth><ForgotPassword/></RequireNotAuth>}/>
          <Route path={TopNavigation.ResetPassword + '/:code'} element={<ResetPassword/>}/>

        </SentryRoutes>
      </SnackbarProvider></div>
  );
}

export default App;
