import {generateDebug} from '@/utils';
import {Box, Stack} from '@mui/material';
import * as React from 'react'
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {FolderPath} from '@/components/file-object/FolderPath';
import {FilesView} from "@components/file-object/FilesView";
import {FileFilterType} from "@components/file-object/types";
import {ViewModeSelector} from "@components/file-object/ViewModeSelector";
import {FileFilterSelector} from "@components/file-object/FileFilterSelector";
import {SortModeSelector} from "@components/file-object/SortModeSelector";
import {useLocalState} from "@/hooks/use-local-state";
const debug = generateDebug('FilesViewPage')

export function FilesViewPage() {
  const {currentOrganization} = useCurrentOrganization();
  const [viewMode, setViewMode] = useLocalState<'grid' | 'list'>('FILES_VIEW_MODE', 'grid')
  const [sortMode, setSortMode] = useLocalState<'name' | 'date'>('FILES_SORT_MODE', 'name')
  const [sortDirection, setSortDirection] = useLocalState<'asc' | 'desc'>('FILES_SORT_DIRECTION', 'asc')
  const [filter, setFilter] = React.useState<FileFilterType|undefined>()

  return currentOrganization?.id
    ? (<div>
      <Box sx={{p: 1}}>

        <Stack sx={{rowGap: 1}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <ViewModeSelector setViewMode={setViewMode} viewMode={viewMode}/>
            <SortModeSelector setSortMode={setSortMode} sortMode={sortMode} setSortDirection={setSortDirection} sortDirection={sortDirection}/>
            <FileFilterSelector setFilter={setFilter} filter={filter}/>
          </Box>
          <Box><FolderPath/></Box>
          <FilesView fixed organizationId={currentOrganization.id} viewMode={viewMode} filter={filter} sortMode={sortMode} sortDirection={sortDirection}/>
        </Stack>
      </Box>
    </div>)
    : null
}
