import * as React from 'react'
import {Box, styled} from "@mui/material";
import {FileFolderTree, MinimalFileObject} from "@components/file-object/types";
import {IconDuration, IconFileCount, IconFolderCount, IconImageSize, IconSize} from '@/icons';
import prettyMs from "pretty-ms";
import prettySize from "pretty-bytes";

const InfoBox = styled(Box)({
  opacity: 0.7,
  padding: '2px',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  columnGap: '6px',
  overflow: 'hidden',
})
const InfoSpan = styled(Box)({
  display: 'flex',
  flexFlow: 'row nowrap',
  fontSize: '12px',
  alignItems: 'center',
  columnGap: '2px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

interface FileInfoProps {
  item: FileFolderTree | MinimalFileObject

}

export function FileInfo(props: FileInfoProps) {
  const {item} = props
  const isFolder = !('url' in item)
  if (isFolder) {
    return (<InfoBox>
      <InfoSpan><IconFolderCount fontSize={'inherit'}/>{item.folders.length}</InfoSpan>
      <InfoSpan><IconFileCount fontSize={'inherit'}/>{item.files.length}</InfoSpan>
    </InfoBox>)

  } else {
    return (<InfoBox>
      <InfoSpan><IconSize fontSize={'inherit'}/>{prettySize(item.size)}</InfoSpan>
      {item.metadata.duration ? (<InfoSpan><IconDuration
        fontSize={'inherit'}/>{prettyMs(1000*item.metadata.duration, {compact: true})}</InfoSpan>) : null}
      {item.metadata.width && item.metadata.height ? (<InfoSpan><IconImageSize
        fontSize={'inherit'}/>{item.metadata.width}x{item.metadata.height}</InfoSpan>) : null}
    </InfoBox>)
  }

}
