import * as React from 'react'

interface ProgressProviderProps {
  children: React.ReactNode
}

type ProgressContextValue = {
  progress: number|'unknown'
  show: boolean
  showProgress: (val: boolean, value?: number) => void
  setProgress: (val: number | 'unknown') => void
}
const NoOp = () => {}
export const ProgressContext = React.createContext<ProgressContextValue>({
  progress: 0,
  show: false,
  showProgress: NoOp,
  setProgress: NoOp
})
function ProgressProvider(props: ProgressProviderProps) {
  const [progress, setProgress] = React.useState<number | 'unknown'>(0)
  const [show, setShow] = React.useState<boolean>(true)
  const showProgress = (val: boolean, value?: number) => {
    setShow(val)
    if (value) {
      setProgress(value)
    } else
    if( !val) {
      setProgress(0)
    }
  }
  const value = React.useMemo(() => {
    return {
      progress,
      show,
      showProgress,
      setProgress
    }
  }, [progress, show])
  return (<ProgressContext.Provider children={props.children} value={value}/>)
}
ProgressProvider.prototype.Consumer = ProgressContext.Consumer

export {ProgressProvider}
export const useProgress = () => {
  const {setProgress, showProgress} = React.useContext(ProgressContext)
  return {
    setProgress, showProgress
  }
}
