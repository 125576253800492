import {Button, IconButton} from '@mui/material'
import * as React from 'react'
import {useNavigate} from "react-router-dom";
import {IconBack} from "@/icons";
import {SxProps, Theme} from "@mui/material/styles";

interface BackButtonProps {
  sx?: SxProps<Theme>;
}

export function BackButton({sx = []}: BackButtonProps) {
  const navigate = useNavigate()
  return (
    <Button sx={[{borderRadius: '50%', width: 40, height: 40, minWidth: 'unset'},
      ...(Array.isArray(sx) ? sx : [sx])]}
            variant="contained" onClick={() => navigate('../courses')}><IconBack/></Button>)
}
