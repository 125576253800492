import {generateDebug} from '@/utils';
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

const debug = generateDebug('network');

interface ApiConfig extends AxiosRequestConfig {
  getToken: () => string | null;
  refreshLoginHandler: () => Promise<any>;
  logoutHandler: () => void;
}

const defaultConfig: ApiConfig = {
  getToken: () => null,
  logoutHandler: () => {
    debug('logoutHandler');
  },
  refreshLoginHandler: async () => {
    return true;
  },
};

export class Network {
  public readonly nonAuthorized: AxiosInstance;
  public readonly authorized: AxiosInstance;

  constructor(config: ApiConfig) {
    this.authorized = this.createAuthorized(config);
    this.nonAuthorized = this.createNonAuthorized(config);

  }

  private createAuthorized(config: ApiConfig) {
    const auth = axios.create(config);

    auth.interceptors.request.use((request) => {
      debug('request: %O', request);
      const token = config.getToken();
      if (token) {
        request.headers.Authorization=`bearer ${token}`;
      }
      return request;
    }, (error) => {
      return Promise.reject(error);
    });

    auth.interceptors.response.use((response) => {
      debug('authorized response %O', response.data ? response.data : response);
      return response;
    }, (error) => {
      // const stdError = new ApiError(error);
      debug('Authorized Response error %O', error);
      // Here we implement the refresh logic
      if (error.response?.status === 401) {
        if (error.config.doNotRetry) {
          debug('Not retrying, will call logout handler');

          config.logoutHandler();
          return Promise.reject(error);
        }

        debug('Retrying, will refresh login');
        return config.refreshLoginHandler().then(() => {
          const newConfig = {...error.config, doNotRetry: true};
          if (newConfig.headers.Authorization) {
            delete newConfig.headers.Authorization;
          }
          debug('Retrying original request %O', newConfig);
          return auth.request(newConfig);
        }).catch((err2: Error) => {
          debug('Refresh failed');
          config.logoutHandler();

          // const stdError2 = new ApiError(err2);
          return Promise.reject(err2);
        });
      }
      return Promise.reject(error);
    });
    return auth;
  }

  private createNonAuthorized(config: ApiConfig) {
    const nonAuth = axios.create(config);

    nonAuth.interceptors.request.use((request) => {
      return request;
    }, (error) => {
      return Promise.reject(error);
    });

    nonAuth.interceptors.response.use((response) => {
      debug('nonAuthorized response %O', response.data ? response.data : response);
      return response;
    }, (error) => {
      // const standardError = new ApiError(error);
      debug('nonAuthorized error %O', error);
      return Promise.reject(error);
    });
    return nonAuth;
  }
}
