import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLMediaQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLMediaQuery = { __typename: 'Query', jrnyMedia: { __typename: 'JrnyMedia', id: string, order: number, name: string, duration: number, description: string, mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, video?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLUpdateMediaMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateMediaInput;
}>;


export type IGQLUpdateMediaMutation = { __typename: 'Mutation', jrnyUpdateVideo: { __typename: 'JrnyMedia', id: string, order: number, name: string, duration: number, description: string, mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, video?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };


export const MediaDocument = gql`
    query media($id: ID!) {
  jrnyMedia(id: $id) {
    __typename
    id
    order
    name
    duration
    description
    mediaType
    iframe
    image {
      ...FileObjectFields
    }
    video {
      ...FileObjectFields
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useMediaQuery(options: Omit<Urql.UseQueryArgs<IGQLMediaQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLMediaQuery, IGQLMediaQueryVariables>({ query: MediaDocument, ...options });
};
export const UpdateMediaDocument = gql`
    mutation updateMedia($input: JrnyUpdateMediaInput!) {
  jrnyUpdateVideo(input: $input) {
    __typename
    id
    order
    name
    duration
    description
    mediaType
    iframe
    image {
      ...FileObjectFields
    }
    video {
      ...FileObjectFields
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useUpdateMediaMutation() {
  return Urql.useMutation<IGQLUpdateMediaMutation, IGQLUpdateMediaMutationVariables>(UpdateMediaDocument);
};