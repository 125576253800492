import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLConfirmUploadsSimpleMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyConfirmUploadSimpleInput;
}>;


export type IGQLConfirmUploadsSimpleMutation = { __typename: 'Mutation', uploadsConfirmSimple: Array<{ __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any }> };

export type IGQLRenameFileObjectMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRenameFileObjectInput;
}>;


export type IGQLRenameFileObjectMutation = { __typename: 'Mutation', renameFileObject: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } };


export const ConfirmUploadsSimpleDocument = gql`
    mutation confirmUploadsSimple($input: JrnyConfirmUploadSimpleInput!) {
  uploadsConfirmSimple(input: $input) {
    ...FileObjectFields
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useConfirmUploadsSimpleMutation() {
  return Urql.useMutation<IGQLConfirmUploadsSimpleMutation, IGQLConfirmUploadsSimpleMutationVariables>(ConfirmUploadsSimpleDocument);
};
export const RenameFileObjectDocument = gql`
    mutation renameFileObject($input: JrnyRenameFileObjectInput!) {
  renameFileObject(input: $input) {
    ...FileObjectFields
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useRenameFileObjectMutation() {
  return Urql.useMutation<IGQLRenameFileObjectMutation, IGQLRenameFileObjectMutationVariables>(RenameFileObjectDocument);
};