import {FileFilterType, FileFolderTree, FileOrFolder, MinimalFileObject} from '@components/file-object/types';
import {Box, ListProps} from '@mui/material';
import {ListTypeMap} from '@mui/material/List/List';
import {orderBy} from 'lodash'
import * as React from 'react'
import {useMemo} from 'react'
import {fieldChooser} from "@components/file-object/utils";
import {
  DataRef,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {useFolderUtils} from "@/hooks/use-folder-utils";
import {
  DraggableListItem,
  DroppableFolderListItem,
  FileListItem,
  FolderListItem
} from "@components/file-object/FileListItem";

type FileListProps<T extends MinimalFileObject, D extends React.ElementType = ListTypeMap['defaultComponent'], P = {}> =
  {
    organizationId: string
    filter?: FileFilterType
    sortMode?: 'name' | 'date'
    sortDirection?: 'asc' | 'desc'

    selectType?: FileFilterType,
    onSelect?: (val: MinimalFileObject | null) => void
    onRename?: (val: MinimalFileObject | FileFolderTree) => void
    onDelete?: (val: MinimalFileObject | FileFolderTree) => void
    folder: FileFolderTree
    onGoToFolder: (folder: FileFolderTree) => void


  }
  & Omit<ListProps<D, P>, 'onSelect'>

export function FileList<T extends MinimalFileObject>({
                                                        folder,
                                                        onSelect,
                                                        onRename,
                                                        onGoToFolder,
                                                        onDelete,
                                                        organizationId,
                                                        filter,
                                                        sortMode,
                                                        sortDirection,
                                                        selectType,
                                                      }: FileListProps<T>) {
  const pointer = useSensor(PointerSensor, {activationConstraint: {distance: 5}})
  const touch = useSensor(TouchSensor, {activationConstraint: {distance: 5}})
  const mouse = useSensor(MouseSensor, {activationConstraint: {distance: 5}})
  const sensors = useSensors(touch, mouse, pointer)
  const [movingItem, setMovingItem] = React.useState<FileOrFolder | undefined>()
  const [movedItemId, setMovedItemId] = React.useState<string | undefined>()

  const mixedItems = useMemo(() => [
      ...(folder.parent ? [folder.parent] : []),
      ...orderBy(folder.folders, sortMode == 'date' ? ['created'] : ['name'], [sortDirection||'asc']),
      ...orderBy(folder.files, fieldChooser(sortMode || 'name'), [sortDirection||'asc'])]

      .filter((item) => !(item.id === movedItemId || (filter && 'url' in item && item.type !== filter))),
    [folder, movedItemId, filter, sortMode, sortDirection])
  const {handleMove, handleFileMove} = useFolderUtils(organizationId)

  const onDragEnd = (result: DragEndEvent) => {
    const {over, active} = result

    const toMove: FileFolderTree | MinimalFileObject | undefined = (active.data as DataRef<FileFolderTree | MinimalFileObject>).current;
    const moveTo = (over?.data as DataRef<FileFolderTree>).current;

    if (toMove && moveTo && moveTo.id !== toMove.id) {

      setMovedItemId(toMove.id)
      if ('url' in toMove) {
        handleFileMove(toMove, moveTo).then(() => setMovedItemId(undefined))
      } else {
        handleMove(toMove, moveTo!).then(() => setMovedItemId(undefined))

      }
    }
  }

  const onDragStart = (result: DragStartEvent) => {
    const {active} = result
    const current = active.data.current as FileOrFolder
    setMovingItem(current)
  }

  const renderItem = (fileOrFolder: FileFolderTree | MinimalFileObject, asOverlay?: boolean) => {
    if ('url' in fileOrFolder) {
      return <FileListItem item={fileOrFolder}
                           onSelect={onSelect}
                           onDelete={onDelete}
                           disabled={selectType && fileOrFolder.type !== selectType}
                           onRename={onRename}/>
    } else {
      const Element = asOverlay ? FolderListItem : DroppableFolderListItem
      return <Element item={fileOrFolder}
                      isParent={folder.parent === fileOrFolder}
                      onDelete={onDelete}
                      onRename={onRename}
                      isDroppable={!asOverlay}
                      onGoToFolder={onGoToFolder}/>

    }
  }

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box>
        {mixedItems.map(fileOrFolder => {
          return (<DraggableListItem item={fileOrFolder}>{renderItem(fileOrFolder)}</DraggableListItem>);
        })}
      </Box>
      <DragOverlay style={{opacity: 0.9}} dropAnimation={{
        duration: 250,
        easing: 'ease',
        sideEffects: defaultDropAnimationSideEffects({
          styles: {
            active: {
              opacity: '0.5',
            },
          }
        })
      }}>{movingItem && renderItem(movingItem, true)}</DragOverlay>

    </DndContext>)
}
