import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
export type IGQLPerkDetailsFragment = { __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null };

export const PerkDetailsFragmentDoc = gql`
    fragment PerkDetails on JrnyPerk {
  id
  title
  price
  created
  organizationId
  providerEmail
  managerEmail
  description
  visible
  image {
    ...FileObjectFields
  }
  isOutOfStock
  inStockCount
}
    ${FileObjectFieldsFragmentDoc}`;