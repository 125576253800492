import {Draggable} from '@/pages/course/components/Draggable';
import {SimpleCheckpoint} from '@/pages/course/types';
import {generateDebug} from '@/utils';
import {MinimalFileObject} from '@components/file-object/types';
import {Grid} from '@mui/material';
import {styled} from '@mui/system';
import * as React from 'react'

const debug = generateDebug('MapEditor')
const MapImage = styled('img') // language=css prefix=.root{ suffix=}
  `
    max-width: 100vw;
    max-height: 50vh;
    width: auto;
    height: auto;
    border: 0;
  `
const ImageWrapper = styled('div') // language=css prefix=.root{ suffix=}
  `
    display: inline-block;
    max-height: 50vh;
    position: relative;
    padding: 0;
  `
const Dot = styled('div') // language=css prefix=.root{ suffix=}
  `
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
    height: 2em;
    width: 2em;
    border-radius: 24px;
    border: white 2px solid;
    position: absolute;
    background: #ff0;
    line-height: calc(2em - 2px);
    font-size: 16px;
    text-align: center;
    transform: translate(-50%, -50%);
  `

interface MapEditorProps {
  map: MinimalFileObject
  checkpoints: SimpleCheckpoint[]
  onUpdateCoordinate: (checkpoint: SimpleCheckpoint, coordinates: { x: number, y: number }) => void
}

export default function MapEditor(props: MapEditorProps) {
  const imageRef = React.useRef<HTMLImageElement>(null)
  const [mapDimension, setMapDimensions] = React.useState<{ w: number, h: number } | null>(imageRef.current ? ({
    w: imageRef.current.width,
    h: imageRef.current.height
  }) : null)

  const handleResize = React.useCallback(() => {
    let newDimension = imageRef.current ? {w: imageRef.current.width, h: imageRef.current.height} : null;
    debug('newDimension', newDimension)
    setMapDimensions(newDimension)
  }, [])

  React.useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    imageRef.current?.addEventListener('load', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      imageRef.current?.removeEventListener('load', handleResize)
    }
  }, [imageRef])

  const updatePosition = React.useCallback((cp: SimpleCheckpoint, position: [number, number]) => {
    if (!mapDimension) {
      return
    }
    const x = Math.max(0, Math.min(1, position[0] / mapDimension.w))
    const y = Math.max(0, Math.min(1, position[1] / mapDimension.h))
    let coordinates = {x, y};
    props.onUpdateCoordinate(cp, coordinates)
  }, [mapDimension])


  return (<div>
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid item flexGrow={0}>
        <ImageWrapper>
          <MapImage ref={imageRef} src={props.map.url}/>
          {mapDimension && props.checkpoints.map(cp => (<Draggable
              key={cp.id}
              onDragEnd={(position: [number, number]) => updatePosition(cp, position)}
              style={{
                top: cp.coordinates.y * mapDimension.h,
                left: cp.coordinates.x * mapDimension.w,
              }}>
              <Dot>{cp.order + 1}</Dot>
            </Draggable>)
          )}
        </ImageWrapper>
      </Grid>
    </Grid>
  </div>)
}
