import * as React from 'react';
import {emphasize, styled} from '@mui/material/styles';
import {Breadcrumbs} from '@mui/material';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import {useAssets} from "@/context/AssetsProvider";

interface FolderPathProps {
}

const StyledBreadcrumb = styled(Chip)(({theme}) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[300]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export function FolderPath(props: FolderPathProps) {
  const {currentFolder, setCurrentFolder} = useAssets()
  const path = React.useMemo( () => ([...currentFolder.path, currentFolder]), [currentFolder])
  return (<div role="presentation" onClick={handleClick}>
    <Breadcrumbs aria-label="breadcrumb">

      {path.map((item, idx) => {
        return (<StyledBreadcrumb
            key={item.id}
            component="a"
            href="#"
            label={idx === 0 ? "Home" : item.name}
            onClick={() => setCurrentFolder(item)}
            icon={idx === 0 ? <HomeIcon fontSize="small"/> : undefined}
          />
        )
      })
      }
    </Breadcrumbs>
  </div>)
}
