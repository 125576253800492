import {NetworkContext} from '@/context/NetworkProvider';
import {IGQLFileObject} from '@/graphql-types';
import {generateDebug} from '@/utils';
import {useConfirmUploadsSimpleMutation} from '@graphql/uploads-hook';
import {useSnackbar} from 'notistack';
import * as React from 'react';
import {useProgress} from "@/context/ProgressProvider";
const debug = generateDebug('useUploadHandler')

export const useUploadHandler = () => {
  const {showProgress, setProgress} = useProgress()
  const {enqueueSnackbar} = useSnackbar();
  const [_, confirmUploadsSimple] = useConfirmUploadsSimpleMutation()
  const {addFile} = React.useContext(NetworkContext)

  return async (formData: FormData, type: string, organizationId: string,   folderId: string
  ) => {

    debug(formData);
    showProgress(true, 0)
    try {
      const result = await addFile(organizationId, folderId,formData, type, (progress) => {
        debug('progress', progress)
        setProgress(progress === 100 ? 'unknown' : progress)
      });
      showProgress(false)
      debug('Upload result', result);
      if (!result.error) {
        // enqueueSnackbar('Uploaded successfully');
        // TODO: Update cache
        const images = result.fileObjects as IGQLFileObject[]
        const resultConfirm = await confirmUploadsSimple({input: {
            organizationId: organizationId,
            type: type,
            ids: images.map(fo => fo.id)
          }})
        debug('Confirm result', resultConfirm);
        enqueueSnackbar(`Images uploaded`);
      } else {
        enqueueSnackbar(result.error, {variant: 'error'});
      }
    } catch (e) {
      showProgress(false)
      debug('Upload error', e);
      enqueueSnackbar((e as Error).message, {variant: 'error'});
    }

  }
}
