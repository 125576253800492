import {FileFilterType} from "@components/file-object/types";
import {Box, Button, ButtonGroup} from "@mui/material";
import {IconDocument, IconFilter, IconImage, IconVideo} from "@/icons";
import * as React from "react";

export type FileFilterSelectorProps = {
  filter?: FileFilterType
  setFilter: (val?: FileFilterType) => void
}

export function FileFilterSelector(props: FileFilterSelectorProps) {
  const {filter, setFilter} = props
  return <Box sx={{columnGap: 1, display: 'flex', alignItems: 'center'}}>
    <IconFilter htmlColor={'#aaa'}/>
    <ButtonGroup variant={'outlined'}>
      <Button variant={filter === 'image' ? 'contained' : 'outlined'}
              onClick={() => filter == 'image' ? setFilter(undefined) : setFilter('image')}><IconImage/></Button>
      <Button variant={filter === 'video' ? 'contained' : 'outlined'}
              onClick={() => filter == 'video' ? setFilter(undefined) : setFilter('video')}><IconVideo/></Button>
      <Button variant={filter === 'document' ? 'contained' : 'outlined'}
              onClick={() => filter == 'document' ? setFilter(undefined) : setFilter('document')}><IconDocument/></Button>
    </ButtonGroup>

  </Box>;
}
