import {IGQLInvitationStatus, IGQLJrnyInvitation} from '@/graphql-types';
import InvitationsList from '@components/InvitationsList';
import {Card, CardContent, Container, CardHeader} from '@mui/material';
import * as React from 'react';
import InviteToOrganizationDialog from './components/InviteToOrganizationDialog';
import {useInviteToOrganizationMutation, useOrganizationInvitationsQuery} from '@graphql/invitations-hook';
import {OrganizationUsersList} from './components/OrganizationUsersList';
import {useCurrentOrganization} from "@/context/OrganizationProvider";

interface OrganizationUsersViewProps {
}

export default function OrganizationUsersView(props: OrganizationUsersViewProps) {
  const {currentOrganization} = useCurrentOrganization();
  const [, inviteToOrganization] = useInviteToOrganizationMutation();
  const [invitations] = useOrganizationInvitationsQuery({
    variables: {id: currentOrganization?.id || ''},
    pause: !currentOrganization
  });
  const filteredInvitations = ((invitations.data?.jrnyOrganizationInvitations || []) as IGQLJrnyInvitation[]).filter(u => u.status !== IGQLInvitationStatus.Accepted)
  return currentOrganization && (<Container>
    {filteredInvitations.length>0 && <Card>
        <CardHeader title='Invited users'/>
        <CardContent>
            <InvitationsList invitations={filteredInvitations}/>

        </CardContent>
    </Card>}
    <Card>
      <CardHeader title='Accepted users'/>
    <CardContent>
      <OrganizationUsersList currentOrganization={currentOrganization}/>
    </CardContent>
    </Card>

    <InviteToOrganizationDialog inviteToOrganization={inviteToOrganization} organizationId={currentOrganization.id}/>
  </Container>) || null;
}
