import {ModifiedContext} from '@/context/ModifiedProvider';
import {generateDebug} from '@/utils';
import {OperationResult} from '@urql/core';
import {nothing} from 'immer';
import {isEqual} from 'lodash';
import {useSnackbar} from 'notistack';
import * as React from 'react';
import {Updater, useImmer} from 'use-immer';

const debug = generateDebug('useLocalChange')

/**
 * Helper that reduces the boilerplate of working on a local copy
 *
 * @param original the original object
 * @param saveLocal a function responsible for saving local version
 */
function useLocalChange<Org>(original: Org, saveLocal: (original: NonNullable<Org>, local:NonNullable<Org>) => Promise<OperationResult<any>>): [Org | undefined, Updater<Org | undefined>, number];
/**
 * Helper that reduces the boilerplate of working on a local copy with a transformed local shape
 *
 * @param original the original object
 * @param saveLocal a function responsible for saving local version
 * @param originalToLocal a transformer to give local a different shape
 * @param localToOriginal a corresponding reverse transformer, so we can compare back with the original
 * @param customIsEqual optional isEqual function that will determine if local (after reverse transformer) equals original
 */
function useLocalChange<Org, Local>(original: Org | undefined,
                                    saveLocal: (original: NonNullable<Org>, local: NonNullable<Local>) => Promise<OperationResult<any>>,
                                    originalToLocal?: (input: Org) => Local,
                                    localToOriginal?: (input: Local) => Org,
                                    customIsEqual?: (original: Org, local: Local ) => boolean,
): [Local | undefined, Updater<Local | undefined>, number];
function useLocalChange<Org, Local>(original: Org | undefined,
                                    saveLocal: (original: Org, local: Local) => Promise<OperationResult<any>>,
                                    originalToLocal?: (input: Org) => Local,
                                    localToOriginal?: (input: Local) => Org,
                                    customIsEqual: (original: any, local: any ) => boolean = isEqual,
): [Local | undefined, Updater<Local | undefined>, number] {
  const modifiedContextValue = React.useContext(ModifiedContext)
  const {enqueueSnackbar} = useSnackbar()
  const [resetCount, setRestCount] = React.useState(0)
  const [local, setLocal] = useImmer<Local | undefined>(undefined)

  React.useEffect(() => {
    debug('Updating modified')
    modifiedContextValue.setModified(original && local
      ? !customIsEqual(original, localToOriginal ? localToOriginal(local) : local)
      : false)
  }, [original, local])


  const reset = React.useCallback(() => {
    debug('Resetting', original)
    if (original) {
      setLocal((draft) => originalToLocal ? originalToLocal(original) : original as Local)
    } else {
      setLocal(() => nothing)
    }
    setRestCount((val)=> val+1)
  }, [original])

  React.useEffect(() => {
    debug('Updating handlers')
    modifiedContextValue.setHandlers({
      save: async () => {
        if (local && original) {
          const result = await saveLocal(original!, local!)
          if (result.error) {
            debug('error %o', result.error)
            enqueueSnackbar(result.error.message, {variant: 'error'})
          } else {
            enqueueSnackbar('Saved', {variant: 'success', autoHideDuration: 1000})
          }
        }
      }, reset: () => {
        reset()
      }
    })
    return () => {
      debug('Clearing handlers')
      modifiedContextValue.setHandlers({})
      modifiedContextValue.setModified(false)

    }
  }, [local])
  React.useEffect(reset, [original])
  return [local, setLocal, resetCount]
}

export {useLocalChange}
