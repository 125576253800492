import Button from '@/components/Button';
import { Container } from '@mui/material';
import * as React from 'react';

interface AdminMainProps {

}

export default function AdminMain(props: AdminMainProps) {
  const [updating, setUpdating] = React.useState(false)
   return (<Container>
  </Container>);
}
