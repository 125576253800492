import { generateDebug } from '@/utils';
import { Checkbox, DialogContent, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { CourseOccasion } from '../types';
import { set } from 'lodash';
import { Dayjs } from 'dayjs';
import Typography from '@/components/Typography';
import { Stack } from '@mui/system';

const debug = generateDebug('SelectDateDialog');

interface SelectDateDialogProps {
  protectedDates: string[]
  occasion: undefined | CourseOccasion
  dateHandler: ((date: Dayjs, duration: number, occasion: CourseOccasion) => Promise<any>);
  onClose: () => void
}

export default function SelectDateDialog(props: SelectDateDialogProps) {
  const [submitting, setSubmitting] = React.useState(false);
  const [date, setDate] = React.useState<Dayjs>(dayjs());
  const [duration, setDuration] = React.useState<number>(90);
  React.useEffect(() => {
    if (props.occasion?.date) {
      setDate(dayjs(props.occasion.date));
      setDuration(props.occasion.duration);
    }
  }, [props.occasion])
  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async () => {
    if (!date || !props.occasion) {
      props.onClose();
    }
    setSubmitting(true);
    const result = await props.dateHandler!(date!, duration, props.occasion!);
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return { [FORM_ERROR]: result.error.graphQLErrors[0].message };
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.occasion} onClose={submitting ? () => {
      } : handleClose}>
        <DialogContent>
          <DateCalendar
            shouldDisableDate={(date) => {
              const dateStr = date.format('YYYY-MM-DD');
              return props.protectedDates.includes(dateStr)
            }}
            value={date}
            onChange={(val) => { setDate(val) }} />
          <Stack spacing={1}>
            <Typography variant={'caption'}>Selected date: {date?.format('YYYY-MM-DD')}</Typography>
            <TextField type='number'
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              label="Duration"
              inputProps={{ min: 1 }} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={doSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
