import * as Types from '../../graphql-types';

import { gql } from 'urql';
export type IGQLFileObjectFieldsFragment = { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any };

export const FileObjectFieldsFragmentDoc = gql`
    fragment FileObjectFields on FileObject {
  __typename
  id
  type
  name
  originalName
  contentType
  size
  url
  folderId
  metadata
  created
}
    `;