import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLSettingDetailsFragment = { __typename: 'JrnySettings', domain: string, key: string, value?: any | null };

export type IGQLSettingsQueryVariables = Types.Exact<{
  id: Types.IGQLJrnySettingsIdInput;
}>;


export type IGQLSettingsQuery = { __typename: 'Query', jrnySettingsById: { __typename: 'JrnySettings', domain: string, key: string, value?: any | null } };

export type IGQLSaveSettingsMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateUpdateSettingsInput;
}>;


export type IGQLSaveSettingsMutation = { __typename: 'Mutation', jrnySaveSettings: { __typename: 'JrnySettings', domain: string, key: string, value?: any | null } };

export const SettingDetailsFragmentDoc = gql`
    fragment SettingDetails on JrnySettings {
  __typename
  domain
  key
  value
}
    `;
export const SettingsDocument = gql`
    query settings($id: JrnySettingsIdInput!) {
  jrnySettingsById(id: $id) {
    ...SettingDetails
  }
}
    ${SettingDetailsFragmentDoc}`;

export function useSettingsQuery(options: Omit<Urql.UseQueryArgs<IGQLSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLSettingsQuery, IGQLSettingsQueryVariables>({ query: SettingsDocument, ...options });
};
export const SaveSettingsDocument = gql`
    mutation saveSettings($input: JrnyCreateUpdateSettingsInput!) {
  jrnySaveSettings(input: $input) {
    ...SettingDetails
  }
}
    ${SettingDetailsFragmentDoc}`;

export function useSaveSettingsMutation() {
  return Urql.useMutation<IGQLSaveSettingsMutation, IGQLSaveSettingsMutationVariables>(SaveSettingsDocument);
};