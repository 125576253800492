import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLParticipationsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
}>;


export type IGQLParticipationsQuery = { __typename: 'Query', jrnyParticipationsForCourse: Array<{ __typename: 'JrnyParticipation', courseId: string, userId: string, role: string, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null, isConfirmed: boolean } }> };

export type IGQLCreateParticipationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateParticipationInput;
}>;


export type IGQLCreateParticipationMutation = { __typename: 'Mutation', jrnyCreateParticipation: { __typename: 'JrnyParticipation', courseId: string, userId: string, role: string, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null, isConfirmed: boolean } } };

export type IGQLCreateMultipleParticipationsMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateMultipleParticipationsInput;
}>;


export type IGQLCreateMultipleParticipationsMutation = { __typename: 'Mutation', jrnyCreateMultipleParticipations: Array<{ __typename: 'JrnyParticipation', courseId: string, userId: string, role: string, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null, isConfirmed: boolean } }> };

export type IGQLRemoveParticipationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRemoveParticipationInput;
}>;


export type IGQLRemoveParticipationMutation = { __typename: 'Mutation', jrnyRemoveParticipation: { __typename: 'JrnyParticipation', courseId: string, userId: string } };

export type IGQLSendWelcomeEmailMutationVariables = Types.Exact<{
  input: Types.IGQLJrnySendEmailToParticipantsInput;
}>;


export type IGQLSendWelcomeEmailMutation = { __typename: 'Mutation', jrnySendEmailToParticipants: { __typename: 'JrnySendEmailToParticipantsOutput', courseId: string, userIds: Array<string> } };


export const ParticipationsDocument = gql`
    query participations($courseId: ID!) {
  jrnyParticipationsForCourse(courseId: $courseId) {
    courseId
    userId
    role
    user {
      id
      name
      email
      isConfirmed
    }
  }
}
    `;

export function useParticipationsQuery(options: Omit<Urql.UseQueryArgs<IGQLParticipationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLParticipationsQuery, IGQLParticipationsQueryVariables>({ query: ParticipationsDocument, ...options });
};
export const CreateParticipationDocument = gql`
    mutation createParticipation($input: JrnyCreateParticipationInput!) {
  jrnyCreateParticipation(input: $input) {
    courseId
    userId
    role
    user {
      id
      name
      email
      isConfirmed
    }
  }
}
    `;

export function useCreateParticipationMutation() {
  return Urql.useMutation<IGQLCreateParticipationMutation, IGQLCreateParticipationMutationVariables>(CreateParticipationDocument);
};
export const CreateMultipleParticipationsDocument = gql`
    mutation createMultipleParticipations($input: JrnyCreateMultipleParticipationsInput!) {
  jrnyCreateMultipleParticipations(input: $input) {
    courseId
    userId
    role
    user {
      id
      name
      email
      isConfirmed
    }
  }
}
    `;

export function useCreateMultipleParticipationsMutation() {
  return Urql.useMutation<IGQLCreateMultipleParticipationsMutation, IGQLCreateMultipleParticipationsMutationVariables>(CreateMultipleParticipationsDocument);
};
export const RemoveParticipationDocument = gql`
    mutation removeParticipation($input: JrnyRemoveParticipationInput!) {
  jrnyRemoveParticipation(input: $input) {
    courseId
    userId
  }
}
    `;

export function useRemoveParticipationMutation() {
  return Urql.useMutation<IGQLRemoveParticipationMutation, IGQLRemoveParticipationMutationVariables>(RemoveParticipationDocument);
};
export const SendWelcomeEmailDocument = gql`
    mutation sendWelcomeEmail($input: JrnySendEmailToParticipantsInput!) {
  jrnySendEmailToParticipants(input: $input) {
    courseId
    userIds
  }
}
    `;

export function useSendWelcomeEmailMutation() {
  return Urql.useMutation<IGQLSendWelcomeEmailMutation, IGQLSendWelcomeEmailMutationVariables>(SendWelcomeEmailDocument);
};