import {Button, ButtonGroup} from "@mui/material";
import {IconSort, IconSortByDate, IconSortByName, IconSortDescending, IconSortAscending} from "@/icons";
import * as React from "react";
import Box from "@mui/material/Box";

export type SortModeSelectorProps = {
  sortMode: "name" | "date"
  setSortMode: (val: "name" | "date") => void
  sortDirection?: "asc" | "desc"
  setSortDirection?: (val: "asc" | "desc") => void
}

export function SortModeSelector(props: SortModeSelectorProps) {
  const {sortMode, setSortMode, sortDirection, setSortDirection} = props
  return <Box sx={{columnGap: 1, display: 'flex', alignItems: 'center'}}>
    <IconSort htmlColor={'#aaa'}/>
    <ButtonGroup variant={'outlined'}>
      <Button variant={sortMode === 'date' ? 'contained' : 'outlined'}
              onClick={() => setSortMode('date')}><IconSortByDate/></Button>
      <Button variant={sortMode === 'name' ? 'contained' : 'outlined'}
              onClick={() => setSortMode('name')}><IconSortByName/></Button>
    </ButtonGroup>
    {sortDirection && setSortDirection && <ButtonGroup variant={'outlined'}>
      <Button variant={sortDirection === 'asc' ? 'contained' : 'outlined'}
              onClick={() => setSortDirection('asc')}><IconSortAscending/></Button>
      <Button variant={sortDirection === 'desc' ? 'contained' : 'outlined'}
              onClick={() => setSortDirection('desc')}><IconSortDescending/></Button>
    </ButtonGroup>
    }
  </Box>;
}
