import { IGQLRemoveCourseOccasionMutationVariables } from '@/graphql/hooks/course-occasions-hook';
import { generateDebug } from '@/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { CourseOccasion } from "../types";

const debug = generateDebug('RemoveCourseOccasionDialog');

interface RemoveCourseOccasionDialogProps {
  removeUser: (variables: IGQLRemoveCourseOccasionMutationVariables) => Promise<any>;
  occasion?: CourseOccasion
  onClose: () => void
}

export default function RemoveCourseOccasionDialog(props: RemoveCourseOccasionDialogProps) {
  const [submitting, setSubmitting] = React.useState(false);


  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async () => {
    setSubmitting(true);
    const result = await props.removeUser({
      input: {
        courseId: props.occasion!.courseId,
        id: props.occasion!.id,
      }
    });
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return { [FORM_ERROR]: result.error.graphQLErrors[0].message };
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.occasion} onClose={submitting ? () => {
      } : handleClose}>
        <DialogTitle>Remove {props.occasion?.date} from course?</DialogTitle>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={() => doSubmit()}
            variant={'contained'}
            color={'error'}>Remove</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
