import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SpeedDial, SpeedDialAction
} from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import AddCourseUserDialog from './components/AddCourseUserDialog';
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateMultipleParticipationsMutation,
  useCreateParticipationMutation,
  useParticipationsQuery, useRemoveParticipationMutation,
  useSendWelcomeEmailMutation
} from "@graphql/participations-hook";
import { Participation } from "@/pages/course-users/types";
import Box from "@mui/material/Box";
import { IconAdd, IconDelete, IconImportCSV, IconTeacher } from "@/icons";
import UploadCourseUserCsv from "@/pages/course-users/components/UploadCourseUserCsv";
import { sortBy } from "lodash";
import RemoveCourseUserDialog from "@/pages/course-users/components/RemoveCourseUserDialog";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { actions } from "@/pages/prompt/types";
import IconStudent from "@mui/icons-material/School";
import SelectUsersToInviteDialog from './components/SelectUsersToInviteDialog';

interface CourseUsersViewProps {
}

export default function CourseUsersView(props: CourseUsersViewProps) {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const { currentOrganization } = useCurrentOrganization();
  const { courseId } = useParams()
  const [toRemove, setToRemove] = React.useState<Participation>()
  const [showInvitationDialog, setShowInvitationDialog] = React.useState(false)
  const [toAddRole, setToAddRole] = React.useState<'student' | 'teacher'>()
  const navigate = useNavigate()

  const [, addCourseUser] = useCreateParticipationMutation();
  const [, removeCourseUser] = useRemoveParticipationMutation();
  const [, addMultipleCourseUser] = useCreateMultipleParticipationsMutation();
  const [, sendWelcomeEmails] = useSendWelcomeEmailMutation();

  const [participations] = useParticipationsQuery({
    variables: { courseId: courseId || '' },
    pause: !currentOrganization
  });
  const students = useMemo(() => sortBy(
    (participations.data?.jrnyParticipationsForCourse || [])
      .filter(u => u.role == 'student'), ['name', 'email']),
    [participations.data])
  const teachers = useMemo(() => sortBy(
    (participations.data?.jrnyParticipationsForCourse || [])
      .filter(u => u.role == 'teacher', ['name', 'email'])),
    [participations.data])

  const combined = useMemo(() => [...teachers, ...students], [teachers, students])
  return courseId && currentOrganization && (<Container>
    <Box sx={{ position: 'relative', mb: 8 }}>
      <Card>
        <CardHeader title='Users'
          subheader={`Teacher count ${teachers.length}, student count: ${students.length}`}
          action={combined.length > 0 ? <Button
            onClick={() => setShowInvitationDialog(true)}
            sx={{ m: 2 }} variant='contained'>Send invitation</Button> : null} />
        <CardContent>
          <List>
            {combined.map((participation, ix) => (<ListItem
              secondaryAction={<>
                <Chip label={participation.role} sx={{ bgcolor: participation.role == 'teacher' ? 'orange' : 'lightgray' }} />
                <IconButton
                  onClick={() => setToRemove(participation)}
                  edge='end'
                ><IconDelete /></IconButton>
              </>}

              divider={ix < combined.length - 1}
              key={participation.userId}>

              <ListItemText primary={participation.user.email} secondary={participation.user.name} />
            </ListItem>))}
          </List>
        </CardContent>
      </Card>
    </Box>
    <SelectUsersToInviteDialog courseId={courseId} participations={participations.data?.jrnyParticipationsForCourse || []}
      open={showInvitationDialog} onClose={() => setShowInvitationDialog(false)}
      sendEmails={sendWelcomeEmails} />
    <AddCourseUserDialog courseId={courseId} role={toAddRole} onClose={() => setToAddRole(undefined)} addUser={addCourseUser}
    />
    <UploadCourseUserCsv ref={fileInput} courseId={courseId} addUsers={addMultipleCourseUser} />
    <RemoveCourseUserDialog onClose={() => setToRemove(undefined)} participation={toRemove}
      removeUser={removeCourseUser} />
    <SpeedDial
      ariaLabel="Create"
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}

    >
      <SpeedDialAction
        onClick={() => setToAddRole('teacher')}
        icon={<IconTeacher />}
        tooltipTitle={'Add teacher'}
      />
      <SpeedDialAction
        onClick={() => setToAddRole('student')}
        icon={<IconStudent />}
        tooltipTitle={'Add student'}
      />
      <SpeedDialAction
        onClick={() => fileInput.current?.click()}
        icon={<IconImportCSV />}
        tooltipTitle={'Upload CSV'}
      />
    </SpeedDial>
  </Container>) || null;
}
