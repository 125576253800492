import {useAssets} from '@/context/AssetsProvider';
import {generateDebug} from '@/utils';
import {FileGrid} from '@components/file-object/FileGrid';
import {Box, SpeedDial, SpeedDialAction, SvgIconTypeMap} from '@mui/material';
import * as React from 'react'
import {useRenameHandler} from "@/hooks/use-rename-handler";
import {IconCreateFolder, IconSpeedDial, IconUploadFile} from '@/icons';
import GenericUploader from "@components/file-object/GenericUploader";
import {FileList} from "@components/file-object/FileList";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useFolderUtils} from "@/hooks/use-folder-utils";
import {NameDialog} from "@components/NameDialog";
import {FileFilterType, FileFolderTree, FileOrFolder, MinimalFileObject} from "@components/file-object/types";

const debug = generateDebug('FilesView')

interface FilesViewProps {
  organizationId: string
  selectType?: FileFilterType,
  viewMode: 'grid' | 'list'
  sortMode?: 'name' | 'date'
  sortDirection?: 'asc' | 'desc'
  filter?: FileFilterType
  onSelect?: (file: FileOrFolder|null) => void
  selected?: MinimalFileObject
  fixed?: boolean
}

type ActionType = {
  iconElement: OverridableComponent<SvgIconTypeMap>,
  name: string,
  // color: string
  type: 'file' | 'folder'
}
const actions: ActionType[] = [
  {
    iconElement: IconUploadFile,
    // color: '#050',
    name: 'Upload files',
    type: 'file',
  },
  {
    iconElement: IconCreateFolder,
    // color: '#000',
    name: 'Create folder',
    type: 'folder',
  },
];

type NameHandler = {
  type: string,
  name: string,
  handler: (name: string | null) => void
  namesInUse?: string[]
}

export function FilesView(props: FilesViewProps) {
  const {viewMode, organizationId, filter, sortMode, sortDirection} = props
  const {currentFolder, setCurrentFolder} = useAssets()
  const {
    handleCreate,
    handleRename: handleFolderRename,
    inProgress,
    handleDelete,
    handleDeleteFile
  } = useFolderUtils(organizationId)
  const documentUploader = React.useRef<{ chooseFiles: () => void }>(null)
  const {renaming: renamingFile, handleRename: handleFileRename} = useRenameHandler(organizationId)
  const [nameHandler, setNameHandler] = React.useState<undefined | NameHandler>(undefined)

  const onDelete = React.useCallback((item: MinimalFileObject | FileFolderTree) => {
    if ('url' in item) {
      handleDeleteFile(item).then()

    } else {
      handleDelete(item).then()
    }
  }, [handleDelete])
  const onCreate = React.useCallback((action: ActionType) => {
    switch (action.type) {
      case "file":
        documentUploader.current?.chooseFiles()
        break;
      case "folder":
        const nameHandler: NameHandler = {
          type: 'folder',
          name: 'New folder',
          namesInUse: currentFolder.folders?.map(f => f.name),
          handler: async (name: string | null) => {
            if (name) {
              if (await handleCreate(currentFolder, name)) {
                setNameHandler(undefined)
              }
            }
            setNameHandler(undefined)
          }
        }
        setNameHandler(nameHandler)

        break;

    }
  }, [currentFolder])
  const renameHandler = React.useCallback((fileOrFolder: MinimalFileObject | FileFolderTree) => {
    const isFile = 'url' in fileOrFolder
    const initialName = isFile ? fileOrFolder.name || fileOrFolder.originalName : fileOrFolder.name;
    const renameHandler: NameHandler = {
      type: isFile ? fileOrFolder.type! : 'folder',
      name: initialName,
      namesInUse: isFile ? undefined : currentFolder.folders?.map(f => f.name).filter(n => n !== initialName),

      handler: async (name: string | null) => {
        if (name) {
          let result
          if (isFile) {
            result = await handleFileRename(fileOrFolder, name)
          } else {
            result = await handleFolderRename(fileOrFolder, name)
          }
          if (result) {
            setNameHandler(undefined);
          }
        }
        setNameHandler(undefined)
      }

    }
    setNameHandler(renameHandler)
  }, [handleFileRename, handleFolderRename, currentFolder])
  return currentFolder
    ? (<div>
      <Box sx={{p: 1}}>

        {viewMode == 'grid'
          ? <FileGrid
            organizationId={organizationId}
            folder={currentFolder}
            onRename={renameHandler}
            onSelect={props.onSelect}
            onDelete={onDelete}
            filter={filter}
            sortMode={sortMode}
            sortDirection={sortDirection}
            selectType={props.selectType}
            onGoToFolder={setCurrentFolder}
          />
          : <FileList
            organizationId={organizationId}
            folder={currentFolder}
            onRename={renameHandler}
            onSelect={props.onSelect}
            onDelete={onDelete}
            filter={filter}
            sortMode={sortMode}
            sortDirection={sortDirection}
            selectType={props.selectType}
            onGoToFolder={setCurrentFolder}
            />
        }
      </Box>
      <GenericUploader ref={documentUploader} organizationId={organizationId} folderId={currentFolder.id}/>
      <SpeedDial
        ariaLabel="Create"
        sx={{position: props.fixed ? 'fixed' : 'absolute', bottom: props.fixed ? 24 : 24 + 48, right: 24}}
        icon={<IconSpeedDial/>}

      >
        {actions.map((action) => {
          const Icon = action.iconElement
          return (
            <SpeedDialAction
              onClick={() => !inProgress && !renamingFile && onCreate(action)}
              key={action.name}
              icon={<Icon/>}
              tooltipTitle={action.name}
            />
          );
        })}
      </SpeedDial>
      {nameHandler &&
        <NameDialog
          disabled={renamingFile || inProgress}
          entityType={nameHandler.type}
          name={nameHandler.name}
          handler={nameHandler.handler}
          namesInUse={nameHandler.namesInUse}
        />}
    </div>)
    : null
}
