import {FileFilterType, FileFolderTree, FileOrFolder, MinimalFileObject} from '@components/file-object/types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react'
import {Box, Stack, styled} from "@mui/material";
import {ViewModeSelector} from "@components/file-object/ViewModeSelector";
import {FileFilterSelector} from "@components/file-object/FileFilterSelector";
import {FolderPath} from "@components/file-object/FolderPath";
import {FilesView} from "@components/file-object/FilesView";
import {SortModeSelector} from "@components/file-object/SortModeSelector";
import {useLocalState} from "@/hooks/use-local-state";

const TallDialog = styled(Dialog)(({theme}) => ({
  [`& .MuiDialog-paper`]: {
    height: 'calc(100% - 128px)',
  }
}))
interface FileObjectChooserProps {
  organizationId: string
  type?: 'image' | 'video' | 'document'
  selected?: MinimalFileObject
  open: boolean
  folder: FileFolderTree
  onGoToFolder: (folder: FileFolderTree) => void
  onSelect?: (val: MinimalFileObject | null) => void
  onClose: () => void
}

export function FileObjectChooser<T extends MinimalFileObject>(props: FileObjectChooserProps) {
  const [viewMode, setViewMode] = useLocalState<'grid' | 'list'>('FILES_VIEW_MODE', 'grid')
  const [sortMode, setSortMode] = useLocalState<'name' | 'date'>('FILES_SORT_MODE', 'name')
  const [sortDirection, setSortDirection] = useLocalState<'asc' | 'desc'>('FILES_SORT_DIRECTION', 'asc')
  const [filter, setFilter] = React.useState<FileFilterType | undefined>()


  const selectNone = () => {
    props.onSelect && props.onSelect(null)
  }
  return (
    <TallDialog open={props.open && !!props.type} onClose={props.onClose} fullWidth maxWidth={'lg'}>
      <DialogTitle id="scroll-dialog-title">
        <Stack sx={{rowGap: 1}}>
          {props.onSelect&&<span>Choose {props.type}</span>}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <ViewModeSelector setViewMode={setViewMode} viewMode={viewMode}/>
            <SortModeSelector setSortMode={setSortMode} sortMode={sortMode} setSortDirection={setSortDirection} sortDirection={sortDirection}/>
            <FileFilterSelector setFilter={setFilter} filter={filter}/>
          </Box>
          <Box><FolderPath/></Box>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FilesView
          selectType={props.type==='document'?undefined:props.type}
          organizationId={props.organizationId}
          onSelect={props.onSelect as (val: FileOrFolder|null) => void}
          viewMode={viewMode}
          sortMode={sortMode}
          sortDirection={sortDirection}
          filter={filter} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{props.onSelect?'Cancel':'Close'}</Button>
        {props.onSelect && <Button onClick={() => props.onSelect && props.onSelect(null)}>Select none</Button>}
      </DialogActions>
    </TallDialog>
  )
}
