import {IGQLJrnyOrganization} from '@/graphql-types';
import {generateDebug} from '@/utils';
import {useOrganizationQuery} from '@graphql/organizations-hook';
import * as React from 'react';
import {ReactNode} from 'react';
import {useCurrentUser} from "@/context/CurrentUserProvider";
import {IGQLOrganizationQuery} from "@graphql/organizations-hook";
import {Unpacked} from "@/types";
import {sortBy, orderBy} from 'lodash';

export type Organization = Unpacked<IGQLOrganizationQuery['jrnyOrganization']>
const debug = generateDebug('OrganizationProvider');
debug('Running')

const saveOrganizationId = (id?: string | null) => {
  if (!id) {
    window.localStorage.removeItem('currentOrganization')
  } else {
    window.localStorage.setItem('currentOrganization', id);
  }
};
const saved = window.localStorage.getItem('currentOrganization')

interface OrganizationContextValue {
  organization: Organization | undefined;
  setOrganization: (id: string | null) => void;
}

export const OrganizationContext = React.createContext<OrganizationContextValue>({
  organization: undefined,
  setOrganization: () => {
  },
});

interface OrganizationProviderProps {
  children: ReactNode;
}

//Return an organization with the all the entities sorted by order
const deepSort = (organization?: Organization): Organization | undefined => {
  if (!organization) {
    return undefined
  }
  const sorted = {
    ...organization, courses: sortBy(organization.courses.map(c => {
      return {
        ...c, checkpoints: sortBy(c.checkpoints.map(cp => {
          return {
            ...cp, lessons: sortBy(cp.lessons.map(l => {
              return {...l, contentItems: sortBy(l.contentItems, 'order')}
            }), 'order')
          }
        }), ['order'])
      }
    }), ['name'])
  }
  return sorted
}

export function OrganizationProvider(props: OrganizationProviderProps) {
  const {currentUser} = useCurrentUser()
  const [organizationId, _setOrganizationId] = React.useState<string | null | undefined>(saved);
  const [organization, fetchOrganization] = useOrganizationQuery({
    variables: {id: organizationId!},
    pause: !organizationId,
  });
  const setOrganizationId = React.useCallback((id?: string | null) => {
    saveOrganizationId(id);
    _setOrganizationId(id);
  }, []);

  const value = React.useMemo(() => ({
    organization: organizationId ? deepSort(organization.data?.jrnyOrganization as Organization) : undefined,
    setOrganization: setOrganizationId,
  }), [organizationId, organization.data?.jrnyOrganization]);

  // Autoload if only one organization
  React.useEffect(() => {
    if (currentUser?.organizationIds?.length == 1) {
      if (currentUser.organizationIds[0] != organizationId) {
        debug('User changed and has one organization, setting currentOrganization to ' + currentUser.organizationIds[0])
        setOrganizationId(currentUser.organizationIds[0])
      }
    } else if (!currentUser) {
      debug('No user, setting currentOrganization to null')
      setOrganizationId(null)
    }
  }, [currentUser?.organizationIds, organizationId])

  return (<OrganizationContext.Provider value={value} {...props} />);
}

export const useCurrentOrganization = () => {
  const {
    organization: currentOrganization,
    setOrganization: setCurrentOrganization
  } = React.useContext(OrganizationContext);
  return {
    currentOrganization,
    setCurrentOrganization
  }
}
