import {ModifiedContext} from '@/context/ModifiedProvider';
import {usePrompt} from '@/hooks/use-prompt';
import {generateDebug} from '@/utils';
import {BottomNavigation, BottomNavigationAction, Paper} from '@mui/material';
import {ReactNode} from 'react';
import * as React from 'react'
import {IconCancel, IconSave} from "@/icons";

const debug = generateDebug('ModifiedGuard');

interface ModifiedGuardProps {
  children: ReactNode;

}

export default function ModifiedGuard(props: ModifiedGuardProps) {
  const modifiedContextValue = React.useContext(ModifiedContext)
  // debug('modifiedContextValue %o', modifiedContextValue)
  const prompt = usePrompt('Content modified', modifiedContextValue.isModified)
  return (<>
    {props.children}
    {modifiedContextValue.isModified && <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:1}} elevation={3}>
      <BottomNavigation
        showLabels
        value={undefined}
        onChange={(event, newValue) => {
          // debug('onChange', event, newValue)
          if( newValue === 0) {
            modifiedContextValue.handlers.reset && modifiedContextValue.handlers.reset()
          } else if( newValue === 1) {
            modifiedContextValue.handlers.save && modifiedContextValue.handlers.save()
          }
        }}
      >
        <BottomNavigationAction
          disabled={!modifiedContextValue.handlers.reset}
          label="Reset"
          icon={<IconCancel sx={{color:'#f00'}}/>}/>
        <BottomNavigationAction
          disabled={!modifiedContextValue.handlers.save}
          label="Save"
          icon={<IconSave sx={{color:'#0f0'}}/>}/>
      </BottomNavigation>
    </Paper>
    }
  </>)
}
