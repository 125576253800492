import {Button, ButtonGroup} from "@mui/material";
import {IconGrid, IconList} from "@/icons";
import * as React from "react";

export type ViewModeSelectorProps = {
  viewMode: "grid" | "list"
  setViewMode: (val: "grid" | "list") => void
}

export function ViewModeSelector(props: ViewModeSelectorProps) {
  const {viewMode, setViewMode} = props
  return <ButtonGroup variant={'outlined'}>
    <Button variant={viewMode === 'list' ? 'contained' : 'outlined'}
            onClick={() => setViewMode('list')}><IconList/></Button>
    <Button variant={viewMode === 'grid' ? 'contained' : 'outlined'}
            onClick={() => setViewMode('grid')}><IconGrid/></Button>
  </ButtonGroup>;
}
