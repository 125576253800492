import {generateDebug} from '@/utils';
import {mergeAttributes, Node} from '@tiptap/core'

const debug = generateDebug('PromptInputNode')

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    promptInput: {
      /**
       * Set the text align attribute
       */
      insertPromptInput: (index: number) => ReturnType,
    }
  }
}
export const PromptInputNode = Node.create({
  name: 'promptInput',
  priority: 200,
  inline: true,
  content: '',
  group: 'inline',
  atom: true,
  isolating: true,
  selectable: false,
  // Your code goes here.
  addAttributes() {
    return {
      promptIx: {
        parseHTML: element => element.getAttribute('data-prompt-ix'),
        renderHTML: attributes => {
          return {
            'data-prompt-ix': attributes.promptIx,
            // contenteditable: false,
          }
        },
      }
    }
  },
  renderHTML({HTMLAttributes, node}) {

    return ['span', mergeAttributes(HTMLAttributes)]
  },
  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: element => {
          // Check if the element has an attribute
          return (element as HTMLElement).hasAttribute('data-prompt-ix') && null
        },
      },
    ]
  },
  // addNodeView() {
  //   return () => {
  //     // Markup
  //     /*
  //       <div class="node-view">
  //         <span class="label">Node view</span>
  //
  //         <div class="content"></div>
  //       </div>
  //     */
  //
  //     const dom = document.createElement('span')
  //
  //     dom.classList.add('node-view')
  //
  //
  //     return {
  //       dom,
  //     }
  //   }
  // },
  addCommands() {
    return {
      insertPromptInput: (index: number) => ({chain, commands, editor, state}) => {
        debug('insertPromptInput: ' + index)
        // const nodeAfter = editor.view.state.selection.$to.nodeAfter
        // const overrideSpace = nodeAfter?.text?.startsWith(' ')
        // const {} = state.selection
        // if (overrideSpace) {
        //   range.to += 1
        // }
        return chain()
          .insertContent([{
            type: this.name,
            attrs: {promptIx: index},
            // content: [
            //   {type: 'text',
            //     text: ''+index
            //   }]
          }, {type: 'text', text:' '}]).run()
      }

    }
  }
})

