import { CurrentUser } from '@/context/CurrentUserProvider';
import debugFn from 'debug';
import {Client, ExchangeIO, Operation} from 'urql';
import {map, pipe} from 'wonka';
const debug = debugFn('auth-headers-exchange')

export const authHeadersExchange = (currentUser: CurrentUser)  => ({client, forward}: { forward: ExchangeIO; client: Client }): ExchangeIO => {
  return operations$ => {
    // <-- The ExchangeIO function
    const addHeaders = map((op: Operation) => {
      if (!currentUser) {
        debug('Not logged in, skipping')
        return op
      }
      return {
        ...op,
        context: {
          ...op.context,
          fetchOptions: {
            headers: {
              "Authorization": 'Bearer ' + currentUser.tokenResponse.token,
            },
          },
        },
      }
    });
    const operationResult$ = pipe(operations$, addHeaders, forward);

    return operationResult$;
  };
};
