import {CurrentUser, useCurrentUser} from '@/context/CurrentUserProvider';
import {IGQLFileObject} from '@/graphql-types';
import {Network} from '@/network';
import {generateDebug} from '@/utils';
import * as React from 'react';
import {ReactNode} from 'react';
import {AxiosRequestConfig} from "axios";

const debug = generateDebug('NetworkProvider');
debug('Running')
const extractResult = (r: any) => {
  return r.data;
};
const debugResponse = (method: string) => (res: object) => {
  debug('%s %O', method, res);
  return res;
};

interface NetworkContextShape {
  get: (url: string, params?: AxiosRequestConfig) => Promise<any>,
  addFile: (organizationId: string, folderId: string, formData: FormData, type: string, updateAction?: (value: number) => void) => Promise<any>
}

export const NetworkContext = React.createContext<NetworkContextShape>({
  addFile: async () => {
  },
  get: async () => {}
})

interface NetworkProviderProps {
  children?: ReactNode;

}

export function NetworkProvider(props: NetworkProviderProps) {
  const {currentUser, signOut} = useCurrentUser()
  const value = React.useMemo(() => {
    const network = new Network({
      getToken: () => currentUser?.tokenResponse.token || null,
      refreshLoginHandler: async () => {
        throw new Error()
      },
      logoutHandler: signOut
    })
    return {
      get: async (url: string, params?: AxiosRequestConfig) => {
        return await network.authorized.get(url, params);


      },
      addFile: async (organizationId: string, folderId: string, formData: FormData, type: string, updateAction?: (value: number) => void) => {
        const onUploadProgress = (progressEvent: any) => {
          debug('Upload progress', progressEvent);
          if (updateAction && progressEvent) {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateAction(percent);
          }
        };
        const result = await network.authorized.post(
          `/api/upload/${organizationId}/${folderId}/${type}`,
          formData, {onUploadProgress});
        debugResponse('addImage')(result);
        return extractResult(result);
      }
    }
  }, [currentUser])
  return (<NetworkContext.Provider value={value} {...props} />);
}
