import {TopNavigation} from '@/top-navigation'
import * as React from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useCurrentUser} from "@/context/CurrentUserProvider";

/**
 * Needs to be logged in, if not, redirects to sign-in
 * TODO: If logged in but lacks permissions, show not allowed
 * @param props
 * @constructor
 */
function RequireAuth({ children }: { children: JSX.Element }) {
  const {currentUser} = useCurrentUser()
  let location = useLocation();

  if (!currentUser) {
    return <Navigate to={TopNavigation.SignIn} state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth
