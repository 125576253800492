import * as React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {IconDelete} from "@/icons";

interface DeleteDialogProps {
  entityType: string
  handler?: ((remove: boolean) => void)
  disabled: boolean
}

export function DeleteDialog(props: DeleteDialogProps) {
  const {handler, entityType, disabled} = props
  return handler ? (

    <Dialog
      open={!!handler}
      onClose={() => {
        handler(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Remove this {entityType}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will delete all content but <strong>NOT</strong> the images, media or documents used in the {entityType}<br/><br/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={disabled} onClick={() => {
          handler(false);
        }} color={'info'} variant={'contained'} autoFocus>Cancel</Button>
        <Button disabled={disabled} startIcon={<IconDelete/>} onClick={() => handler(true)} color={'error'}
                variant={'contained'}>

          Remove
        </Button>
      </DialogActions>
    </Dialog>

  ) : null
}
