import {useResetPasswordMutation} from '@graphql/password-hook';
import {TopNavigation} from '@/top-navigation';
import AppAppBar from '@components/AppAppBar';
import AppForm from '@components/AppForm';
import Button from '@components/Button';
import FormButton from '@components/form/FormButton';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import {password, required, verifyPassword} from '@components/form/validation';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import debugFn from 'debug';
import {FORM_ERROR} from 'final-form';
import * as React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useCurrentUser} from "@/context/CurrentUserProvider";

const debug = debugFn('ResetPassword')

function ResetPassword() {
  const [queryString] = useSearchParams()
  const [passwordUpdated, setPasswordUpdated] = React.useState(false)
  const {code} = useParams()
  const navigate = useNavigate()
  const {currentUser, setCurrentUser, signOut} = useCurrentUser()
  const [_, resetMutation] = useResetPasswordMutation()

  React.useEffect(() => {
    if (currentUser) {
      signOut()
    }
  }, [])

  const validate = (values: { [index: string]: string }) => {
    const errors = required(['password', 'verifyPassword'], values);

    if (!errors.password) {
      const passwordError = password(values.password);
      if (passwordError) {
        errors.password = passwordError;
      }
    }
    if (!errors.verifyPassword) {
      const passwordError = verifyPassword(values.verifyPassword, values.password);
      if (passwordError) {
        errors.verifyPassword = passwordError;
        errors.password = passwordError;
      }
    }
    return errors;
  };

  const handleSubmit = async (values: { password: string }) => {
    debug('Submitting', values)
    const result = await resetMutation({
      input: {
        code: code!,
        password: values.password,
      }
    })
    if (result.error) {
      debug('error', result.error)
      return {[FORM_ERROR]: 'Failed to set new password'}
    }
    setPasswordUpdated(true)
  };

  return (
    <React.Fragment>
      <AppAppBar/>
      {!passwordUpdated && (<AppForm>
          <React.Fragment>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Choose a new password
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{submitting: true}}
            validate={validate}
            initialValues={{code: queryString.get('code') || ''}}
          >
            {({handleSubmit: handleSubmit2, submitting}) => (
              <Box component="form" onSubmit={handleSubmit2} noValidate sx={{mt: 6}}>
                <Field
                  fullWidth
                  component={RFTextField}
                  disabled={submitting }
                  required
                  name="password"
                  autoComplete="new-password"
                  label="Password"
                  type="password"
                  margin="normal"
                />
                <Field
                  fullWidth
                  component={RFTextField}
                  disabled={submitting }
                  required
                  name="verifyPassword"
                  autoComplete="new-password"
                  label="Verify password"
                  type="password"
                  margin="normal"
                />
                <FormSpy subscription={{submitError: true}}>
                  {({submitError}) =>
                    submitError ? (
                      <FormFeedback error sx={{mt: 2}}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{mt: 3, mb: 2}}
                  disabled={submitting}
                  color="secondary"
                  fullWidth
                >
                  {submitting ? 'In progress…' : 'Confirm'}
                </FormButton>
              </Box>
            )}
          </Form>
        </AppForm>
      ) || (
        <AppForm>
          <React.Fragment>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              New password successfully set!
            </Typography>
          </React.Fragment>
          <Box sx={{mt: 6}}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => navigate(TopNavigation.SignIn, {replace: true})}>Sign in</Button>

          </Box>
        </AppForm>
      )}
    </React.Fragment>
  );
}

export default ResetPassword;
