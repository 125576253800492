import { IGQLJrnyUpdatePromptInput } from '@/graphql-types';
import { useLocalChange } from '@/hooks/use-local-change';
import { actions, ActionType, LocalPrompt, OrgPrompt } from '@/pages/prompt/types';
import { generateDebug } from '@/utils';
import { usePromptQuery, useUpdatePromptMutation } from '@graphql/prompts-hook';
import { Prompt, PromptInput, PromptJson } from '@journey-lti-tool/common';
import { PromptView, InputUploaderProvider } from '@journey-lti-tool/common-web';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SpeedDial,
  SpeedDialAction,
  Tabs,
  TextField, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material'
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Tab from '@mui/material/Tab';
import { isEqual } from 'lodash';
import { nanoid } from 'nanoid'
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import PromptDescriptionEditor from './components/PromptDescriptionEditor';
import PromptInputListItem from './components/PromptInputListItem';
import { BackButton } from "@components/BackButton";

const debug = generateDebug('PromptDetailsView')

interface PromptDetailsViewProps {

}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', py: 3, mb: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function PromptDetailsView(props: PromptDetailsViewProps) {
  const { promptId } = useParams()
  const [promptQuery] = usePromptQuery({ variables: { id: promptId || '' }, pause: !promptId })
  const [_, updatePromptMutation] = useUpdatePromptMutation()

  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  
  const [localPrompt, setLocalPrompt] = useLocalChange<OrgPrompt, LocalPrompt>(
    promptQuery.data?.jrnyPrompt,
    (original, prompt) => {
      const local = prompt.toJSON()
      const inputs = local.inputs.map(i => ({ ...i, __typename: undefined }))
      const aiAvailable = !inputs.some(i => i.type == 'upload')
      const input: IGQLJrnyUpdatePromptInput = {
        id: promptId!,
        name: local.name,
        isTeam: local.isTeam,
        description: local.description,
        submitPoints: local.submitPoints,
        correctPoints: local.correctPoints,
        duration: local.duration,
        useAIAssessment: local.useAIAssessment && aiAvailable,
        requireAnyInputs: local.requireAnyInputs,
        inputs,
      }
      return updatePromptMutation({ input })
    },
    // Original to local
    input => {
      const prompt = Prompt.fromJSON(input as PromptJson)
      // debug('new local prompt', prompt)
      return prompt
    },
    // Local to original
    prompt => {
      const v =  prompt.toJSON() as OrgPrompt;
      // debug('new org prompt', v)
      return v
    },
    // Custom isEqual
    (org, local) => {
      debug('org:', org, "local:",local)
      return isEqual(org, local)
    }
  )
  const isAIAvailable = React.useMemo(() => {
    return !localPrompt?.inputs.some(i => i.type == 'upload')
  }, [localPrompt])
  // debug(localPrompt, localPrompt?.toJSON(), promptQuery.data?.jrnyPrompt)
  const setDescription = React.useCallback((description: string) =>
    setLocalPrompt(draft => {
      draft!.description = description
    }), [])

  const setName = React.useCallback((evt: ChangeEvent<HTMLInputElement>) =>
    setLocalPrompt(draft => {
      draft!.name = evt.target.value
    }), [])

  const setInput = React.useCallback((v: PromptInput<any, any>) =>
    setLocalPrompt(draft => {
      const ix = draft!.inputs.findIndex(i => i.id == v.id)
      if (ix >= 0) {
        draft!.inputs[ix] = v
      }
    }), [])

    React.useEffect(() => {
      debug('Local prompt changed', localPrompt)
    }, [localPrompt])
  const create = React.useCallback((action: ActionType) => setLocalPrompt(draft => {
    draft!.inputs.push(PromptInput.fromJSON({ ...action.template, id: nanoid(5) }))
  }), [])
  const remove = React.useCallback((v: { id: string }) => setLocalPrompt(draft => {
    draft!.inputs = draft!.inputs.filter(i => i.id != v.id)
  }), [])

  return localPrompt ? (<>
    <BackButton sx={{ mb: 1 }} />
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item sm={12}><TextField
          label='Name/Title'
          fullWidth
          size='small'
          value={localPrompt.name}
          onChange={setName}
        /></Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            size='small'
            type={'number'}
            label='Estimated duration'
            value={localPrompt.duration}
            onChange={(evt) => setLocalPrompt(draft => {
              draft!.duration = Math.max(0, Number.parseFloat(evt.target.value))
            })} />
        </Grid>
        <Grid item sm={2}>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={localPrompt.isTeam ? '1' : '_'}
            exclusive
            size='small'
            onChange={(evt, val) => setLocalPrompt(draft => {
              draft!.isTeam = val == '1'
            })}>
            <ToggleButton fullWidth value="_">Individual</ToggleButton>
            <ToggleButton fullWidth value="1">Team</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item sm={2}>
          <FormControl fullWidth>
            <InputLabel id="input-required-label">Required Inputs</InputLabel>
            <Select
              labelId="input-required-label"
              label='Required Inputs'
              size='small'
              displayEmpty
              value={localPrompt.requireAnyInputs ? '1' : '_'}
              onChange={(evt) => setLocalPrompt(draft => {
                draft!.requireAnyInputs = evt.target.value == '1'
              })}>
              <MenuItem value='_'>ALL</MenuItem>
              <MenuItem value='1'>ANY</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={2}>

          <TextField
            fullWidth
            size='small'
            type={'number'}
            label='Submission Points'
            value={localPrompt.submitPoints}
            onChange={(evt) => setLocalPrompt(draft => {
              draft!.submitPoints = Math.max(0, Number.parseFloat(evt.target.value))
            })} />
        </Grid>
        <Grid item sm={2}>

          <TextField
            fullWidth
            size='small'
            type={'number'}
            label='Assessment Points'
            value={localPrompt.correctPoints}
            onChange={(evt) => setLocalPrompt(draft => {
              draft!.correctPoints = Math.max(0, Number.parseFloat(evt.target.value))
            })} />
        </Grid>
        <Grid item sm={2}>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={localPrompt.useAIAssessment && isAIAvailable ? '1' : '_'}
            exclusive
            size='small'
            onChange={(evt, val) => setLocalPrompt(draft => {
              draft!.useAIAssessment = val == '1'
            })}>
            <ToggleButton fullWidth value="_">Manual</ToggleButton>
            <ToggleButton disabled={!isAIAvailable} fullWidth value="1">AI</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item sm={12} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="Edit" />
              <Tab label="Preview" />
            </Tabs>
          </Box>
          {/* Editor panel */}
          <TabPanel value={tab} index={0}>
            <PromptDescriptionEditor
              inputs={localPrompt.inputs}
              onChange={setDescription} content={localPrompt.description} />
          </TabPanel>
          {/* Preview panel */}
          <TabPanel value={tab} index={1}>
            <InputUploaderProvider>
              <PromptView prompt={localPrompt} debug />
            </InputUploaderProvider>
          </TabPanel>
        </Grid>
      </Grid>
      <Typography variant='caption'>Inputs</Typography>
      {localPrompt.inputs.map((v, i) => (<PromptInputListItem
        promptInput={v}
        key={v.id}
        onRemove={remove}
        onChange={setInput}
      />
      ))
      }

      {/*<DebugView>{JSON.stringify(localPrompt, null, 2)}</DebugView>*/}
    </Paper>
    <SpeedDial
      ariaLabel="Create"
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}

    >
      {actions.map((action) => (
        <SpeedDialAction
          onClick={() => create(action)}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
        />
      ))}
    </SpeedDial></>
  )
    : null
}
