import {IGQLJrnyOrganization} from '@/graphql-types';
import {List, ListItem, ListItemText} from '@mui/material';
import * as React from 'react';
import {IGQLOrganizationQuery, useOrganizationUsersQuery} from '@graphql/organizations-hook';
import {Unpacked} from "@/types";
export type Organization = Unpacked<IGQLOrganizationQuery['jrnyOrganization']>

interface OrganizationUsersListProps {
  currentOrganization: Organization;
}

export  function OrganizationUsersList(props: OrganizationUsersListProps) {
  const [users] = useOrganizationUsersQuery({
    variables: { id:  props.currentOrganization?.id || ''  },
  });


  const filteredUsers = React.useMemo(
    () => users.data?.jrnyOrganizationUsers || [],
    [users]);
  return filteredUsers.length && (
          <List>
            {filteredUsers.map(user => (<ListItem
                                                  key={user.id}>
              <ListItemText primary={user.firstName + ' ' + user.lastName} secondary={user.email} />
            </ListItem>))}
          </List>
    ) || null;
}
