import { TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { useForm, Controller, UseControllerProps, FieldValues, useController } from 'react-hook-form';

export type FormTextFieldProps<T extends FieldValues, E = any> = TextFieldProps & UseControllerProps<T> & {
  toFieldValue?: (value: string) => E;
  fromFieldValue?: (value: E) => string;
};

export function FormTextField<T extends FieldValues, E=any>(props: FormTextFieldProps<T, E>) {
  const { field, fieldState } = useController(props);

  const { helperText, name, control, rules, fromFieldValue, toFieldValue, ...rest } = props;

  return (
    <TextField
      error={!!fieldState.error}
      onChange={(e) => field.onChange(toFieldValue ? toFieldValue(e.target.value) as E : e)} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={fromFieldValue ? fromFieldValue(field.value as E) : field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      helperText={fieldState.error?.message || helperText}
      {...rest}
    />);
}

