import {ModifiedContext} from '@/context/ModifiedProvider';
import {IGQLJrnyUpdateCheckpointInput, IGQLJrnyUpdateLessonInput} from '@/graphql-types';
import {generateDebug} from '@/utils';
import DraggableList from '@components/draggable/DraggableList';
import {IGQLCheckpointQuery, useCheckpointQuery, useUpdateCheckpointMutation} from '@graphql/checkpoints-hook';
import {IconAdd, IconGoTo, IconDelete, IconEdit, IconLesson} from '@/icons';
import {Avatar, Fab, IconButton, ListItem, ListItemAvatar, ListItemText, Paper} from '@mui/material';
import {nothing} from 'immer';
import {uuidv4} from 'lib0/random';
import {isEqual} from 'lodash'
import {useSnackbar} from 'notistack';
import * as React from 'react'
import {DraggableProvided, DraggableStateSnapshot, DropResult} from 'react-beautiful-dnd';
import {useNavigate, useParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import EditLessonDialog from './components/EditLessonDialog';
import {SimpleLesson} from './types';
import {BackButton} from "@components/BackButton";

const debug = generateDebug('CheckpointDetailsView');

interface CheckpointDetailsViewProps {

}

export default function CheckpointDetailsView(props: CheckpointDetailsViewProps) {
  const modifiedContextValue = React.useContext(ModifiedContext)
  const [currentLesson, setCurrentLesson] = React.useState<SimpleLesson>()
  const {enqueueSnackbar} = useSnackbar()
  const {checkpointId} = useParams()
  const navigate = useNavigate()

  const [checkpoint] = useCheckpointQuery({variables: {id: checkpointId || ''}, pause: !checkpointId})
  const [_, updateCheckpoint] = useUpdateCheckpointMutation()
  const [localCheckpoint, setLocalCheckpoint] = useImmer<IGQLCheckpointQuery['jrnyCheckpoint'] | undefined>(undefined)

  // Set the local state when it loads
  React.useEffect(() => {
    if (checkpoint) {
      if (checkpoint.data) {
        setLocalCheckpoint((draft) => checkpoint.data!.jrnyCheckpoint)
      } else {
        setLocalCheckpoint((draft) => nothing)
      }
    }
  }, [checkpoint])

  // Update modified flag when changes are made
  React.useEffect(() => {
    modifiedContextValue.setModified(checkpoint.data?.jrnyCheckpoint ? !isEqual(checkpoint.data?.jrnyCheckpoint, localCheckpoint) : false)
  }, [checkpoint, localCheckpoint])

  // Create a reset function to restore local state
  const reset = React.useCallback(() => {
    setLocalCheckpoint((draft) => checkpoint.data!.jrnyCheckpoint)

  }, [checkpoint.data])

  //
  React.useEffect(() => {
    const saveCheckpointChanges = async () => {
      if (!localCheckpoint || !checkpoint?.data) {
        return
      }
      const orgCheckpoint = checkpoint.data.jrnyCheckpoint
      const lessonsAdded = localCheckpoint.lessons
        .filter(lcp => !orgCheckpoint.lessons.find(cp => cp.id === lcp.id))
        .map(lcp => ({id: lcp.id, order: lcp.order, name: lcp.name, checkpointId: checkpointId!}))
      const lessonsDeleted = orgCheckpoint.lessons
        .filter(cp => !localCheckpoint.lessons.find(lcp => cp.id === lcp.id))
        .map(cp => ({id: cp.id}))
      const lessonsModified = localCheckpoint.lessons
        .map(lcp => {
          const org = orgCheckpoint.lessons.find(cp => cp.id === lcp.id)
          if (!org || isEqual(lcp, org)) {
            return null
          }
          const update: IGQLJrnyUpdateLessonInput = {id: lcp.id}
          if (org.name !== lcp.name) {
            update.name = lcp.name
          }
          if (org.order !== lcp.order) {
            update.order = lcp.order
          }
          return update
        })
        .filter(lcp => lcp !== null) as IGQLJrnyUpdateLessonInput[]
      // TODO: Add name
      const input: IGQLJrnyUpdateCheckpointInput = {
        id: checkpointId!,
        lessonsAdded,
        lessonsDeleted,
        lessonsModified
      }
      debug('input %o', input)
      const result = await updateCheckpoint({input})
      if (result.error) {
        debug('error %o', result.error)
        enqueueSnackbar(result.error.message, {variant: 'error'})
      } else {
        enqueueSnackbar('Saved', {variant: 'success', autoHideDuration: 1000})
      }
    }

    modifiedContextValue.setHandlers({
      save: () => {
        saveCheckpointChanges().then()
      }, reset: () => {
        reset()
      }
    })
    return () => {
      modifiedContextValue.setHandlers({})
      modifiedContextValue.setModified(false)

    }
  }, [localCheckpoint])


  const addLesson = React.useCallback(() => {
    setLocalCheckpoint(draft => {
      const newLesson = {
        __typename: 'JrnyLesson',
        checkpointId: checkpointId!,
        name: 'New Lesson ' + (draft!.lessons.length + 1),
        id: uuidv4(),
        order: draft!.lessons.length,
      } as const
      draft!.lessons.push(newLesson)
    })
  }, [])


  const onDragEnd = ({destination, source}: DropResult) => {

    // dropped outside the list
    if (!destination) return;

    setLocalCheckpoint(draft => {
      const moved = draft!.lessons.splice(source.index, 1)
      draft!.lessons.splice(destination.index, 0, ...moved)
      draft!.lessons.forEach((p, i) => p.order = i)
    })
  };

  const onSetName = (item: SimpleLesson, newName: string) => {
    setLocalCheckpoint(draft => {
      const found = draft!.lessons.find(p => p.id === item.id)
      if (found) {
        found.name = newName
      }
    })
    setCurrentLesson(undefined)
  }

  const onDelete = (index: number) => {
    setLocalCheckpoint(draft => {
      draft!.lessons.splice(index, 1)
      draft!.lessons.forEach((p, i) => p.order = i)
    })
  }
  const onClose = () => setCurrentLesson(undefined)
  const onEdit = (item: SimpleLesson) => setCurrentLesson(item)
  const onNavigate = (item: SimpleLesson) => navigate('../lesson/' + item.id)

  const listItemFactory = React.useCallback(
    (item: SimpleLesson,
     index: number,
     provided: DraggableProvided,
     snapshot: DraggableStateSnapshot) => (        <ListItem
      secondaryAction={(<>
        <IconButton onClick={() => onDelete(index)} edge='end'><IconDelete /></IconButton>
        <IconButton onClick={() => onEdit(item)} edge='end'><IconEdit /></IconButton>
        <IconButton onClick={() => onNavigate(item)} edge='end'><IconGoTo /></IconButton>
      </>)
      }
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={snapshot.isDragging?{background: 'rgb(235,235,235)'}:{}}
    >
      <ListItemAvatar>
        <Avatar>
          <IconLesson />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={(item.order+1)+' '+item.name} secondary={item.id} />
    </ListItem>
  ), [])

  return (<div>
    {localCheckpoint ? (<>
        <BackButton sx={{mb:1}}/>

        <Paper>
          <DraggableList
            items={localCheckpoint.lessons}
            onDragEnd={onDragEnd}
          >{listItemFactory}</DraggableList>
        </Paper>
        <EditLessonDialog
          lesson={currentLesson}
          onCancel={onClose}
          onSave={onSetName}
        />
        <Fab color='primary' onClick={addLesson} sx={{position: 'fixed', bottom: 24, right: 24}}><IconAdd/></Fab>
      </>)
      : <div>Nothing</div>}

  </div>)
}
