import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLPromptDetailsFragment = { __typename: 'JrnyPrompt', id: string, description: string, category: string, name: string, isTeam: boolean, correctPoints: number, submitPoints: number, duration: number, requireAnyInputs: boolean, useAIAssessment: boolean, inputs: Array<{ __typename: 'JrnyPromptInput', id: string, type: string, version: number, weight: number, minContribution: number, config: any }> };

export type IGQLPromptQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLPromptQuery = { __typename: 'Query', jrnyPrompt: { __typename: 'JrnyPrompt', id: string, description: string, category: string, name: string, isTeam: boolean, correctPoints: number, submitPoints: number, duration: number, requireAnyInputs: boolean, useAIAssessment: boolean, inputs: Array<{ __typename: 'JrnyPromptInput', id: string, type: string, version: number, weight: number, minContribution: number, config: any }> } };

export type IGQLUpdatePromptMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdatePromptInput;
}>;


export type IGQLUpdatePromptMutation = { __typename: 'Mutation', jrnyUpdatePrompt: { __typename: 'JrnyPrompt', id: string, description: string, category: string, name: string, isTeam: boolean, correctPoints: number, submitPoints: number, duration: number, requireAnyInputs: boolean, useAIAssessment: boolean, inputs: Array<{ __typename: 'JrnyPromptInput', id: string, type: string, version: number, weight: number, minContribution: number, config: any }> } };

export const PromptDetailsFragmentDoc = gql`
    fragment PromptDetails on JrnyPrompt {
  __typename
  id
  description
  category
  name
  isTeam
  correctPoints
  submitPoints
  duration
  requireAnyInputs
  useAIAssessment
  inputs {
    __typename
    id
    type
    version
    weight
    minContribution
    config
  }
}
    `;
export const PromptDocument = gql`
    query prompt($id: ID!) {
  jrnyPrompt(id: $id) {
    ...PromptDetails
  }
}
    ${PromptDetailsFragmentDoc}`;

export function usePromptQuery(options: Omit<Urql.UseQueryArgs<IGQLPromptQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLPromptQuery, IGQLPromptQueryVariables>({ query: PromptDocument, ...options });
};
export const UpdatePromptDocument = gql`
    mutation updatePrompt($input: JrnyUpdatePromptInput!) {
  jrnyUpdatePrompt(input: $input) {
    ...PromptDetails
  }
}
    ${PromptDetailsFragmentDoc}`;

export function useUpdatePromptMutation() {
  return Urql.useMutation<IGQLUpdatePromptMutation, IGQLUpdatePromptMutationVariables>(UpdatePromptDocument);
};