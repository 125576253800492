import {generateDebug} from '@/utils';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import {email, required} from '@components/form/validation';
import {IGQLInviteToOrganizationMutationVariables} from '@graphql/invitations-hook';
import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FORM_ERROR} from 'final-form';
import * as React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {IconAdd} from "@/icons";

const debug = generateDebug('InviteToOrganizationDialog');

interface InviteToOrganizationDialogProps {
  inviteToOrganization: (variables: IGQLInviteToOrganizationMutationVariables) => Promise<any>;
  organizationId: string
}
type Values = Omit<IGQLInviteToOrganizationMutationVariables['input'], 'organizationId'>
export default function InviteToOrganizationDialog(props: InviteToOrganizationDialogProps) {
  const [open, setOpen] = React.useState(false);

  const validate = (values: Record<string, any>) => {
    const errors = required([ 'email'], values);
    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (values: Values) => {
    debug('handleSubmit', values);
    const result = await props.inviteToOrganization({
      input: {
        organizationId: props.organizationId,
        name: values.name,
        email: values.email,
        message: values.message

      } });
    debug('Submit result', result);
    if (result.error) {
      return { [FORM_ERROR]: result.error.graphQLErrors[0].message };
    }
    setOpen(false);
  };

  return (
    <div>
      <Fab color='primary' onClick={handleClickOpen} sx={{ position: 'fixed', bottom: 24, right: 24 }}><IconAdd /></Fab>
      <Form
        onSubmit={handleSubmit}
        subscription={{ submitting: true }}
        validate={validate}
      >
        {({ handleSubmit: handleSubmit2, submitting, form }) => (<>
          <Dialog open={open} onClose={submitting?()=>{}:handleClose}>
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter name of organization. Must be unique
              </DialogContentText>
              <Box component='form' onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
                <Field
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label='Email'
                  margin='dense'
                  name='email'
                  type='email'
                  required
                  size='normal'
                />
                <Field
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label='Name (internal, for your reference only)'
                  margin='dense'
                  name='name'
                  type='text'
                  size='normal'
                />
                <Field
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  multiline
                  label='Message (additional message sent in the email)'
                  margin='dense'
                  name='message'
                  size='normal'
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
              <Button disabled={submitting} onClick={form.submit}>Create</Button>
            </DialogActions>
          </Dialog>
        </>)}
      </Form>
    </div>
  );
}
