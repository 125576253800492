import {Box, Menu, MenuItem} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {useCurrentUser} from "@/context/CurrentUserProvider";
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {IconMenu} from "@/icons";
import {useAssets} from "@/context/AssetsProvider";

const version = __APP_VERSION__

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
}

export default function Header(props: HeaderProps) {
  const {onDrawerToggle} = props;
  const {signOut, currentUser} = useCurrentUser()
  const {currentOrganization} = useCurrentOrganization()
  const {showFileChooser} = useAssets()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const doLogout = React.useCallback(() => {
    // setCurrentOrganization(null)
    signOut()
  }, [])
  const [initials, name] = React.useMemo(() => {
    if( currentUser) {
      return [(currentUser.user?.firstName[0]||'')+(currentUser.user?.lastName[0]||'').toUpperCase(),
        currentUser.user?.firstName+' '+currentUser.user?.lastName]
    }
    return ['','']
  }, [currentUser])
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{display: {sm: 'none', xs: 'block'}}} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <IconMenu/>
              </IconButton>
            </Grid>
            <Grid item xs/>
            <Grid item>
              <IconButton color="inherit" sx={{p: 0.5}}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}>
                <Avatar alt="My Avatar">{initials}</Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Box sx={{position: 'absolute', p: '2px', top: 35, right: 0, opacity: 1, fontSize: 8}}>v{version}</Box>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{zIndex: 0}}
      >
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}>
        <MenuItem disabled dense>Logged in as {name}</MenuItem>
        {currentOrganization && <MenuItem
          onClick={() => {
            showFileChooser({type: 'document', onSelect: undefined})
            handleClose()
          }}>Show files</MenuItem>
        }
        <MenuItem onClick={doLogout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
}
