import {OptionsEditor} from '@/pages/prompt/components/OptionsEditor';
import {PromptInputCardProps} from '@/pages/prompt/components/types';
import {generateDebug} from '@/utils';
import {TextPromptInputConfig, TextPromptInputSubmission} from '@journey-lti-tool/common';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {produce} from 'immer'
import * as React from 'react';

const debug = generateDebug('TextInputCard')

export function TextInputCard({
                                promptInput,
                                onChange,
                                onRemove
                              }: PromptInputCardProps<TextPromptInputConfig, TextPromptInputSubmission>) {
  debug('promptInput', promptInput)

  const onAddOption = (ix: number) => {
    const value = produce(promptInput, draft => {
      debug(promptInput, draft)
      const answers = draft.config.answers || [];
      const answerWeights = draft.config.answerWeights || [];
      draft.config.answers = answers.splice(ix + 1, 0, 'New option')
      draft.config.answerWeights = answerWeights.splice(ix + 1, 0, 1)
    });
    debug('new Value', value)
    onChange(value)

  }
  const onRemoveOption = (ix: number) => {
    onChange(produce(promptInput, draft => {
      draft.config.answers?.splice(ix, 1)
      draft.config.answerWeights?.splice(ix, 1,)
    }))

  }
  const onUpdateOption = (ix: number, value: string, weight: number) => {
    onChange(produce(promptInput, draft => {
      draft.config.answers![ix] = value
      draft.config.answerWeights![ix] = weight
    }))

  }
  return (<Grid container spacing={1}>

    <Grid item sm={2}>
      <FormControl fullWidth>
        <InputLabel id={"autocorrect-label" + promptInput.id} filled={true}>Auto correct</InputLabel>
        <Select
          labelId={"autocorrect-label" + promptInput.id}
          label='Auto correct'
          size='small'
          displayEmpty
          value={promptInput.config.autoCorrectable ? '1' : '_'}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            const autoCorrectable = evt.target.value=='1';
            draft.config.autoCorrectable = autoCorrectable
            if( autoCorrectable) {
              draft.config.multiline=false
            }
          }))}>
          <MenuItem value='_'>No</MenuItem>
          <MenuItem value='1'>Yes</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item sm={2}>
      <FormControl fullWidth>
        <InputLabel id={"match-case-label-" + promptInput.id} filled={true}>Match case</InputLabel>
        <Select
          labelId={"match-case-label-" + promptInput.id}
          label='Match case'
          size='small'
          displayEmpty
          disabled={!promptInput.config.autoCorrectable}
          value={promptInput.config.matchCase ? '1' : '_'}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            draft.config.matchCase = evt.target.value=='1'
          }))}>
          <MenuItem value='_'>No</MenuItem>
          <MenuItem value='1'>Yes</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item sm={4}>
      <FormControl fullWidth>
        <InputLabel id={"multi-label" + promptInput.id} filled={true}>Multi/Single</InputLabel>
        <Select
          labelId={"multi-label" + promptInput.id}
          label='Multi/Single'
          size='small'
          displayEmpty
          value={promptInput.config.multiline ? '1' : '_'}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            draft.config.multiline = evt.target.value=='1'
          }))}>
          <MenuItem value='_'>Single line</MenuItem>
          <MenuItem value='1'>Multi line</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item sm={2}>
      <TextField
        fullWidth
        size='small'
        type={'number'}
        label='Weight'
        value={promptInput.weight}
        onChange={(evt) => onChange(produce(promptInput, draft => {
          draft.weight = Math.max(0, Number.parseFloat(evt.target.value))
        }))}/>
    </Grid>
    <Grid item sm={2}>
      <TextField
        fullWidth
        size='small'
        type={'number'}
        label='Min weight'
        value={promptInput.minContribution}
        onChange={(evt) => onChange(produce(promptInput, draft => {
          draft.minContribution = Math.min(0, Number.parseFloat(evt.target.value))
        }))}/>
    </Grid>

    <Grid item sm={12}>

      {promptInput.config.autoCorrectable && (<OptionsEditor values={promptInput.config.answers || []}
                                                        weights={promptInput.config.answerWeights || []}
                                                        onRemove={onRemoveOption}
                                                        onChange={onUpdateOption}
                                                        onAdd={onAddOption}
      />)}
    </Grid>
  </Grid>)
}
