import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLContentItemsFragment = { __typename: 'JrnyLesson', contentItems: Array<{ __typename: 'JrnyMedia', lessonId: string, id: string, name: string, mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> };

export type IGQLCurrentLessonFieldsFragment = { __typename: 'JrnyLesson', version: number, checkpointId: string, id: string, name: string, teacherNotes?: string | null, summary: string, contentItems: Array<{ __typename: 'JrnyMedia', mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, video?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string, file?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null }> };

export type IGQLLessonQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLLessonQuery = { __typename: 'Query', jrnyLesson: { __typename: 'JrnyLesson', version: number, checkpointId: string, id: string, name: string, teacherNotes?: string | null, summary: string, contentItems: Array<{ __typename: 'JrnyMedia', mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, video?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string, file?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null }> } };

export type IGQLUpdateLessonMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateLessonInput;
}>;


export type IGQLUpdateLessonMutation = { __typename: 'Mutation', jrnyUpdateLesson: { __typename: 'JrnyLesson', version: number, checkpointId: string, id: string, name: string, teacherNotes?: string | null, summary: string, contentItems: Array<{ __typename: 'JrnyMedia', mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, video?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string, file?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null }> } };

export type IGQLMoveLessonMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyMoveLessonInput;
}>;


export type IGQLMoveLessonMutation = { __typename: 'Mutation', jrnyMoveLesson: { __typename: 'JrnyMoveLessonPayload', source: { __typename: 'JrnyCheckpoint', id: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, checkpointId: string, order: number }> }, target: { __typename: 'JrnyCheckpoint', id: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, checkpointId: string, order: number }> } } };

export type IGQLCloneLessonMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCloneLessonInput;
}>;


export type IGQLCloneLessonMutation = { __typename: 'Mutation', jrnyCloneLesson: { __typename: 'JrnyCheckpoint', id: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, checkpointId: string, name: string, order: number, contentItems: Array<{ __typename: 'JrnyMedia', lessonId: string, id: string, name: string, mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> }> } };

export type IGQLCreateLessonMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateLessonInput;
}>;


export type IGQLCreateLessonMutation = { __typename: 'Mutation', jrnyCreateLesson: { __typename: 'JrnyCheckpoint', id: string, courseId: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, checkpointId: string, name: string, order: number }> } };

export type IGQLRemoveLessonMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRemoveLessonInput;
}>;


export type IGQLRemoveLessonMutation = { __typename: 'Mutation', jrnyRemoveLesson: { __typename: 'JrnyCheckpoint', id: string, lessons: Array<{ __typename: 'JrnyLesson', id: string, checkpointId: string, name: string, order: number }> } };

export type IGQLMoveContentItemMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyMoveContentItemInput;
}>;


export type IGQLMoveContentItemMutation = { __typename: 'Mutation', jrnyMoveContentItem: { __typename: 'JrnyMoveContentItemPayload', source: { __typename: 'JrnyLesson', id: string, contentItems: Array<{ __typename: 'JrnyMedia', id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> }, target: { __typename: 'JrnyLesson', id: string, contentItems: Array<{ __typename: 'JrnyMedia', lessonId: string, id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> } } };

export type IGQLCloneContentItemMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCloneContentItemInput;
}>;


export type IGQLCloneContentItemMutation = { __typename: 'Mutation', jrnyCloneContentItem: { __typename: 'JrnyLesson', id: string, contentItems: Array<{ __typename: 'JrnyMedia', id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> } };

export type IGQLCreateContentItemMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateContentItemInput;
}>;


export type IGQLCreateContentItemMutation = { __typename: 'Mutation', jrnyCreateContentItem: { __typename: 'JrnyLesson', id: string, checkpointId: string, contentItems: Array<{ __typename: 'JrnyMedia', id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> } };

export type IGQLRemoveContentItemMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRemoveContentItemInput;
}>;


export type IGQLRemoveContentItemMutation = { __typename: 'Mutation', jrnyRemoveContentItem: { __typename: 'JrnyLesson', id: string, contentItems: Array<{ __typename: 'JrnyMedia', id: string, name: string, order: number, categoryOrder: number } | { __typename: 'JrnyPrompt', id: string, name: string, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyResource', id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> } };

export const ContentItemsFragmentDoc = gql`
    fragment ContentItems on JrnyLesson {
  contentItems {
    ... on JrnyPrompt {
      __typename
      lessonId
      id
      name
      order
      categoryOrder
      category
      useAIAssessment
    }
    ... on JrnyMedia {
      __typename
      lessonId
      id
      name
      mediaType
      iframe
      order
      categoryOrder
      category
    }
    ... on JrnyResource {
      __typename
      lessonId
      id
      name
      order
      type
      categoryOrder
      category
    }
  }
}
    `;
export const CurrentLessonFieldsFragmentDoc = gql`
    fragment CurrentLessonFields on JrnyLesson {
  __typename
  version
  checkpointId
  id
  name
  teacherNotes
  summary
  ...ContentItems
  contentItems {
    ... on JrnyMedia {
      mediaType
      iframe
      video {
        ...FileObjectFields
      }
      image {
        ...FileObjectFields
      }
    }
    ... on JrnyResource {
      file {
        ...FileObjectFields
      }
    }
  }
}
    ${ContentItemsFragmentDoc}
${FileObjectFieldsFragmentDoc}`;
export const LessonDocument = gql`
    query lesson($id: ID!) {
  jrnyLesson(id: $id) {
    ...CurrentLessonFields
  }
}
    ${CurrentLessonFieldsFragmentDoc}`;

export function useLessonQuery(options: Omit<Urql.UseQueryArgs<IGQLLessonQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLLessonQuery, IGQLLessonQueryVariables>({ query: LessonDocument, ...options });
};
export const UpdateLessonDocument = gql`
    mutation updateLesson($input: JrnyUpdateLessonInput!) {
  jrnyUpdateLesson(input: $input) {
    ...CurrentLessonFields
  }
}
    ${CurrentLessonFieldsFragmentDoc}`;

export function useUpdateLessonMutation() {
  return Urql.useMutation<IGQLUpdateLessonMutation, IGQLUpdateLessonMutationVariables>(UpdateLessonDocument);
};
export const MoveLessonDocument = gql`
    mutation moveLesson($input: JrnyMoveLessonInput!) {
  jrnyMoveLesson(input: $input) {
    source {
      __typename
      id
      lessons {
        __typename
        id
        checkpointId
        order
      }
    }
    target {
      __typename
      id
      lessons {
        __typename
        id
        checkpointId
        order
      }
    }
  }
}
    `;

export function useMoveLessonMutation() {
  return Urql.useMutation<IGQLMoveLessonMutation, IGQLMoveLessonMutationVariables>(MoveLessonDocument);
};
export const CloneLessonDocument = gql`
    mutation cloneLesson($input: JrnyCloneLessonInput!) {
  jrnyCloneLesson(input: $input) {
    __typename
    id
    lessons {
      __typename
      id
      checkpointId
      name
      order
      ...ContentItems
    }
  }
}
    ${ContentItemsFragmentDoc}`;

export function useCloneLessonMutation() {
  return Urql.useMutation<IGQLCloneLessonMutation, IGQLCloneLessonMutationVariables>(CloneLessonDocument);
};
export const CreateLessonDocument = gql`
    mutation createLesson($input: JrnyCreateLessonInput!) {
  jrnyCreateLesson(input: $input) {
    __typename
    id
    courseId
    lessons {
      __typename
      id
      checkpointId
      name
      order
    }
  }
}
    `;

export function useCreateLessonMutation() {
  return Urql.useMutation<IGQLCreateLessonMutation, IGQLCreateLessonMutationVariables>(CreateLessonDocument);
};
export const RemoveLessonDocument = gql`
    mutation removeLesson($input: JrnyRemoveLessonInput!) {
  jrnyRemoveLesson(input: $input) {
    __typename
    id
    lessons {
      __typename
      id
      checkpointId
      name
      order
    }
  }
}
    `;

export function useRemoveLessonMutation() {
  return Urql.useMutation<IGQLRemoveLessonMutation, IGQLRemoveLessonMutationVariables>(RemoveLessonDocument);
};
export const MoveContentItemDocument = gql`
    mutation moveContentItem($input: JrnyMoveContentItemInput!) {
  jrnyMoveContentItem(input: $input) {
    source {
      __typename
      id
      contentItems {
        ... on JrnyPrompt {
          __typename
          id
          name
          order
          categoryOrder
          category
        }
        ... on JrnyMedia {
          __typename
          id
          name
          order
          categoryOrder
        }
        ... on JrnyResource {
          __typename
          id
          name
          order
          type
          categoryOrder
          category
        }
      }
    }
    target {
      __typename
      id
      contentItems {
        ... on JrnyPrompt {
          __typename
          lessonId
          id
          name
          order
          categoryOrder
          category
        }
        ... on JrnyMedia {
          __typename
          lessonId
          id
          name
          order
          categoryOrder
        }
        ... on JrnyResource {
          __typename
          lessonId
          id
          name
          order
          type
          categoryOrder
          category
        }
      }
    }
  }
}
    `;

export function useMoveContentItemMutation() {
  return Urql.useMutation<IGQLMoveContentItemMutation, IGQLMoveContentItemMutationVariables>(MoveContentItemDocument);
};
export const CloneContentItemDocument = gql`
    mutation cloneContentItem($input: JrnyCloneContentItemInput!) {
  jrnyCloneContentItem(input: $input) {
    __typename
    id
    contentItems {
      ... on JrnyPrompt {
        __typename
        id
        name
        order
        categoryOrder
        category
      }
      ... on JrnyMedia {
        __typename
        id
        name
        order
        categoryOrder
      }
      ... on JrnyResource {
        __typename
        id
        name
        order
        type
        categoryOrder
        category
      }
    }
  }
}
    `;

export function useCloneContentItemMutation() {
  return Urql.useMutation<IGQLCloneContentItemMutation, IGQLCloneContentItemMutationVariables>(CloneContentItemDocument);
};
export const CreateContentItemDocument = gql`
    mutation createContentItem($input: JrnyCreateContentItemInput!) {
  jrnyCreateContentItem(input: $input) {
    __typename
    id
    checkpointId
    contentItems {
      ... on JrnyPrompt {
        __typename
        id
        name
        order
        categoryOrder
        category
      }
      ... on JrnyMedia {
        __typename
        id
        name
        order
        categoryOrder
      }
      ... on JrnyResource {
        __typename
        id
        name
        order
        type
        categoryOrder
        category
      }
    }
  }
}
    `;

export function useCreateContentItemMutation() {
  return Urql.useMutation<IGQLCreateContentItemMutation, IGQLCreateContentItemMutationVariables>(CreateContentItemDocument);
};
export const RemoveContentItemDocument = gql`
    mutation removeContentItem($input: JrnyRemoveContentItemInput!) {
  jrnyRemoveContentItem(input: $input) {
    __typename
    id
    contentItems {
      ... on JrnyPrompt {
        __typename
        id
        name
        order
        categoryOrder
        category
      }
      ... on JrnyMedia {
        __typename
        id
        name
        order
        categoryOrder
      }
      ... on JrnyResource {
        __typename
        id
        name
        order
        type
        categoryOrder
        category
      }
    }
  }
}
    `;

export function useRemoveContentItemMutation() {
  return Urql.useMutation<IGQLRemoveContentItemMutation, IGQLRemoveContentItemMutationVariables>(RemoveContentItemDocument);
};