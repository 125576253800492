import {useProseMirrorDevTools} from '@/context/ProsemirrorDevToolsProvider';
import {PromptInputNode} from '@/pages/prompt/extensions/prompt-input.extension';
import {Box, Button, ButtonGroup, FormControl, InputLabel, OutlinedInput, Stack, styled} from '@mui/material';
import {Editor, EditorContent, useEditor, BubbleMenu} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import {Link, LinkOptions, LinkProtocolOptions} from '@tiptap/extension-link';

import * as React from 'react';
import {
  IconBold,
  IconBreak,
  IconBulletList,
  IconDev,
  IconHorizontalRule,
  IconItalic,
  IconNumberList,
  IconStrike,
} from '@/icons'
import {BubbleMenuPluginProps} from "@tiptap/extension-bubble-menu/src/bubble-menu-plugin";

interface MenuBarProps {
  editor: Editor | null
}

export const MenuBar = ({editor}: MenuBarProps) => {
  const {toggle, current} = useProseMirrorDevTools()
  if (!editor) {
    return null
  }
  const isCurrent = React.useMemo(() => editor.view === current, [current, editor])
  return (
    <Stack spacing={1} alignItems='flex-start'>
      <ButtonGroup variant="outlined" size={'small'}>
        <Button
          size='small'
          onClick={() => toggle(editor.view)}
          variant={isCurrent ? 'contained' : undefined}

        >
          <IconDev/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          variant={editor.isActive('bold') ? 'contained' : undefined}
        >
          <IconBold/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          variant={editor.isActive('italic') ? 'contained' : undefined}
        >
          <IconItalic/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          variant={editor.isActive('strike') ? 'contained' : undefined}
        >
          <IconStrike/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <IconBulletList/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleOrderedList().run()}

          variant={editor.isActive('orderedList') ? 'contained' : undefined}

        >
          <IconNumberList/>
        </Button>
        <Button
          size='small' onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <IconHorizontalRule/>
        </Button>
        <Button
          size='small' onClick={() => editor.chain().focus().setHardBreak().run()}>
          <IconBreak/>
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
const EditorWrapper = styled(FormControl, {
  name: 'MuiTextField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({theme}) => //language=scss prefix=.root{ suffix=}
  `
    & .ProseMirror span[data-prompt-ix] {
      min-width: 5em;
      background: ${theme.palette.secondary.light};
      border: #f00;
      height: 1.2em;
      line-height: 1.2em;
      border-radius: 1.2em;
      display: inline-block;
      margin: 0 .1em -0.1em;
      text-align: center;
      font-size: .9em;

      &:after {
        content: attr(data-prompt-ix);
      }
    }
  `)

export interface PromptDescriptionEditorProps {
  onChange: (content: string) => void
  content: string
  label: string
}

export function DescriptionEditor(props: PromptDescriptionEditorProps) {

  const editor = useEditor({
    extensions: [
      StarterKit,
      PromptInputNode,
      Link.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
        HTMLAttributes: {
          target: '_blank',
        }
      }),
    ],
    onUpdate({editor}) {
      props.onChange(editor.getHTML())
    },
    content: props.content,
  }, [props.onChange])
  const content = React.useMemo(() => React.forwardRef((pr, ref) => (<Box sx={{p: 2}}>
    <MenuBar editor={editor}/>
    <EditorContent editor={editor}/>
  </Box>)), [editor])
  return editor ? (<>
        <EditorWrapper variant='outlined' fullWidth>
          <InputLabel htmlFor='text-editor' id='text-editor-label' shrink>
            {props.label}
          </InputLabel>
          {/* TODO: Create a proper outlined version of this*/}
          {/* @ts-ignore */}
          <OutlinedInput notched={true} label={props.label} id='text-editor' inputComponent={content}/>
        </EditorWrapper>

      </>
    )
    : null
}
