import { useAssets } from "@/context/AssetsProvider";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useLocalChange } from "@/hooks/use-local-change";
import { IconPhoto } from "@/icons";
import { generateDebug } from "@/utils";
import { DescriptionEditor } from "@components/DescriptionEditor";
import AspectRatio from "@components/file-object/AspectRatio";
import { MinimalFileObject } from "@components/file-object/types";
import { usePerkQuery, useUpdatePerkMutation } from "@graphql/perks-hook";
import {
  Box,
  Tabs,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { set } from "lodash";
import { Purchase } from "./types";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface PerkDetailsViewProps {}
const debug = generateDebug("PerkDetailsView");
export function PerkDetailsView(props: PerkDetailsViewProps) {
  const [value, setValue] = React.useState(0);
  const { perkId } = useParams();
  const { showFileChooser } = useAssets();
  const { currentOrganization } = useCurrentOrganization();
  const [perkQuery] = usePerkQuery({
    variables: { id: perkId || "" },
    pause: !perkId,
    requestPolicy: "cache-and-network",
  });
  const [_, updatePerkMutation] = useUpdatePerkMutation();
  const purchases = perkQuery.data?.jrnyPerk.purchases || [];
  const courseNames: Record<string, string> = React.useMemo(() => {
    return (
      currentOrganization?.courses?.reduce(
        (acc, c) => {
          acc[c.id] = c.name;
          return acc;
        },
        {} as Record<string, string>
      ) || {}
    );
  }, [currentOrganization?.courses]);
  const [localPerk, setLocalPerk] = useLocalChange(
    perkQuery.data?.jrnyPerk,
    (original, local) => {
      return updatePerkMutation({
        input: {
          id: local.id,
          description: local.description || null,
          price: local.price,
          title: local.title || null,
          providerEmail: local.providerEmail || null,
          managerEmail: local.managerEmail || null,
          visible: local.visible,
          imageId: local.image?.id || "DELETE",
          perkCourses: local.perkCourses,
          inStockCount:
            original.inStockCount == local.inStockCount
              ? undefined
              : local.inStockCount,
        },
      });
    }
  );
  const availableCourses: string[] = React.useMemo(
    () => currentOrganization?.courses.map((c) => c.id) || [],
    [currentOrganization?.courses]
  );

  const onSetImage = (fileObject: MinimalFileObject | null) => {
    setLocalPerk((draft) => {
      if (draft) {
        if (fileObject) {
          // @ts-ignore
          draft.image = fileObject;
        } else {
          // @ts-ignore
          draft.image = undefined;
        }
      }
    });
  };

  const setDescription = React.useCallback(
    (description: string) =>
      setLocalPerk((draft) => {
        draft!.description = description;
      }),
    []
  );
  const canBeVisible = React.useMemo(() => {
    return (
      !!localPerk?.managerEmail &&
      !!localPerk?.providerEmail &&
      localPerk?.title &&
      !!localPerk?.image
    );
  }, [localPerk]);
  return localPerk ? (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={3} />
          <Grid item xs={6} alignContent={"center"}>
            <Stack>
              <AspectRatio ratio="1.7" objectFit="contain">
                {localPerk.image ? (
                  <img src={localPerk.image.url} />
                ) : (
                  <Box sx={{ maxWidth: "100%", bgcolor: "#aaa" }}>
                    <IconPhoto sx={{ fontSize: "10rem", fill: "#444" }} />
                  </Box>
                )}
              </AspectRatio>
              <Button
                onClick={() =>
                  showFileChooser({ type: "image", onSelect: onSetImage })
                }
              >
                Choose image
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={3} />
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs value={value} onChange={(_: any, val: number) => setValue(val)}>
            <Tab label="Settings" {...a11yProps(0)} />

            <Tab
              label="Purchases"
              {...a11yProps(1)}
              disabled={!purchases.length}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                label="Title"
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                value={localPerk.title}
                onChange={(evt) =>
                  setLocalPerk((draft) => {
                    draft!.title = evt.target.value;
                  })
                }
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                size="small"
                type={"number"}
                label="In Stock Count"
                value={localPerk.inStockCount}
                onChange={(evt) =>
                  setLocalPerk((draft) => {
                    draft!.inStockCount = Math.max(
                      0,
                      Number.parseFloat(evt.target.value)
                    );
                  })
                }
              />
            </Grid>
            <Grid item sm={2}>
              <FormControl fullWidth>
                <InputLabel id="visible-label">Visible</InputLabel>
                <Select
                  labelId="visible-label"
                  label="Visible"
                  size="small"
                  displayEmpty
                  value={localPerk.visible && canBeVisible ? "1" : "_"}
                  onChange={(evt) =>
                    setLocalPerk((draft) => {
                      draft!.visible = evt.target.value == "1";
                    })
                  }
                >
                  <MenuItem value="_">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <TextField
                size="small"
                type={"number"}
                label="Price (points)"
                value={localPerk.price}
                onChange={(evt) =>
                  setLocalPerk((draft) => {
                    draft!.price = Math.max(
                      0,
                      Number.parseFloat(evt.target.value)
                    );
                  })
                }
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Provider email"
                fullWidth
                InputLabelProps={{ shrink: true }}
                size="small"
                type="email"
                value={localPerk.providerEmail}
                onChange={(evt) =>
                  setLocalPerk((draft) => {
                    draft!.providerEmail = evt.target.value;
                  })
                }
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Manager email"
                fullWidth
                InputLabelProps={{ shrink: true }}
                size="small"
                type="email"
                value={localPerk.managerEmail}
                onChange={(evt) =>
                  setLocalPerk((draft) => {
                    draft!.managerEmail = evt.target.value;
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={availableCourses}
                value={localPerk.perkCourses}
                freeSolo
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: string[]
                ) => {
                  setLocalPerk((draft) => {
                    debug("Setting perkCourses", value);
                    draft!.perkCourses = value;
                  });
                }}
                getOptionLabel={(option: string) => courseNames[option]}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        label={courseNames[option]}
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Courses" placeholder=" " />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <DescriptionEditor
                content={localPerk.description}
                onChange={setDescription}
                label={"Description"}
              />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <List>
            {purchases.map((perk: Purchase) => (
              <ListItem
                secondaryAction={
                  <>
                    <Chip label={courseNames[perk.courseId]} size="small" />
                    {perk.buyer.isTeam && (
                      <Chip
                        sx={{ mr: 1 }}
                        size="small"
                        label="TEAM"
                        color="primary"
                      />
                    )}
                  </>
                }
                sx={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
                key={perk.id}
              >
                <ListItemText
                  secondary={perk.price + " points"}
                  primary={
                    perk.buyer.name + (perk.buyer.email &&
                    perk.buyer.email != perk.buyer.name
                      ? ` (${perk.buyer.email})`
                      : "")
                  }
                />
              </ListItem>
            ))}
          </List>
        </CustomTabPanel>
      </Paper>
      {/*<DebugView>{JSON.stringify(localPerk, null, 2)}</DebugView>*/}
    </>
  ) : null;
}
