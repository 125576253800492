import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
import isEmpty from 'validator/lib/isEmpty'

export function email(value: string) {
  return value && !isEmail(value.trim()) ? 'Invalid email' : null;
}

export function verificationCode(value: string) {
  return value && !isLength(value, {min: 6, max: 6}) ? 'Invalid code' : null
}

export function password(value: string) {
  return value && !isLength(value, {min: 8, max: 32}) ? 'Minimum 8, maximum 32 characters' : null
}

function isDirty(value: string | number) {
  return value || value === 0;
}
export function verifyPassword(value: string, orgValue: string) {
  return value && value != orgValue?'Passwords don\'t match': null
}
export function required(
  requiredFields: readonly string[],
  values: Record<string, string>,
): Record<string, string> {
  return requiredFields.reduce(
    (fields, field) => ({
      ...fields,
      ...(isDirty(values[field]) ? undefined : {[field]: 'Required'}),
    }),
    {},
  );
}
