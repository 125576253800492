import Box from '@mui/material/Box';
import * as React from 'react'
import {FileIcon, FileIconProps, defaultStyles, DefaultExtensionType} from 'react-file-icon'
import mime from 'mime-types'

interface MimeTypeIconProps extends FileIconProps {
  contentType: string
  width?: number | string
  height?: number | string
  auto?: boolean
}

export function MimeTypeIcon({contentType, width, auto, height, ...rest}: MimeTypeIconProps) {
  const ext = (mime.extension(contentType) || contentType.split('/')[0]) as DefaultExtensionType
  const defaults = defaultStyles[ext]
  return (<Box sx={
    auto
      ? {height: '100%', width: '100%', position: 'absolute'} :
      {height: height || 32, width: width || 32}}>
    <FileIcon {...rest}
              {...defaults}
              radius={2}
              extension={ext}/></Box>)
}
