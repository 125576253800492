import * as React from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {IconAdd} from "@/icons";
import {generateDebug} from "@/utils";
import Papa from 'papaparse';
import isEmail from "validator/lib/isEmail";
import {useSnackbar} from "notistack";
import {IGQLCreateMultipleParticipationsMutationVariables} from "@graphql/participations-hook";

const debug = generateDebug('UploadCourseUserCsv');

interface UploadCourseUserCsvProps {
  addUsers: (variables: IGQLCreateMultipleParticipationsMutationVariables) => Promise<any>;
  courseId: string
}

type ResultObject = {
  data: string[],
  errors: {
    type: string,     // A generalization of the error
    code: string,     // Standardized error code
    message: string,  // Human-readable details
    row: number,
  }[]
  meta: any// object with extra info
}
const parseCSV = (file: File): Promise<ResultObject['data']> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: (results: ResultObject) => {
        if (results.errors.length > 0) {
          reject(results.errors);
        }
        resolve(results.data);
      },
      error: (err: Error) => {
        reject(err);
      },
    });
  });
}

function UploadCourseUserCsv(props: UploadCourseUserCsvProps, ref: React.Ref<any> ) {
  const {enqueueSnackbar} = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [validEntries, setValidEntries] = React.useState<{ email: string, role: string, name?: string }[]>([]);
  const [invalidEntries, setInvalidEntries] = React.useState<{ row: number, message: string }[]>([]);


  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async () => {
    setSubmitting(true);
    const input: IGQLCreateMultipleParticipationsMutationVariables['input'] = {
      courseId: props.courseId,
      entries: validEntries.map(e => ({
        email: e.email,
        role: e.role,
        name: e.name || null,
      }))
    }
    try {
      await props.addUsers({input});
      setValidEntries([]);
      setInvalidEntries([]);
      setOpen(false);
    } catch (e) {
      enqueueSnackbar((e as Error).message, {variant: 'error'});
    } finally {
      setSubmitting(false);

    }
  }
  const parseCsv = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(false);
    setInvalidEntries([])
    setValidEntries([])
    const fileList: File[] = Array.from(evt.target.files || []);
    if (fileList.length == 0) {
      return;
    }
    try {
      const result = await parseCSV(fileList[0])
      const validRows: { email: string, role: string, name?: string }[] = []
      const invalidRows: { row: number, message: string }[] = []
      result.forEach((row, ix) => {
        const [role, email, name] = row;
        if (role != 'teacher' && role != 'student') {
          invalidRows.push({row: ix + 1, message: `Invalid role: "${role}"`});
          return;
        }
        if (!isEmail(email)) {
          invalidRows.push({row: ix + 1, message: `Invalid email: ${email}`});
          return false;
        }
        validRows.push({email, role, name});
      })
      setOpen(true)
      setValidEntries(validRows)
      setInvalidEntries(invalidRows)
      debug(validRows, invalidRows);
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar((e as Error).message, {variant: 'error'})
      } else if (Array.isArray(e)) {
        e.forEach(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
        })
      }
    }
  }

  return (<>
    <Dialog open={open}
            fullWidth={true}
            scroll={'paper'}
            onClose={submitting ? () => {} : handleClose}
    >
      <DialogTitle>Add users to course</DialogTitle>
      <DialogContent dividers={true} >
        <DialogContentText>
          {validEntries.length && <div>
            <Typography variant={'caption'}>{validEntries.length} users that will be added</Typography><List>
            {validEntries.map((entry, ix) => (<ListItem
              divider={true}
              secondaryAction={<Chip label={entry.role} sx={{bgcolor:entry.role=='teacher'?'orange':'lightgray'}}/>}
              key={ix}>
              <ListItemText primary={entry.email} secondary={entry.name}/>
            </ListItem>))}
          </List></div>
          }
          {invalidEntries.length && <div>
            <Typography variant={'caption'}>These rows will be ignored:</Typography><List>
            {invalidEntries.map(entry => (<ListItem
              divider={true}
              key={entry.row}>
              <ListItemText primary={<span style={{fontFamily:'monospace', whiteSpace: 'pre'}}>Row {entry.row.toString().padStart(2)}: <span style={{color: 'red'}}>{entry.message}</span></span>}/>
            </ListItem>))}
          </List></div>
          }

        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
        <Button disabled={submitting || validEntries.length<1} onClick={handleUpload}>Add</Button>
      </DialogActions>
    </Dialog>
    <form encType="multipart/form-data"
          noValidate style={{display: 'none'}}>
      <input ref={ref} type="file"
             onChange={parseCsv}
             accept="text/csv"/>
    </form>

  </>)

}
export default React.forwardRef(UploadCourseUserCsv);
