import {createSvgIcon, SvgIcon, SvgIconProps, useTheme} from '@mui/material';
import IconAdd from '@mui/icons-material/Add';
import IconGoTo from '@mui/icons-material/ChevronRight';
import IconBack from '@mui/icons-material/ChevronLeft';
import IconDelete from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';
import IconInbox from '@mui/icons-material/Inbox';
import IconRefresh from '@mui/icons-material/CachedOutlined';
import IconStructure from '@mui/icons-material/ViewList';
import IconCourse from '@mui/icons-material/Map';
import IconCheckpoint from '@mui/icons-material/Place';
import IconLesson from '@mui/icons-material/ListAlt';
import IconChallenge from '@mui/icons-material/Assignment';
import IconResource from '@mui/icons-material/InsertDriveFile';
import IconPrompt from '@mui/icons-material/Quiz';
import IconSpeedDial from '@mui/material/SpeedDialIcon';
import IconSave from '@mui/icons-material/Done';
import IconCancel from '@mui/icons-material/Close';
import IconContextMenu from '@mui/icons-material/MoreVert';
import IconVideo from '@mui/icons-material/VideoCameraBack';
import IconPhoto from '@mui/icons-material/PhotoRounded';
import IconClose from '@mui/icons-material/Close';
import IconInfo from '@mui/icons-material/Info';
import IconMenu from '@mui/icons-material/Menu';
import IconUpload from '@mui/icons-material/FileUpload';
import IconChoice from '@mui/icons-material/FormatListNumbered';
import IconText from '@mui/icons-material/TextFields';
import IconGrading from '@mui/icons-material/Grade';
import IconDuplicate from '@mui/icons-material/ControlPointDuplicateRounded';
import IconBold from '@mui/icons-material/FormatBoldRounded';
import IconItalic from '@mui/icons-material/FormatItalicRounded';
import IconStrike from '@mui/icons-material/FormatStrikethroughRounded';
import IconBulletList from '@mui/icons-material/FormatListBulletedRounded';
import IconNumberList from '@mui/icons-material/FormatListNumberedRounded';
import IconBreak from '@mui/icons-material/KeyboardReturnRounded';
import IconHorizontalRule from '@mui/icons-material/HorizontalRuleRounded';
import IconDev from '@mui/icons-material/BugReportRounded';
import IconList from '@mui/icons-material/List';
import IconGrid from '@mui/icons-material/Apps';
import IconImage from '@mui/icons-material/Image';
import IconDuration from '@mui/icons-material/TimerOutlined';
import IconSize from '@mui/icons-material/BackupOutlined';
import IconFolder from '@mui/icons-material/Folder';
import IconPerks from '@mui/icons-material/Storefront';
import IconUsers from '@mui/icons-material/Group';
import IconOrganization from '@mui/icons-material/Business';
import IconFilter from '@mui/icons-material/FilterAlt';
import IconCreateFolder from '@mui/icons-material/CreateNewFolder';
import IconUploadFile from '@mui/icons-material/UploadFile';
import IconImageSize from '@mui/icons-material/OpenInFull';
import IconFolderCount from '@mui/icons-material/FolderOutlined';
import IconFileCount from '@mui/icons-material/InsertDriveFileOutlined';
import IconSortByDate from '@mui/icons-material/CalendarMonth';
import IconSortByName from '@mui/icons-material/TextFields';
import IconSort from '@mui/icons-material/Sort';
import IconStudent from '@mui/icons-material/School';
import IconImportCSV from '@mui/icons-material/Upload';
import IconDownloadCSV from '@mui/icons-material/Download';
import IconSettings from '@mui/icons-material/SettingsOutlined';
import {SortAscending, SortDescending} from 'mdi-material-ui';
export {
  IconAdd,
  IconInbox,
  IconGoTo,
  IconEdit,
  IconDelete,
  IconRefresh,
  IconStructure,
  IconCourse,
  IconCheckpoint,
  IconLesson,
  IconChallenge,
  IconResource,
  IconPrompt,
  IconVideo,
  IconSpeedDial,
  IconSave,
  IconCancel,
  IconContextMenu,
  IconPhoto,
  IconClose,
  IconInfo,
  IconMenu,
  IconUpload,
  IconChoice,
  IconText,
  IconGrading,
  IconDuplicate,
  IconBold,
  IconItalic,
  IconStrike,
  IconBulletList,
  IconNumberList,
  IconBreak,
  IconHorizontalRule,
  IconDev,
  IconBack,
  IconList,
  IconGrid,
  IconResource as IconDocument,
  IconImage,
  IconDuration,
  IconSize,
  IconFolder,
  IconPerks,
  IconUsers,
  IconOrganization,
  IconFilter,
  IconUploadFile,
  IconCreateFolder,
  IconImageSize,
  IconFolderCount,
  IconFileCount,
  IconSortByDate,
  IconSortByName,
  IconSort,
  IconStudent,
  IconImportCSV,
  IconDownloadCSV,
  IconSettings,
  SortAscending as IconSortAscending,
  SortDescending as IconSortDescending,
}

export const IconGridFolder = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512.004 512.004" xmlSpace="preserve">
      <polygon filter='grayscale(20%) brightness(85%)'
               points="0,48.018 179.944,48.018 224.112,92.178 512,92.178 512,463.986 0.04,463.986 "/>
      <rect x="0.04" y="137.88" filter='grayscale(30%) brightness(110%)' width="511.964" height="326.102"/>
      <rect x="31.32" y="113.512" fill={'#ffffff'} width="460.226" height="24.368"/>
    </SvgIcon>)
}
export const IconTeacher = (props: SvgIconProps) => {
  const theme = useTheme()
  const {color, } = props
  return (<SvgIcon viewBox="0 0 100 100" xmlSpace="preserve">
    <path
      d="M13.5 31.6C13.5 25.6906 18.2906 20.9 24.2 20.9C30.1094 20.9 34.9 25.6906 34.9 31.6C34.9 37.5094 30.1094 42.3 24.2 42.3C18.2906 42.3 13.5 37.5094 13.5 31.6Z"
      fill="currentColor" fill-rule="nonzero" opacity="1" stroke="none"/>
    <path
      d="M93.5 20.1L48.6 20.1C46.4 20.1 44.6 21.9 44.6 24.1L44.6 54.8C44.6 54.8 44.6 54.8 44.6 54.9C45.3 55.1 46.6 55.4 48.6 55.4L48.6 24.1L93.5 24.1L93.5 59.5L58.9 59.5C59.1 60.1 59.3 60.6 59.4 61.2C59.5 62 59.5 62.7 59.3 63.5L93.5 63.5C95.7 63.5 97.5 61.7 97.5 59.5L97.5 24.1C97.5 21.9 95.7 20.1 93.5 20.1Z"
      fill="currentColor" fill-rule="nonzero" opacity="1" stroke="none"/>
    <path
      d="M54.8 58.9L61.1 43C61.5 42 61 40.8 60 40.4C59 40 57.8 40.5 57.4 41.5L50.7 58.2C48.6 58.4 45 58.4 43.1 57.4C41.8 56.8 40.4 53.4 39.8 51.9C39.5 51.2 39.2 50.5 39 50.1C38 48.1 35.8 45.7 31.3 45.7L17.7 45.7C14.2 45.7 11.5 47.5 9.6 50.9L9.2 51.7C7.4 55 5.6 58.5 4.9 61.1C4.2 64 2.6 71.7 2.6 72C2.2 74.2 3.6 76.4 5.8 76.8C6.1 76.9 6.4 77 6.6 77C8.5 77 10.2 75.6 10.6 73.7C10.6 73.6 12.1 65.9 12.8 63.4C13.1 62.4 13.7 60.9 14.5 59.4L14 77.2C14 78.7 15.1 79.9 16.6 79.9L31.8 79.9C33.3 79.9 34.4 78.7 34.4 77.2L33.8 58.8C35.1 61.2 36.9 63.7 39.6 65C44.7 67.4 52 66.5 52.8 66.4C55.1 66.1 56.6 64 56.3 61.8C56.3 60.5 55.7 59.6 54.8 58.9Z"
      fill="currentColor" fill-rule="nonzero" opacity="1" stroke="none"/>
    <path
      d="M86 30.1L56.1 30.1C55 30.1 54.1 31 54.1 32.1C54.1 33.2 55 34.1 56.1 34.1L86 34.1C87.1 34.1 88 33.2 88 32.1C88 31 87.1 30.1 86 30.1Z"
      fill="#000000" fill-rule="nonzero" opacity="1" stroke="none"/>
    <path
      d="M86 39.8L67.7 39.8C66.6 39.8 65.7 40.7 65.7 41.8C65.7 42.9 66.6 43.8 67.7 43.8L86 43.8C87.1 43.8 88 42.9 88 41.8C88 40.7 87.1 39.8 86 39.8Z"
      fill="#000000" fill-rule="nonzero" opacity="1" stroke="none"/>
    <path
      d="M86 49.5L67.7 49.5C66.6 49.5 65.7 50.4 65.7 51.5C65.7 52.6 66.6 53.5 67.7 53.5L86 53.5C87.1 53.5 88 52.6 88 51.5C88 50.4 87.1 49.5 86 49.5Z"
      fill="#000000" fill-rule="nonzero" opacity="1" stroke="none"/>

  </SvgIcon>)
}
export const Logo = () => (<svg width="123" height="19" viewBox="0 0 123 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5282_6383)">
      <path
        d="M96.4825 14.3577C95.2535 13.8234 94.2678 12.8614 93.7139 11.6559H93.7468C93.4398 10.9976 93.2809 10.2817 93.2809 9.55721C93.2809 8.83274 93.4398 8.11683 93.7468 7.45849H93.7196C94.1371 6.52149 94.8229 5.72475 95.693 5.16586C96.5632 4.60696 97.58 4.31017 98.6188 4.31183H100.883C101.984 4.26947 103.025 3.8086 103.789 3.02598C104.552 2.24335 104.978 1.19982 104.978 0.114401H98.613C97.8894 0.112883 97.1679 0.192005 96.4624 0.35026C94.0804 0.89858 91.9969 2.3137 90.6298 4.31183C89.6647 5.7349 89.1104 7.38957 89.0261 9.09895C88.9418 10.8083 89.3306 12.5081 90.1511 14.0167C90.9716 15.5252 92.1929 16.7857 93.6843 17.6635C95.1758 18.5412 96.8814 19.0032 98.6188 19H100.722C101.851 18.9967 102.933 18.5534 103.731 17.7669C104.53 16.9805 104.98 15.9148 104.983 14.8026H98.613C97.8786 14.8073 97.1518 14.6556 96.4825 14.3577Z"
        fill="white"/>
      <path
        d="M93.7468 11.6559H96.9886C98.1176 11.6526 99.1995 11.2093 99.9979 10.4228C100.796 9.63638 101.246 8.57069 101.25 7.4585H93.7411C93.4341 8.11683 93.2751 8.83274 93.2751 9.55721C93.2751 10.2817 93.4341 10.9976 93.7411 11.6559H93.7468Z"
        fill="white"/>
      <path
        d="M70.3335 19C71.4622 18.9966 72.5437 18.5535 73.3418 17.7673C74.1399 16.9811 74.5898 15.9158 74.5932 14.804V8.8609L70.3335 4.46716V19Z"
        fill="white"/>
      <path
        d="M86.3128 0.11438C85.1837 0.117733 84.1018 0.561037 83.3034 1.34748C82.5051 2.13393 82.055 3.19961 82.0516 4.31181V10.3354L86.3128 14.6571V0.11438Z"
        fill="white"/>
      <path
        d="M82.0488 10.3354V10.3707L73.581 1.5832C73.1796 1.12175 72.6814 0.751484 72.1208 0.497915C71.5602 0.244346 70.9504 0.113498 70.3335 0.114384V4.46717L74.5932 8.86091V8.74369L83.0152 17.4521C83.412 17.9373 83.9143 18.3288 84.485 18.5976C85.0556 18.8665 85.6802 19.0059 86.3128 19.0056V14.6571L82.0488 10.3354Z"
        fill="white"/>
      <path
        d="M16.2761 11.2873C16.0233 10.6247 15.9093 9.91849 15.9409 9.2113C15.9724 8.5041 16.149 7.81052 16.4598 7.17229C16.7706 6.53406 17.2094 5.96439 17.7496 5.49757C18.2898 5.03075 18.9203 4.67644 19.6032 4.45597C20.2861 4.2355 21.0072 4.15342 21.7231 4.21469C22.4391 4.27595 23.135 4.47928 23.7691 4.81245C24.4031 5.14561 24.9622 5.60171 25.4126 6.1533C25.863 6.70489 26.1954 7.34055 26.3899 8.02202C26.1079 6.16157 25.2688 4.4263 23.98 3.03825C22.6912 1.6502 21.0112 0.672492 19.1551 0.230225C17.9195 0.503622 16.751 1.01545 15.7173 1.73616C14.6836 2.45686 13.8051 3.37216 13.1325 4.42918C12.46 5.48621 12.0066 6.66402 11.7988 7.89459C11.5909 9.12516 11.6325 10.3841 11.9214 11.5987C12.2102 12.8133 12.7405 13.9596 13.4815 14.9712C14.2226 15.9828 15.1598 16.8398 16.2389 17.4926C17.3181 18.1454 18.5179 18.5812 19.769 18.7747C21.0201 18.9682 22.2977 18.9157 23.5281 18.6201C21.7327 18.2117 20.0943 17.3033 18.8085 16.0031C17.5227 14.703 16.6436 13.066 16.2761 11.2873Z"
        fill="white"/>
      <path
        d="M21.2656 3.79442e-06C20.5556 -0.000623737 19.8478 0.0765878 19.1551 0.230213C21.0112 0.67248 22.6912 1.65019 23.98 3.03824C25.2688 4.42629 26.1079 6.16156 26.3899 8.02201C26.7615 9.32429 26.6082 10.7176 25.9618 11.9112C25.3155 13.1047 24.2261 14.0063 22.9211 14.4276C21.6162 14.8489 20.1964 14.7575 18.9582 14.1723C17.7201 13.5872 16.7592 12.5536 16.2761 11.2873C16.6436 13.066 17.5227 14.703 18.8085 16.0031C20.0944 17.3033 21.7327 18.2117 23.5281 18.6201C25.8082 18.0759 27.8065 16.7271 29.1447 14.8289C30.4828 12.9308 31.0679 10.6152 30.7891 8.3206C30.5103 6.02604 29.387 3.91184 27.6318 2.3784C25.8767 0.84496 23.6117 -0.00125766 21.2656 3.79442e-06Z"
        fill="white"/>
      <path
        d="M66.7002 5.88514C66.6949 4.35624 66.076 2.89145 64.9785 1.81035C63.881 0.729254 62.3939 0.119592 60.8418 0.11438H52.8529C52.8559 1.22669 53.3058 2.29259 54.1043 3.07912C54.9028 3.86564 55.9849 4.30883 57.1141 4.31181H60.8418C61.2654 4.31181 61.6717 4.47757 61.9712 4.77263C62.2708 5.06768 62.439 5.46786 62.439 5.88514C62.439 6.30241 62.2708 6.70259 61.9712 6.99765C61.6717 7.29271 61.2654 7.45847 60.8418 7.45847H58.4976L62.4204 11.4398C64.8406 10.7591 66.7002 8.505 66.7002 5.88514Z"
        fill="white"/>
      <path
        d="M52.8529 14.8026C52.8559 15.9149 53.3058 16.9808 54.1043 17.7673C54.9028 18.5538 55.9849 18.997 57.1141 19V12.1573L52.8529 7.52344V14.8026Z"
        fill="white"/>
      <path
        d="M62.4118 11.4398H62.4204L58.4976 7.4585H52.8529V7.52346L57.1141 12.1573L62.8118 17.7445C63.2053 18.1373 63.6741 18.4493 64.191 18.6622C64.7078 18.8751 65.2625 18.9847 65.8228 18.9847C66.383 18.9847 66.9377 18.8751 67.4545 18.6622C67.9714 18.4493 68.4402 18.1373 68.8337 17.7445L62.4118 11.4398Z"
        fill="white"/>
      <path
        d="M113.041 2.05633C112.66 1.45734 112.13 0.964415 111.5 0.624488C110.871 0.284561 110.164 0.108951 109.447 0.11438H106.998L112.482 9.46256L114.975 5.34139L113.041 2.05633Z"
        fill="white"/>
      <path
        d="M120.524 0.114398C119.811 0.112342 119.109 0.289618 118.484 0.629443C117.86 0.969269 117.334 1.4604 116.957 2.05634L115.467 4.58864L115.012 5.36683L111.547 11.2449L115.011 5.36683H114.988L114.978 5.34988L112.482 9.46257L112.536 9.55437L106.998 18.9972H109.447C110.177 18.9987 110.896 18.8163 111.534 18.4673C112.173 18.1183 112.71 17.6142 113.095 17.003L114.337 14.8647L114.988 13.7546V13.7673L117.879 8.85104L123 0.114398H120.524Z"
        fill="white"/>
      <path
        d="M37.8214 5.5716V4.31181C37.8183 3.19974 37.3686 2.13405 36.5705 1.34757C35.7723 0.561084 34.6906 0.117734 33.5616 0.11438V14.8026C33.5647 15.9146 34.0144 16.9803 34.8125 17.7668C35.6107 18.5533 36.6924 18.9966 37.8214 19V5.5716Z"
        fill="white"/>
      <path d="M45.2898 14.8026H37.8342V18.9986H45.2898V14.8026Z" fill="white"/>
      <path
        d="M45.277 4.31181V19C46.4062 18.997 47.4883 18.5538 48.2867 17.7673C49.0852 16.9808 49.5351 15.9149 49.5381 14.8026V0.11438C48.4089 0.117362 47.3268 0.560547 46.5284 1.34707C45.7299 2.1336 45.28 3.1995 45.277 4.31181Z"
        fill="white"/>
      <path
        d="M4.25977 18.8856V4.19743C4.26317 3.08523 4.7132 2.01955 5.51159 1.2331C6.30997 0.446657 7.39184 0.00335282 8.52092 0V14.6882C8.51752 15.8004 8.06748 16.8661 7.2691 17.6525C6.47071 18.4389 5.38885 18.8822 4.25977 18.8856Z"
        fill="white"/>
      <path
        d="M4.25972 14.6882V18.8856C3.13077 18.8822 2.04906 18.4389 1.2509 17.6524C0.452742 16.8659 0.00302561 15.8002 0 14.6882H4.25972Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_5282_6383">
        <rect width="123" height="19" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
