import { CurrentUserProvider } from '@/context/CurrentUserProvider';
import { OrganizationProvider } from '@/context/OrganizationProvider';
import { NetworkProvider } from '@/context/NetworkProvider';
import { GqlClientProvider } from '@/graphql-client/GqlClientProvider';
import '@/graphql/register-update-hooks';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { addTabTracking, disableEmotionWarnings } from './utils';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
dayjs.extend(utc);



Sentry.init({
  dsn: "https://0c3565cdac1cf8106d4c0f7b03710392@o4506260373897216.ingest.sentry.io/4506272611827712",
  tunnel: "/api/sentry",
  environment: import.meta.env.MODE,
  // debug: import.meta.env.MODE == 'development',
  release: __APP_VERSION__,
  integrations: [
    new Sentry.BrowserTracing({

      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "/api/"],
});

window.localStorage.setItem('debug', '*, -y-ws:*, -y-idb:*, , -y-prosemirror:*');


addTabTracking();
disableEmotionWarnings();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <>
    <CurrentUserProvider>
      <NetworkProvider>
        <GqlClientProvider>
          <OrganizationProvider>
            <BrowserRouter>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App />
              </LocalizationProvider>
            </BrowserRouter>
          </OrganizationProvider>
        </GqlClientProvider>
      </NetworkProvider>
    </CurrentUserProvider>
  </>
  // </React.StrictMode>,
);
