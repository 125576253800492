import * as React from 'react'
import {Navigate} from 'react-router-dom'
import {useCurrentUser} from "@/context/CurrentUserProvider";

/**
 * Needs to be not logged in, otherwise redirects to /
 * @param props
 * @constructor
 */
function RequireNotAuth({ children }: { children: JSX.Element }) {
  const {currentUser} = useCurrentUser()

  if (currentUser) {
    return <Navigate to='/' replace />;
  }

  return children;
}

export default RequireNotAuth
