import {Unpacked} from '@/types';
import {IGQLPromptQuery} from '@graphql/prompts-hook';
import {Prompt, PromptInputJson} from '@journey-lti-tool/common';
import {IconChoice, IconText, IconUpload} from "@/icons";

export type OrgPrompt = IGQLPromptQuery['jrnyPrompt']
export type OrgPromptInput = Unpacked<OrgPrompt['inputs']>
export type LocalPrompt = Prompt
export type LocalPromptInput = PromptInputJson<any>
export type ActionType = {
  icon: any,
  name: string,
  template: LocalPromptInput,
}
export const actions: ActionType[] = [
  {
    icon: <IconChoice />,
    name: 'Add choices',
    template: {
      version: 1,
      id: '',
      type: 'option',
      weight: 1,
      minContribution: 0,
      config: {
        mode: 'checkbox',
        options: ['Correct input', 'Incorrect input', 'Really incorrect!'],
        optionWeights: [1, 0, -1],
      }
    }
  },
  {
    icon: <IconText />,
    name: 'Add text',
    template: {
      version: 1,
      id: '',
      type: 'text',
      weight: 1,
      minContribution: 0,
      config: {
        multiline: true,
        autoCorrectable: false,
        answers: ['Correct', 'Incorrect', 'Bad'],
        answerWeights: [1, 0, -1],
      }
    }
  },
  {
    icon: <IconUpload />,
    name: 'Add upload',
    template: {
      version: 1,
      id: '',
      type: 'upload',
      weight: 1,
      minContribution: 0,
      config: {
        name: 'Homework'
      }
    }
  },
  /*
    {
      icon: <GradingIcon />,
      name: 'Add grading',
      template: {
        version: 1,
        id: '',
        type: 'grading',
        weight: 1,
        minContribution: 0,
        config: {}

      }
    },
  */
];
