import {applyDevTools, DevToolsOpts, removeDevTools} from 'prosemirror-dev-toolkit';
import {EditorView} from 'prosemirror-view';
import * as React from 'react';
import {ReactNode} from 'react';

interface ProsemirrorDevToolsProviderProps {
  children?: ReactNode;

}

interface ProsemirrorDevToolsProviderShape {
  toggle: (view?: EditorView) => void
  set: (view: EditorView) => void
  unset: (view: EditorView) => void
  isCurrent: (view: EditorView) => boolean
  current?: EditorView|null

}

const ProseMirrorDevContext = React.createContext<ProsemirrorDevToolsProviderShape>({
  toggle: (view?: EditorView) => {
  },
  set: (view: EditorView) => {
  },
  unset: (view: EditorView) => {
  },
  isCurrent: (view: EditorView) => false

})
const defaults: DevToolsOpts = {devToolsExpanded: true, buttonPosition: 'bottom-left'}

export function ProsemirrorDevToolsProvider(props: ProsemirrorDevToolsProviderProps) {
  const [current, setCurrent] = React.useState<EditorView | null>(null)
  const value = React.useMemo(() => {
    return {
      toggle: (view?: EditorView) => {
        if (!view) {
          return
        }
        if (current === view) {

          removeDevTools()
          setCurrent(null)
        } else {
          current && removeDevTools()
          setCurrent(view)
          applyDevTools(view, defaults)
        }
      },
      set: (view: EditorView) => {
        if (!view) {
          return
        }
        if (current !== view) {
          setCurrent(view)
          applyDevTools(view, defaults)
        }
      },
      unset: (view: EditorView) => {
        if (!view) {
          return
        }
        if (current === view) {
          removeDevTools()
          setCurrent(current)
        }
      },
      current,
      isCurrent: (view: EditorView) => current === view

    }
  }, [current])
  return (<ProseMirrorDevContext.Provider value={value} children={props.children}/>)
}

export const useProseMirrorDevTools = () => React.useContext(ProseMirrorDevContext)
