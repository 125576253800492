export const TopNavigation = {
  SignIn: '/sign-in',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  AcceptInvitation: '/accept-invitation',
  Admin: '/admin',
  Plans: '/plans',
  Subscription: '/subscription',

}
