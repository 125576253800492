import {useProseMirrorDevTools} from "@/context/ProsemirrorDevToolsProvider";
import * as React from "react";
import {Button, ButtonGroup, createSvgIcon, Stack} from "@mui/material";
import {
  IconBold,
  IconBreak,
  IconBulletList,
  IconDev,
  IconHorizontalRule,
  IconItalic,
  IconNumberList,
  IconStrike,
} from '@/icons'

import {Editor} from "@tiptap/react";
import {PromptInput} from '@journey-lti-tool/common';

const Heading1Icon = createSvgIcon(<path d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M14,18V16H16V6.31L13.5,7.75V5.44L16,4H18V16H20V18H14Z" />, 'Heading1')
const Heading2Icon = createSvgIcon(<path d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M21,18H15A2,2 0 0,1 13,16C13,15.47 13.2,15 13.54,14.64L18.41,9.41C18.78,9.05 19,8.55 19,8A2,2 0 0,0 17,6A2,2 0 0,0 15,8H13A4,4 0 0,1 17,4A4,4 0 0,1 21,8C21,9.1 20.55,10.1 19.83,10.83L15,16H21V18Z" />, 'Heading2')
const Heading3Icon = createSvgIcon(<path d="M3,4H5V10H9V4H11V18H9V12H5V18H3V4M15,4H19A2,2 0 0,1 21,6V16A2,2 0 0,1 19,18H15A2,2 0 0,1 13,16V15H15V16H19V12H15V10H19V6H15V7H13V6A2,2 0 0,1 15,4Z" />, 'Heading3')
const ParagraphIcon = createSvgIcon(<path d="M10,11A4,4 0 0,1 6,7A4,4 0 0,1 10,3H18V5H16V21H14V5H12V21H10V11Z" />, 'Paragraph')
interface MenuBarProps {
  editor: Editor | null
  inputs: PromptInput<any>[]
}

export function MenuBar({editor, inputs}: MenuBarProps)  {
  const {toggle, current} = useProseMirrorDevTools()
  if (!editor) {
    return null
  }
  const isCurrent = React.useMemo(() => editor.view === current, [current, editor])
  return (
    <Stack spacing={1} alignItems='flex-start'>
      <ButtonGroup variant="outlined" size={'small'}>
        <Button
          size='small'
          onClick={() => toggle(editor.view)}
          variant={isCurrent ? 'contained' : undefined}

        >
          <IconDev/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          variant={editor.isActive('bold') ? 'contained' : undefined}
        >
          <IconBold/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          variant={editor.isActive('italic') ? 'contained' : undefined}
        >
          <IconItalic/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          variant={editor.isActive('strike') ? 'contained' : undefined}
        >
          <IconStrike/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <IconBulletList/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().toggleOrderedList().run()}

          variant={editor.isActive('orderedList') ? 'contained' : undefined}

        >
          <IconNumberList/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().setParagraph().run()}

          variant={editor.isActive('paragraph',) ? 'contained' : undefined}
        >
          <ParagraphIcon/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().setHeading({level:1}).run()}

          variant={editor.isActive('heading', {level:1}) ? 'contained' : undefined}
        >
          <Heading1Icon/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().setHeading({level:2}).run()}

          variant={editor.isActive('heading', {level:2}) ? 'contained' : undefined}
        >
          <Heading2Icon/>
        </Button>
        <Button
          size='small'
          onClick={() => editor.chain().focus().setHeading({level:3}).run()}

          variant={editor.isActive('heading', {level:3}) ? 'contained' : undefined}
        >
          <Heading3Icon/>
        </Button>
        <Button
          size='small' onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <IconHorizontalRule/>
        </Button>
        <Button
          size='small' onClick={() => editor.chain().focus().setHardBreak().run()}>
          <IconBreak/>
        </Button>
      </ButtonGroup>
      <ButtonGroup size='small' color='secondary' variant='contained'>

        {inputs.map((input, ix) => (<Button
          size='small'
          key={input.id}
          onClick={() => editor.chain().focus().insertPromptInput(ix + 1).run()}
        >
          input {ix + 1}
        </Button>))
        }
      </ButtonGroup>

    </Stack>
  )
}
