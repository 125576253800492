import { useState, useEffect } from 'react';

export function useLocalState<T>(storageKey: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = () => {
    const item = window.localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : initialValue;
  };

  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, [storageKey]);

  return [storedValue, setValue];
}
