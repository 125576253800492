import {IGQLInvitationStatus} from '@/graphql-types';
import Button from '@components/Button';
import {Chip, Container, List, ListItem, ListItemText, Paper} from '@mui/material';
import * as React from 'react';
import {
  useUserInvitationsQuery,
  useAcceptInvitationMutation,
  useRejectInvitationMutation
} from '@graphql/invitations-hook';
import {useSnackbar} from "notistack";
import {useCurrentUser} from "@/context/CurrentUserProvider";


export default function UserInvitationsView() {
  const {currentUser, setCurrentUser} = useCurrentUser();
  const {enqueueSnackbar} = useSnackbar()
  const [invitations] = useUserInvitationsQuery({variables: {id: currentUser?.user.id || ''}, pause: !currentUser});
  const [, accept] = useAcceptInvitationMutation()
  const [, reject] = useRejectInvitationMutation()
  const doAccept = React.useCallback(async (variables: { id: string }) => {
    const result = await accept(variables)
    if (result.error) {
      enqueueSnackbar(result.error.message, {variant: 'error'})
    } else {
      setCurrentUser(result.data!.jrnyAcceptInvitation.loginResult)
    }

  }, [accept])
  return currentUser && (<Container>
      <Paper>

        <List>
          {invitations.data?.jrnyUserInvitations.map(invitation => (
            <ListItem secondaryAction={invitation.status == IGQLInvitationStatus.Pending
              ? (<>
                <Button onClick={() => doAccept({id: invitation.id})} size='small'>ACCEPT</Button>
                <Button onClick={() => reject({id: invitation.id})} size='small'>REJECT</Button>
              </>)
              : (<Chip size='small' label={invitation.status} color='primary'/>)

            } key={invitation.id}>
              <ListItemText primary={`${invitation.type}: ${invitation.name}`} secondary={invitation.email}/>
            </ListItem>))}
        </List>
      </Paper>
    </Container>) ||
    null;
}
