import {generateDebug} from '@/utils';
import {FileObjectChooser} from '@components/file-object/FileObjectChooser';
import {FileFolderTree, MinimalFileObject} from '@components/file-object/types';
import {useOrganizationFilesQuery, useOrganizationFoldersQuery} from '@graphql/organizations-hook';
import * as React from 'react';
import {ReactNode} from 'react';
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {FileFolder} from "@/types";

const debug = generateDebug('AssetsProvider');

interface ChooserConfig<T extends MinimalFileObject> {
  type: 'image' | 'video' | 'document'
  onSelect?: (val: T | null) => void
}

const EMPTY_FOLDER: FileFolderTree = {
  id: '',
  name: '',
  path: [],
  folders: [],
  files: [],
  parent: undefined
}

export interface AssetsContextValue {
  currentFolder: FileFolderTree
  setCurrentFolder: (folder: FileFolderTree) => void,
  showFileChooser: <T extends MinimalFileObject>(val: ChooserConfig<T>) => void;
}

const noOp = () => {
}
export const ChooserContext = React.createContext<AssetsContextValue>({
  currentFolder: EMPTY_FOLDER,
  setCurrentFolder: noOp,
  showFileChooser: noOp,
});

interface AssetsProviderProps {
  children: ReactNode;
}

const toFolder = (folder: FileFolder, path: FileFolderTree[]): FileFolderTree => ({
  id: folder.id,
  name: folder.name,
  path,
  folders: [],
  files: folder.fileObjects,
})
type FolderLibrary = Record<string, FileFolderTree>
/**
 * Converts the list of FileFolder to a FileFolderTree hierarchy, updating the path of each folder
 */
const toFileFolderTree = (folders?: FileFolder[]): { root: FileFolderTree, byId: FolderLibrary } => {
  const byId: FolderLibrary = {}
  if (!folders || folders.length === 0) {
    return {root: EMPTY_FOLDER, byId}
  }
  const rootFolder = folders.find(f => !f.parentId)
  if (!rootFolder) {
    debug('No root folder found')
    return {root: EMPTY_FOLDER, byId}
  }
  const root: FileFolderTree = toFolder(rootFolder, [])
  byId[root.id] = root
  let toTraverse = folders.filter(f => f.id !== rootFolder.id)
  const stack = [root]
  while (stack.length) {
    const folder = stack.pop()
    if (!folder) {
      continue
    }
    const remaining: FileFolder[] = []
    toTraverse.forEach(f => {
      if (f.parentId === folder.id) {
        const newFolder = toFolder(f, [...folder.path, folder]);
        byId[newFolder.id] = newFolder
        newFolder.parent = folder
        folder.folders.push(newFolder)
      } else {
        remaining.push(f)
      }
    })
    toTraverse = remaining
    stack.push(...folder.folders)
  }
  return {root, byId}

}

const CURRENT_FOLDER_ID_KEY = 'imageFolderId';

export function AssetsProvider(props: AssetsProviderProps) {
  const [fileChooser, showFileChooser] = React.useState<ChooserConfig<any> | null>(null);
  const {currentOrganization} = useCurrentOrganization();
  const [currentFolderId, _setCurrentFolderId] = React.useState('')
  const [folderQuery] = useOrganizationFoldersQuery({
    variables: {
      input: {
        id: currentOrganization?.id || ''
      }
    },
    pause: !currentOrganization
  })
  const {byId, root} = React.useMemo(() => toFileFolderTree(folderQuery.data?.jrnyOrganizationFolders), [folderQuery])

  const currentFolder = React.useMemo(() => byId[currentFolderId] || root, [byId, currentFolderId])

  const setCurrentFolder = React.useCallback((folder: FileFolderTree) => {
    _setCurrentFolderId(folder.id)
    window.localStorage.setItem(CURRENT_FOLDER_ID_KEY, folder.id)
  }, [])
  React.useEffect(() => {
    const imageFolderId = window.localStorage.getItem('imageFolderId')
    if (imageFolderId) {
      _setCurrentFolderId(imageFolderId)
    }
  }, [currentOrganization])


  const onSelect = (val: MinimalFileObject | null) => {
    debug('onSelect', val)
    fileChooser?.onSelect?.(val)
    showFileChooser(null)
  }
  const value = React.useMemo(() => {
    return {
      currentFolder,
      setCurrentFolder,
      showFileChooser,
    }
  }, [currentFolder, fileChooser])

  return (<ChooserContext.Provider value={value}>
    <>
      {React.Children.map(props.children, c => c)}

      {currentOrganization && <FileObjectChooser
        organizationId={currentOrganization!.id}
        folder={currentFolder}
        onGoToFolder={setCurrentFolder}
        type={fileChooser?.type}
        open={!!fileChooser}
        onSelect={fileChooser?.onSelect && onSelect}
        onClose={() => showFileChooser(null)}/>}
    </>
  </ChooserContext.Provider>);
}

export const useAssets = (): AssetsContextValue => {
  return React.useContext(ChooserContext)
}
