import {createContextualCan} from '@casl/react';
import {AbilityContext, ShiroContext} from "@/context/CurrentUserProvider";
import * as React from 'react'

interface ShiroCanProps {
  children: React.ReactNode
  permission: string
  not?: boolean
}

export function ShiroCan({children, permission, not}: ShiroCanProps) {
  const shiro = React.useContext(ShiroContext);
  return (<>{not
    ? (shiro.check(permission) ? null : children)
    : (shiro.check(permission) ? children : null)}
    </>)

}

export const CaslCan = createContextualCan(AbilityContext.Consumer);
