import { IGQLJrnyInvitation, IGQLInvitationStatus } from '@/graphql-types';
import Button from '@components/Button';
import Paper from '@components/Paper';
import { useResendInvitationMutation, useRevokeInvitationMutation } from '@graphql/invitations-hook';
import { Chip, Container, List, ListItem, ListItemText } from '@mui/material';
import * as React from 'react';

interface OrganizationInvitationsListProps {
  invitations: IGQLJrnyInvitation[];
}

export default function InvitationsList(props: OrganizationInvitationsListProps) {
  const [, resend] = useResendInvitationMutation();
  const [, revoke] = useRevokeInvitationMutation();

  const actions = React.useCallback((invitation: { id: string, status: IGQLInvitationStatus }) => {
    const result = [];
    if (invitation.status != IGQLInvitationStatus.Accepted) {
      invitation.status != IGQLInvitationStatus.Revoked && result.push(
        <Button size='small'
                onClick={() => revoke({ id: invitation.id })}
                key='b1'>
          REVOKE
        </Button>);
      result.push(<Button
        size='small'
        onClick={() => resend({ id: invitation.id })}
        key='b2'>
        RESEND
      </Button>);
    }
    result.push(<Chip size='small'
                      label={invitation.status}
                      color='primary'
                      key='c1' />);
    return result;
  }, []);

  return props.invitations.length && (
        <List>
          {props.invitations.map(invitation => (<ListItem secondaryAction={actions(invitation)} key={invitation.id}>
            <ListItemText primary={invitation.name} secondary={invitation.email} />
          </ListItem>))}
        </List>
  ) || null;
}
