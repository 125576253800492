import * as Types from '../../graphql-types';

import { gql } from 'urql';
export type IGQLOrganizationUserFieldsFragment = { __typename: 'JrnyOrganizationUser', id: string, firstName: string, lastName: string, email?: string | null, organizationId: string };

export const OrganizationUserFieldsFragmentDoc = gql`
    fragment OrganizationUserFields on JrnyOrganizationUser {
  id
  firstName
  lastName
  email
  organizationId
}
    `;