import {AssetsProvider} from '@/context/AssetsProvider';
import ModifiedProvider from '@/context/ModifiedProvider';
import {ProsemirrorDevToolsProvider} from '@/context/ProsemirrorDevToolsProvider';
import Link from '@components/Link';
import ModifiedGuard from '@components/ModifiedGuard';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import Header from './Header';
import Navigator from './Navigator';
import {LinearProgress, linearProgressClasses, styled} from "@mui/material";
import {ProgressContext, ProgressProvider} from "@/context/ProgressProvider";
import {generateDebug} from "@/utils";

const debug = generateDebug('Main');

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
    secondary: {
      light: '#e263ff',
      main: '#9900e5',
      dark: '#7100b3',
    },

  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 6,
  position: 'fixed',
  zIndex: 1101,
  top: 48,
  right: 0,
  // [`&.${linearProgressClasses.colorPrimary}`]: {
  //   backgroundColor: 'red',
  // },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: 'red',
  },
  [`& .${linearProgressClasses.bar1Indeterminate}`]: {
    backgroundColor: 'red',
  },
  [`& .${linearProgressClasses.bar2Indeterminate}`]: {
    backgroundColor: 'red',
  },
}));
export default function Main() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const contentRoot = React.useRef<HTMLElement>()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <ProgressProvider>
        <Box sx={{display: 'flex', minHeight: '100vh'}}>
          <CssBaseline/>
          <Box
            component="nav"
            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{style: {width: drawerWidth}}}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            )}
            <Navigator
              PaperProps={{style: {width: drawerWidth}}}
              sx={{display: {sm: 'block', xs: 'none'}}}
            />
          </Box>
          <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <AssetsProvider>
            <Header onDrawerToggle={handleDrawerToggle}/>
            <ProgressContext.Consumer>
              {({progress, show}) => {
                return <BorderLinearProgress
                  sx={{left: !isSmUp ? 0 : drawerWidth, zIndex: 1101, opacity: show ? 1 : 0}}
                  variant={progress != 'unknown' ? 'determinate' : 'indeterminate'}
                  value={progress != 'unknown' ? progress : undefined}
                />
              }}
            </ProgressContext.Consumer>
            <Box ref={contentRoot} component="main" sx={{flex: 1, py: 3, px: 2, bgcolor: '#eaeff1'}}>
              <ModifiedProvider>
                  <ModifiedGuard>
                    <ProsemirrorDevToolsProvider>
                      <Outlet/>
                    </ProsemirrorDevToolsProvider>
                  </ModifiedGuard>
              </ModifiedProvider>
            </Box>
            <Box component="footer" sx={{p: 2, bgcolor: '#eaeff1'}}>
              <Copyright/>
            </Box>
            </AssetsProvider>
          </Box>
        </Box>
      </ProgressProvider>
    </ThemeProvider>
  );
}
