import {Box, IconButton, Stack, TextField} from '@mui/material';
import * as React from 'react'
import {IconDelete, IconAdd} from '@/icons'

interface OptionItemProps {
  value: string,
  weight: number
  ix: number
  onChange: (ix: number, value: string, weight: number) => void
  onRemove: (ix: number) => void
  onAdd: (ix: number) => void
}

function OptionItem({
                      ix,
                      weight,
                      value,
                      onChange,
                      onRemove,
                      onAdd
                    }: OptionItemProps) {
  return (
    <Box sx={{mb:1, display: 'flex', flexFlow: 'nowrap'}}>
      <TextField
        size='small'
        type={'number'}
        sx={{maxWidth: 80, display: 'inline-block'}}
        value={weight}
        onChange={(evt) => onChange(ix, value, Number.parseFloat(evt.target.value))}
      />
      <TextField
        size='small'
        fullWidth
        sx={{mx: 1, display: 'inline-block'}}
        value={value}
        onChange={(evt) => onChange(ix, evt.target.value, weight)}/>
      <IconButton onClick={() => onRemove(ix)}><IconDelete/></IconButton>
      <IconButton onClick={() => onAdd(ix)}><IconAdd/></IconButton>

    </Box>
  )
}

interface OptionsEditorProps {
  values: string[],
  weights: number[]
  onChange: (ix: number, value: string, weight: number) => void
  onRemove: (ix: number) => void
  onAdd: (ix: number) => void
}

export function OptionsEditor({values, weights, onChange, onRemove, onAdd}: OptionsEditorProps) {
  return (<Stack>
    {values.map((v, ix) => (
      <OptionItem
        value={v}
        weight={weights[ix]}
        ix={ix}
        onRemove={onRemove}
        onChange={onChange}
        onAdd={onAdd}
        key={ix}/>
    ))}

  </Stack>)
}
