import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
export type IGQLFileFolderFieldsFragment = { __typename: 'FileFolder', id: string, name: string, parentId?: string | null, created: any, fileObjects: Array<{ __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any }> };

export const FileFolderFieldsFragmentDoc = gql`
    fragment FileFolderFields on FileFolder {
  __typename
  id
  name
  parentId
  created
  fileObjects {
    ...FileObjectFields
  }
}
    ${FileObjectFieldsFragmentDoc}`;