import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useCourseOccasionsQuery, useCreateCourseOccasionMutation, useCreateCourseOccasionsMutation, useRemoveCourseOccasionMutation, useUpdateCourseOccasionMutation } from '@/graphql/hooks/course-occasions-hook';
import { IconAdd, IconDelete, IconEdit } from "@/icons";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
import { sortBy } from "lodash";
import * as React from 'react';
import { useParams } from "react-router-dom";
import RemoveCourseOccasionDialog from "./components/RemoveCourseOccasionDialog";
import SelectDateDialog from "./components/SelectDateDialog";
import { CourseOccasion } from "./types";
import CreateOccasionsDialog from "./components/CreateOccasionsDialog";
dayjs.extend(utc);

const NEW_OCCASION: CourseOccasion = {
  __typename: 'JrnyCourseOccasion',
  id: 'new',
  courseId: '',
  date: '',
  duration: 90


}
interface CourseOccasionsViewProps {
}

export default function CourseOccasionsView(props: CourseOccasionsViewProps) {

  const { currentOrganization } = useCurrentOrganization();
  const { courseId } = useParams()
  const [toRemove, setToRemove] = React.useState<CourseOccasion>()
  const [toUpdate, setToUpdate] = React.useState<CourseOccasion>()
  const [showCreateDialog, setShowCreateDialog] = React.useState(false)
  const [, addCourseOccasions] = useCreateCourseOccasionsMutation();
  const [, updateCourseOccasion] = useUpdateCourseOccasionMutation();
  const [, removeCourseOccasion] = useRemoveCourseOccasionMutation();
  const [occasionsResult] = useCourseOccasionsQuery({
    variables: { courseId: courseId || '' },
    pause: !currentOrganization
  });
  const createOccasionsHandler = React.useCallback((occasions: { date: string, duration: number }[]) => {
    return addCourseOccasions({
      input: {
        courseId: courseId!,
        dateDurations: occasions
      }
    })

  }, [])
  const dateHandler = React.useCallback((date: Dayjs, duration: number, occasion: CourseOccasion) => {
    return updateCourseOccasion({
      input: {
        courseId: occasion.courseId,
        id: occasion.id,
        date: date.format('YYYY-MM-DD'),
        duration,
      }
    })
  }, [])
  const occasions = React.useMemo(() => sortBy(occasionsResult.data?.jrnyCourseOccasionsForCourse || [], 'date'), [occasionsResult.data]);
  return courseId && currentOrganization && (<Container>
    <Box sx={{ position: 'relative', mb: 8 }}>
      <Card>
        <CardHeader title='Course Occasions' />
        <CardContent>
          <List>
            {occasions.map((occasion, ix) => (<ListItem
              sx={{ paddingRight: '80px' }}
              secondaryAction={<>
                <IconButton
                  onClick={() => setToUpdate(occasion)}
                  edge='end'
                ><IconEdit /></IconButton>
                <IconButton
                  onClick={() => setToRemove(occasion)}/*  */
                  edge='end'
                ><IconDelete /></IconButton>
              </>}

              divider={ix < occasions.length - 1}
              key={occasion.id}>
              <ListItemText primary={occasion.date} secondary={occasion.duration+' min'} />

            </ListItem>))}
          </List>
        </CardContent>
      </Card>
    </Box>
    <SelectDateDialog
      protectedDates={occasions.map(o => o.date)}
      occasion={toUpdate}
      onClose={() => setToUpdate(undefined)}
      dateHandler={dateHandler} />
    <CreateOccasionsDialog
      protectedDates={occasions.map(o => o.date)}
      open={showCreateDialog}
      onClose={() => setShowCreateDialog(false)}
      occasionsHandler={createOccasionsHandler} />
    <RemoveCourseOccasionDialog onClose={() => setToRemove(undefined)} occasion={toRemove}
      removeUser={removeCourseOccasion} />
    <Fab color='primary' onClick={() => setShowCreateDialog(true)} sx={{ position: 'fixed', bottom: 24, right: 24 }}><IconAdd /></Fab>

  </Container>) || null;
}
