import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLResourceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLResourceQuery = { __typename: 'Query', jrnyResource: { __typename: 'JrnyResource', id: string, category: string, categoryOrder: number, type: Types.IGQLJrnyResourceType, url?: string | null, description?: string | null, name: string, file?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLUpdateResourceMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateResourceInput;
}>;


export type IGQLUpdateResourceMutation = { __typename: 'Mutation', jrnyUpdateResource: { __typename: 'JrnyResource', id: string, category: string, categoryOrder: number, type: Types.IGQLJrnyResourceType, url?: string | null, description?: string | null, name: string, file?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };


export const ResourceDocument = gql`
    query resource($id: ID!) {
  jrnyResource(id: $id) {
    __typename
    id
    category
    categoryOrder
    type
    url
    description
    name
    file {
      ...FileObjectFields
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useResourceQuery(options: Omit<Urql.UseQueryArgs<IGQLResourceQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLResourceQuery, IGQLResourceQueryVariables>({ query: ResourceDocument, ...options });
};
export const UpdateResourceDocument = gql`
    mutation updateResource($input: JrnyUpdateResourceInput!) {
  jrnyUpdateResource(input: $input) {
    __typename
    id
    category
    categoryOrder
    type
    url
    description
    name
    file {
      ...FileObjectFields
    }
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useUpdateResourceMutation() {
  return Urql.useMutation<IGQLUpdateResourceMutation, IGQLUpdateResourceMutationVariables>(UpdateResourceDocument);
};