import * as React from 'react'
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {Container, Fab, IconButton, Paper} from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  AnyItem,
  AnyContentItem,
  AnyParentItem,
  Checkpoint,
  Course,
  CreateItemType,
  Lesson,
  LocalOrganization
} from "./types";
import {DragDropContext, DropResult,} from 'react-beautiful-dnd';
import {useImmer} from "use-immer";
import {original, nothing} from "immer";
import {
  useCloneCheckpointMutation,
  useCreateCheckpointMutation,
  useMoveCheckpointMutation,
  useRemoveCheckpointMutation
} from "@graphql/checkpoints-hook";
import {useKeydown} from "@/hooks/use-keydown";
import {cloneDeep} from "lodash";
import {renderCourses} from "@/pages/structure/components/StructuredTreeItem";
import {NameDialog} from "@components/NameDialog";
import {DeleteDialog} from "@/pages/structure/components/DeleteDialog";
import {useNavigate} from "react-router-dom";
import {
  useCloneContentItemMutation,
  useCloneLessonMutation, useCreateContentItemMutation,
  useCreateLessonMutation, useMoveContentItemMutation,
  useMoveLessonMutation, useRemoveContentItemMutation,
  useRemoveLessonMutation
} from "@graphql/lessons-hook";
import {debug, findItem, findPath, setCopyIds, toLocalLib, typeToTypeName} from "@/pages/structure/utils";
import {useWrapperHook} from "@/pages/structure/use-wrapper-hook";
import {useCloneCourseMutation, useCreateCourseMutation, useDeleteCourseMutation} from "@graphql/courses-hook";
import {IconAdd} from "@/icons";

interface StructureViewProps {

}


const EXPANDED_KEY = 'structure.expanded';

export function StructureView(props: StructureViewProps) {
  const {currentOrganization} = useCurrentOrganization();
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState<string[]>(JSON.parse(window.localStorage.getItem(EXPANDED_KEY) || '[]'))
  const isAltPressed = useKeydown(['Alt'])
  const [entityType, setEntityType] = React.useState('')
  const [entityName, setEntityName] = React.useState('')
  const [nameHandler, setNameHandler] = React.useState<undefined | { handler: (name: string | null) => void }>()
  const [confirmDeleteHandler, setConfirmDeleteHandler] = React.useState<undefined | { handler: (remove: boolean) => void }>()
  const [isDragging, setIsDragging] = React.useState(false)
  const [localOrganization, setLocalOrganization] = useImmer<LocalOrganization | undefined>(undefined)
  const handleToggle = React.useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    debug('onToggle', event)
    event.persist()
    let iconClicked = (event.target as any).closest(".MuiTreeItem-iconContainer")
    if (iconClicked) {
      setExpanded(nodeIds);
    }
    window.localStorage.setItem(EXPANDED_KEY, JSON.stringify(nodeIds))

  }, []);
  const selectHandler = React.useCallback((event: React.SyntheticEvent, nodeId: string) => {
    const item = localOrganization?.lib[nodeId]
    let labelClicked = (event.target as any).closest(".MuiTreeItem-label")
    if (labelClicked) {
      switch (item?.__typename) {
        case "JrnyCourse":
          navigate(`../course/${item.id}`)
          return;
        case "JrnyCheckpoint":
          navigate(`../checkpoint/${item.id}`)
          return;
        case "JrnyLesson":
          navigate(`../lesson/${item.id}`)
          return;
        case "JrnyMedia":
          navigate(`../media/${item.id}`)
          return;
        case "JrnyPrompt":
          navigate(`../${item.category}/${item.id}`)
          return;
        case "JrnyResource":
          navigate(`../resource/${item.id}`)
          return;
      }
    }
  }, [localOrganization])

  const resetLocal = React.useCallback(() => {
    debug('updating currentOrganization', currentOrganization)
    if (currentOrganization) {
      const local: LocalOrganization = {
        id: currentOrganization.id,
        courses: currentOrganization.courses,
        lib: toLocalLib(currentOrganization.courses),
        isSaving: false
      }
      setLocalOrganization((draft) => local)
    } else {
      setLocalOrganization((draft) => nothing)
    }

  }, [currentOrganization])

  //////////////////////////////////////////////////////////////////
  //                        Courses
  //////////////////////////////////////////////////////////////////
  const [, doCreateCourse] = useCreateCourseMutation();
  const [, doCloneCourse] = useCloneCourseMutation();
  const [, doRemoveCourse] = useDeleteCourseMutation();
  const removeCourse = useWrapperHook((id: string) => doRemoveCourse({id}), 'Remove course', resetLocal)
  const cloneCourse = useWrapperHook(async (id: string, name: string) => doCloneCourse({
    input: {
      id,
      name,
    }
  }), 'Clone course', resetLocal)
  const createCourse = useWrapperHook((organizationId: string, name: string) => doCreateCourse({
    input: {
      name,
      organizationId,
    }
  }), 'Create course', resetLocal, )

  //////////////////////////////////////////////////////////////////
  //                        Checkpoints
  //////////////////////////////////////////////////////////////////
  const [, doMoveCheckpoint] = useMoveCheckpointMutation()
  const [, doCloneCheckpoint] = useCloneCheckpointMutation()
  const [, doRemoveCheckpoint] = useRemoveCheckpointMutation()
  const [, doCreateCheckpoint] = useCreateCheckpointMutation()

  const moveCheckpoint = useWrapperHook((sourceCourseId: string, checkpointId: string, targetCourseId: string, targetOrder: number) => doMoveCheckpoint({
    input: {
      checkpointId,
      sourceCourseId,
      targetCourseId,
      targetOrder
    }
  }), 'Move checkpoint', resetLocal)
  const cloneCheckpoint = useWrapperHook(async (checkpointId: string, targetCourseId: string, targetOrder: number, name: string) => doCloneCheckpoint({
    input: {
      checkpointId,
      targetCourseId,
      targetOrder,
      name,
    }
  }), 'Clone checkpoint', resetLocal)
  const removeCheckpoint = useWrapperHook((courseId: string, id: string) => doRemoveCheckpoint({
    input: {
      id,
      courseId
    }
  }), 'Remove checkpoint', resetLocal)
  const createCheckpoint = useWrapperHook((courseId: string, name: string, order: number) => doCreateCheckpoint({
    input: {
      courseId,
      name,
      order
    }
  }), 'Create checkpoint', resetLocal)

  //////////////////////////////////////////////////////////////////
  //                        Lessons
  //////////////////////////////////////////////////////////////////
  const [, doMoveLesson] = useMoveLessonMutation()
  const [, doCloneLesson] = useCloneLessonMutation()
  const [, doRemoveLesson] = useRemoveLessonMutation()
  const [, doCreateLesson] = useCreateLessonMutation()

  const moveLesson = useWrapperHook((sourceCheckpointId: string, lessonId: string, targetCheckpointId: string, targetOrder: number) => doMoveLesson({
    input: {
      lessonId,
      sourceCheckpointId,
      targetCheckpointId,
      targetOrder
    }
  }), 'Move lesson', resetLocal)
  const cloneLesson = useWrapperHook(async (lessonId: string, targetCheckpointId: string, targetOrder: number, name: string) => doCloneLesson({
    input: {
      lessonId,
      targetCheckpointId,
      targetOrder,
      name,
    }
  }), 'Clone lesson', resetLocal)
  const removeLesson = useWrapperHook((checkpointId: string, lessonId: string) => doRemoveLesson({
    input: {
      id: lessonId,
      checkpointId
    }
  }), 'Remove lesson', resetLocal)
  const createLesson = useWrapperHook((checkpointId: string, name: string, order: number) => doCreateLesson({
    input: {
      checkpointId,
      name,
      order
    }
  }), 'Create lesson', resetLocal)

  //////////////////////////////////////////////////////////////////
  //                        Content items
  //////////////////////////////////////////////////////////////////

  const [, doMoveContentItem] = useMoveContentItemMutation()
  const [, doCloneContentItem] = useCloneContentItemMutation()
  const [, doRemoveContentItem] = useRemoveContentItemMutation()
  const [, doCreateContentItem] = useCreateContentItemMutation()

  const moveContentItem = useWrapperHook((contentItemType: string, sourceLessonId: string, contentItemId: string, targetLessonId: string, targetOrder: number) => doMoveContentItem({
    input: {
      contentItemId,
      sourceLessonId,
      targetLessonId,
      targetOrder,
      contentItemType
    }
  }), 'Move content item', resetLocal)
  const cloneContentItem = useWrapperHook(async (contentItemType: string, contentItemId: string, targetLessonId: string, targetOrder: number, name: string) => doCloneContentItem({
    input: {
      contentItemId,
      contentItemType,
      targetLessonId,
      targetOrder,
      name,
    }
  }), 'Clone content item', resetLocal)
  const removeContentItem = useWrapperHook((lessonId: string, contentItemType: string, contentItemId: string, ) => doRemoveContentItem({
    input: {
      id: contentItemId,
      lessonId,
      contentItemType
    }
  }), 'Remove lesson', resetLocal)
  const createContentItem = useWrapperHook((contentItemType: string, lessonId: string, name: string, order: number, category?: 'prompt' | 'challenge') => doCreateContentItem({
    input: {
      lessonId,
      contentItemType,
      name,
      order,
      category,
    }
  }), 'Create content item', resetLocal)


  const onDragEnd = React.useCallback((result: DropResult) => {
    debug('onDragEnd', result)
    setIsDragging(false)
    if (!result.destination) {
      return
    }
    const isClone = isAltPressed
    const {source, destination, draggableId, type} = result
    // we need to find parent
    const originPath = findPath(source.droppableId, localOrganization!.lib)
    const targetPath = findPath(destination.droppableId, localOrganization!.lib)
    const sourceEntity: AnyParentItem = findItem(originPath, localOrganization!.courses) as AnyParentItem
    if (isClone) {
      if (type === 'course') {
        setEntityType('checkpoint')
        const checkpoint = (sourceEntity as Course).checkpoints[source.index];
        setEntityName(checkpoint.name + ' (copy)')
        setNameHandler({
          handler: (name) => {
            if (name) {
              const copy = cloneDeep(checkpoint);
              setCopyIds(copy)
              let targetIx = destination.index
              if (source.droppableId == destination.droppableId && destination.index >= source.index) {
                targetIx++
              }
              setLocalOrganization(draft => {
                const targetCourse: Course = findItem(targetPath, draft!.courses) as Course

                targetCourse.checkpoints.splice(targetIx, 0, copy)
                targetCourse.checkpoints.forEach((p, i) => p.order = i)
                draft!.isSaving = true
                draft!.lib = toLocalLib(draft!.courses)
              })
              cloneCheckpoint(draggableId, destination.droppableId, targetIx, name).then()
            }
            setNameHandler(undefined)
          }
        })
      } else if (type == 'checkpoint') {
        setEntityType('lesson')
        const lesson = (sourceEntity as Checkpoint).lessons[source.index];
        setEntityName(lesson.name + ' (copy)')
        setNameHandler({
          handler: (name) => {
            if (name) {
              const copy = cloneDeep(lesson);
              setCopyIds(copy)
              let targetIx = destination.index
              if (source.droppableId == destination.droppableId && destination.index >= source.index) {
                targetIx++
              }
              setLocalOrganization(draft => {
                const targetCheckpoint: Checkpoint = findItem(targetPath, draft!.courses) as Checkpoint

                targetCheckpoint.lessons.splice(targetIx, 0, copy)
                targetCheckpoint.lessons.forEach((p, i) => p.order = i)
                draft!.isSaving = true
                draft!.lib = toLocalLib(draft!.courses)
              })
              cloneLesson(draggableId, destination.droppableId, targetIx, name).then()
            }
            setNameHandler(undefined)
          }
        })

      } else if (type == 'lesson') {
        setEntityType('lesson')
        const item = (sourceEntity as Lesson).contentItems[source.index];
        setEntityName(item.name + ' (copy)')
        setNameHandler({
          handler: (name) => {
            if (name) {
              const copy = cloneDeep(item);
              let targetIx = destination.index
              if (source.droppableId == destination.droppableId && destination.index >= source.index) {
                targetIx++
              }
              setLocalOrganization(draft => {
                const targetLesson: Lesson = findItem(targetPath, draft!.courses) as Lesson

                targetLesson.contentItems.splice(targetIx, 0, copy)
                targetLesson.contentItems.forEach((p, i) => p.order = i)
                draft!.isSaving = true
                draft!.lib = toLocalLib(draft!.courses)
              })
              cloneContentItem(item.__typename, draggableId, destination.droppableId, targetIx, name).then()
            }
            setNameHandler(undefined)
          }
        })

      }
    } else {
      setLocalOrganization(draft => {
        debug('onDragEnd', originPath, targetPath)
        if (type == 'course') {
          const targetCourse: Course = findItem(targetPath, draft!.courses) as Course
          const sourceCourse: Course = findItem(originPath, draft!.courses) as Course

          const moved = sourceCourse.checkpoints.splice(source.index, 1)
          targetCourse.checkpoints.splice(destination.index, 0, ...moved)
          sourceCourse.checkpoints.forEach((p, i) => p.order = i)
          targetCourse.checkpoints.forEach((p, i) => p.order = i)
          draft!.isSaving = true
          draft!.lib = toLocalLib(draft!.courses)
          moveCheckpoint(source.droppableId, draggableId, destination.droppableId, destination.index).then()


        } else if (type == 'checkpoint') {
          const sourceCheckpoint: Checkpoint = findItem(originPath, draft!.courses) as Checkpoint
          const targetCheckpoint: Checkpoint = findItem(targetPath, draft!.courses) as Checkpoint
          const moved = sourceCheckpoint.lessons.splice(source.index, 1)
          targetCheckpoint.lessons.splice(destination.index, 0, ...moved)
          sourceCheckpoint.lessons.forEach((p, i) => p.order = i)
          targetCheckpoint.lessons.forEach((p, i) => p.order = i)
          draft!.lib = toLocalLib(draft!.courses)
          draft!.isSaving = true
          moveLesson(source.droppableId, draggableId, destination.droppableId, destination.index).then()

        } else if (type == 'lesson') {
          const sourceLesson: Lesson = findItem(originPath, draft!.courses) as Lesson
          const targetLesson: Lesson = findItem(targetPath, draft!.courses) as Lesson
          const moved = sourceLesson.contentItems.splice(source.index, 1)
          targetLesson.contentItems.splice(destination.index, 0, ...moved)
          sourceLesson.contentItems.forEach((p, i) => p.order = i)
          targetLesson.contentItems.forEach((p, i) => p.order = i)
          draft!.lib = toLocalLib(draft!.courses)
          draft!.isSaving = true
          moveContentItem(moved[0].__typename, source.droppableId, draggableId, destination.droppableId, destination.index).then()

        }
      })

    }

  }, [localOrganization, isAltPressed])

  //////////////////////////////////////////////////////////////////////
  ///                     Delete Handlers                            ///
  //////////////////////////////////////////////////////////////////////
  const deleteHandler = React.useCallback(async (item: Course | Checkpoint | Lesson | AnyContentItem, type?: CreateItemType) => {
    if ('checkpoints' in item) {
      setEntityType('course')
      setConfirmDeleteHandler({
        handler: (remove: boolean) => {
          if (remove) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            removeCourse(item.id).then()
          }
          setConfirmDeleteHandler(undefined)
        }
      })

    } else if ('lessons' in item) {
      setEntityType('checkpoint')
      setConfirmDeleteHandler({
        handler: (remove: boolean) => {
          if (remove) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            removeCheckpoint(item.courseId, item.id).then()
          }
          setConfirmDeleteHandler(undefined)
        }
      })
    } else if ('contentItems' in item) {
      setEntityType('lessons')
      setConfirmDeleteHandler({
        handler: (remove: boolean) => {
          if (remove) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            removeLesson(item.checkpointId, item.id).then()
          }
          setConfirmDeleteHandler(undefined)
        }
      })

    } else if (type) {
      setEntityType(type)
      const jrnyType = typeToTypeName(type)
      setConfirmDeleteHandler({
        handler: (remove: boolean) => {
          if (remove) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            removeContentItem(item.lessonId, jrnyType, item.id).then()
          }
          setConfirmDeleteHandler(undefined)
        }
      })

    }
  }, [localOrganization])

  //////////////////////////////////////////////////////////////////////
  ///                     Create Handlers                            ///
  //////////////////////////////////////////////////////////////////////
  const createHandler = React.useCallback((parent: AnyParentItem | undefined, order: number, type?: CreateItemType) => {
    debug('createHandler', parent)
    if (!parent) {
      setEntityType('course')
      setEntityName('New Course')
      setNameHandler({
        handler: (name: string | null) => {
          if (name) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            createCourse(localOrganization!.id, name).then()
          }
          setNameHandler(undefined)
        }
      })

    } else if ('checkpoints' in parent) {
      setEntityType('checkpoint')
      setEntityName('New Checkpoint')
      setNameHandler({
        handler: (name: string | null) => {
          if (name) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            createCheckpoint(parent.id, name, order).then()
          }
          setNameHandler(undefined)
        }
      })
    } else if ('lessons' in parent) {
      setEntityType('lesson')
      setEntityName('New Lesson')
      setNameHandler({
        handler: (name: string | null) => {
          if (name) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            createLesson(parent.id, name, order).then()
          }
          setNameHandler(undefined)
        }
      })

    } else if ('contentItems' in parent && type) {
      setEntityType(type)
      setEntityName('New ' + type)
      const jrnyType = typeToTypeName(type)
      setNameHandler({
        handler: (name: string | null) => {
          if (name) {
            setLocalOrganization(draft => {
              draft!.isSaving = true
            })
            createContentItem(jrnyType, parent.id, name, order, jrnyType == 'JrnyPrompt' ? type as 'prompt'|'challenge' : undefined).then()
          }
          setNameHandler(undefined)
        }
      })
    }

  }, [localOrganization])
  //////////////////////////////////////////////////////////////////////
  ///                     Duplicate Handlers                         ///
  //////////////////////////////////////////////////////////////////////
  const duplicateHandler = React.useCallback((item: AnyItem, order: number,) => {
    debug('duplicateHandler', item)
    switch(item.__typename) {
      case "JrnyCourse":
        setEntityType('course')
        setEntityName(item.name+' (copy)')
        setNameHandler({
          handler: (name: string | null) => {
            if (name) {
              setLocalOrganization(draft => {
                draft!.isSaving = true
              })
              cloneCourse(item.id, name).then()
            }
            setNameHandler(undefined)
          }
        })
        break;
      case "JrnyCheckpoint":
        setEntityType('checkpoint')
        setEntityName(item.name+' (copy)')
        setNameHandler({
          handler: (name: string | null) => {
            if (name) {
              setLocalOrganization(draft => {
                draft!.isSaving = true
              })
              cloneCheckpoint(item.id,  item.courseId, item.order+1, name).then()
            }
            setNameHandler(undefined)
          }
        })

        break;
      case "JrnyLesson":
        setEntityType('lesson')
        setEntityName(item.name+' (copy)')
        setNameHandler({
          handler: (name: string | null) => {
            if (name) {
              setLocalOrganization(draft => {
                draft!.isSaving = true
              })
              cloneLesson(item.id,  item.checkpointId, item.order+1, name).then()
            }
            setNameHandler(undefined)
          }
        })
        break;
      case "JrnyMedia":
      case "JrnyPrompt":
      case "JrnyResource":
        setEntityType('checkpoint')
        setEntityName(item.name+' (copy)')
        setNameHandler({
          handler: (name: string | null) => {
            if (name) {
              setLocalOrganization(draft => {
                draft!.isSaving = true
              })
              cloneContentItem(item.__typename, item.id,  item.lessonId, item.order+1, name).then()
            }
            setNameHandler(undefined)
          }
        })

    }
  }, [localOrganization])

  // Set the local state when it changes
  React.useEffect(() => {
    resetLocal()
  }, [currentOrganization])

  return (localOrganization ? <>

      <Paper sx={{p: 2, height: '100%', cursor: isDragging ? (isAltPressed ? 'copy' : 'move') : 'default'}}
             variant={'outlined'}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setIsDragging(true)}>
          <TreeView

            expanded={expanded}
            onNodeToggle={handleToggle}
            onNodeSelect={selectHandler}
            defaultCollapseIcon={<IconButton><ArrowDropDownIcon/></IconButton>}
            defaultExpandIcon={<IconButton><ArrowRightIcon/></IconButton>}
            defaultEndIcon={<div style={{width: 24}}/>}
            sx={{flexGrow: 1, maxWidth:'800px',  overflowY: 'auto'}}
          >
            {renderCourses(localOrganization.courses, localOrganization.isSaving, createHandler, deleteHandler, duplicateHandler)}
          </TreeView>
        </DragDropContext>
      </Paper>
      <NameDialog entityType={entityType} disabled={localOrganization.isSaving} handler={nameHandler?.handler}
                  name={entityName}/>
      <DeleteDialog entityType={entityType} disabled={localOrganization.isSaving}
                    handler={confirmDeleteHandler?.handler}/>
      <Fab color='primary' onClick={() => createHandler(undefined, 0)}
           sx={{position: 'fixed', bottom: 24, right: 24}}><IconAdd/></Fab>

    </>
    : null)
}
