import { generateDebug } from '@/utils';
import { Checkbox, DialogContent, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { CourseOccasion } from '../types';
import { set } from 'lodash';
import { Dayjs } from 'dayjs';
import Typography from '@/components/Typography';
import { Stack } from '@mui/system';
import { number } from 'lib0';
import { IGQLJrnyDateDurationInput } from '@/graphql-types';

const debug = generateDebug('CreateOccasionsDialog');

interface CreateOccasionsDialogProps {
  open: boolean
  protectedDates: string[]
  occasionsHandler: ((occasions: IGQLJrnyDateDurationInput[]) => Promise<any>);
  onClose: () => void
}

export default function CreateOccasionsDialog(props: CreateOccasionsDialogProps) {
  const [submitting, setSubmitting] = React.useState(false);
  const [date, setDate] = React.useState<Dayjs>(dayjs());
  const [duration, setDuration] = React.useState<number>(90);
  const [isRepeating, setIsRepeating] = React.useState(false);
  const [repetitions, setRepetitions] = React.useState(1);
  const [every, setEvery] = React.useState(1);
  React.useEffect(() => {
    if (props.open) {
      setIsRepeating(false)
      setDuration(90)
      setEvery(7)
      setRepetitions(5)
    }
  }, [props.open])
  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async () => {
    if (!date || !duration) {
      props.onClose();
    }
    setSubmitting(true);
    const occasions = [{ date: date!.format('YYYY-MM-DD'), duration }];
    if (isRepeating) {
      for (let i = 0; i < repetitions; i++) {
        const next = { date: date!.add((i + 1) * every, 'day').format('YYYY-MM-DD'), duration }
        if (!props.protectedDates.includes(next.date)) {
          occasions.push(next);

        }
      }
    }
    const result = await props.occasionsHandler!(occasions);
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return { [FORM_ERROR]: result.error.graphQLErrors[0].message };
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.open} onClose={submitting ? () => {
      } : handleClose}>
        <DialogContent>
          <DateCalendar
            shouldDisableDate={(date) => {
              const dateStr = date.format('YYYY-MM-DD');
              return props.protectedDates.includes(dateStr)
            }}
            value={date}
            onChange={(val) => { setDate(val) }} />
          <Stack spacing={1}>
            <Typography variant={'caption'}>Selected date: {date?.format('YYYY-MM-DD')}</Typography>
            <TextField
              type='number'
              label="Duration"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              inputProps={{ min: 1 }} />
            <FormGroup>
              <FormControlLabel control={<Checkbox value={isRepeating} onChange={() => setIsRepeating(val => !val)} />} label="Repeat" />
            </FormGroup>
            <Stack direction={'row'} spacing={1}>
              <TextField disabled={!isRepeating}
                type='number'
                label="X times"
                value={repetitions}
                onChange={(e) => setRepetitions(parseInt(e.target.value))}
                inputProps={{ min: 1 }} />
              <TextField disabled={!isRepeating}
                type='number'
                label="Every Y days"
                value={every}
                onChange={(e) => setEvery(parseInt(e.target.value))}
                inputProps={{ min: 1 }} />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={doSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
