import {PromptInput} from '@journey-lti-tool/common';
import {Box, FormControl, InputLabel, OutlinedInput, styled} from '@mui/material';
import {EditorContent, useEditor} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import * as React from 'react';
import {MenuBar} from "@components/MenuBar";

const EditorWrapper = styled(FormControl, {
  name: 'MuiTextField',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({theme}) => //language=scss prefix=.root{ suffix=}
  `
    & .ProseMirror span[data-prompt-ix] {
      min-width: 5em;
      background: ${theme.palette.secondary.light};
      border: #f00;
      height: 1.2em;
      line-height: 1.2em;
      border-radius: 1.2em;
      display: inline-block;
      margin: 0 .1em -0.1em;
      text-align: center;
      font-size: .9em;

      &:after {
        content: attr(data-prompt-ix);
      }
    }
  `)

export interface MediaDescriptionEditorProps {
  onChange: (content: string) => void
  content: string
}
const emptyArray: PromptInput[] = []
export default function MediaDescriptionEditor(props: MediaDescriptionEditorProps) {


  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    onUpdate({editor}) {
      props.onChange(editor.getHTML())
    },
    content: props.content,
  }, [props.onChange])
  const content = React.useMemo(() => React.forwardRef((pr, ref) => (<Box sx={{p: 2}}>
    <MenuBar editor={editor} inputs={emptyArray}/>
    <EditorContent editor={editor}/>
  </Box>)), [editor])
  return editor ? (<>
        <EditorWrapper variant='outlined' fullWidth>
          <InputLabel htmlFor='text-editor' id='text-editor-label' shrink>
            Description
          </InputLabel>
          {/* TODO: Create a proper outlined version of this*/}
          {/* @ts-ignore */}
          <OutlinedInput notched={true} label='Description' id='text-editor' inputComponent={content}/>
        </EditorWrapper>

      </>
    )
    : null
}
