import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';

export type DraggableListProps<T extends {id: string}> = {
  items: T[];
  onDragEnd: OnDragEndResponder;
  children: (item: T, index: number, provided: DraggableProvided, snapshot: DraggableStateSnapshot) => React.ReactElement

};
const typedMemo: <T>(c: T) => T = React.memo;
const DraggableList = <T extends {id: string}>(props: DraggableListProps<T>) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.items.map((item, index) => (
              <Draggable draggableId={item.id} index={index} key={item.id}>
                {(provided, snapshot) => props.children(item, index, provided, snapshot)
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
DraggableList.displayName='DraggableList'

export default typedMemo(DraggableList);
