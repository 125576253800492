import {AnyContentItem, Checkpoint, Course, CreateItemType, Lesson, LocalLib} from "@/pages/structure/types";
import {generateDebug} from "@/utils";

export const debug = generateDebug('structure-view')
// finds all the parents of an item
export const findPath = (id: string, lib: LocalLib): string[] => {
  const source = lib[id]
  switch (source.__typename) {
    case "JrnyCourse":
      return [id]
    case "JrnyCheckpoint":
      return [source.courseId, id]
    case "JrnyLesson":
      return [...findPath(source.checkpointId, lib), id]
    default:
      return []
  }
}
export const findItem = (path: string[], courses: Course[]): Course | Checkpoint | Lesson | undefined => {
  const [courseId, checkpointId, lessonId] = path
  const course = courses.find(c => c.id === courseId)
  if (!checkpointId) {
    return course
  }
  const checkpoint = course?.checkpoints.find(cp => cp.id === checkpointId)
  if (!lessonId) {
    return checkpoint
  }
  return checkpoint?.lessons.find(l => l.id === lessonId)
}
export const toLocalLib = (courses: Course[]): LocalLib => {
  const lib: LocalLib = {}
  courses.forEach(c => {
    lib[c.id] = c
    c.checkpoints.forEach(cp => {
      lib[cp.id] = cp
      cp.lessons.forEach(l => {
        lib[l.id] = l
        l.contentItems.forEach(ci => {
          lib[ci.id] = ci
        })
      })
    })
  })
  return lib
}
// sets temporary ids to not confuse the tree view
export const setCopyIds = (entity: Checkpoint | Lesson | AnyContentItem) => {
  debug(`setting clone id for ${entity.__typename} ${entity.id}`);
  entity.id = entity.id + '-clone'
  if ('lessons' in entity) {
    entity.lessons.forEach(setCopyIds)
  } else if ('contentItems' in entity) {
    entity.contentItems.forEach(setCopyIds)
  }
}

export const typeToTypeName = (type: CreateItemType) => {
  switch (type) {
    case 'challenge':
    case "prompt":
      return 'JrnyPrompt'
    case "resource":
      return 'JrnyResource'
    case "media":
      return 'JrnyMedia'
    default:
      throw new  Error(`Unknown type: "${type}"`)
  }
}

export const typenameToCreateItemType = (type: string, category?:'prompt'|'challenge'): CreateItemType => {
  switch (type) {
    case 'JrnyPrompt':
      return category||'prompt'
    case 'JrnyResource':
      return 'resource'
    case 'JrnyMedia':
      return 'media'
    default:
      throw new  Error(`Unknown type: "${type}"`)
  }

}
