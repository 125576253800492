import {useSnackbar} from "notistack";
import * as React from "react";
import {MinimalFileObject} from "@components/file-object/types";
import {useRenameFileObjectMutation} from "@graphql/uploads-hook";
import {IGQLJrnyRenameFileObjectInput} from "@/graphql-types";

export const useRenameHandler = (organizationId?: string) => {
  const {enqueueSnackbar} = useSnackbar()
  const [selected, setSelected] = React.useState<MinimalFileObject>()
  const [{fetching: renaming}, doRename] = useRenameFileObjectMutation()
  const handleRename = React.useCallback(async (item: MinimalFileObject, name: string) => {
    if( organizationId) {
      const toSave: IGQLJrnyRenameFileObjectInput = {id: item.id, name, organizationId}
      const result = await doRename({input: toSave})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])

  return {
    selected,
    setSelected,
    renaming,
    handleRename,
  }
}
