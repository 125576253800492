import {useSnackbar} from "notistack";
import * as React from "react";
import {FileFolderTree, MinimalFileObject} from "@components/file-object/types";
import {
  IGQLJrnyCreateFolderInput, IGQLJrnyDeleteFileInput,
  IGQLJrnyDeleteFolderInput, IGQLJrnyMoveFileInput,
  IGQLJrnyMoveFolderInput,
  IGQLJrnyRenameFolderInput
} from "@/graphql-types";
import {
  useCreateFolderMutation, useDeleteFileMutation,
  useDeleteFolderMutation, useMoveFileMutation,
  useMoveFolderMutation,
  useRenameFolderMutation
} from "@graphql/organizations-hook";
import {generateDebug} from "@/utils";
const debug = generateDebug('use-folder-utils')


export const useFolderUtils = (organizationId?: string) => {
  debug(organizationId)
  const {enqueueSnackbar} = useSnackbar()
  const [{fetching: renaming}, doRename] = useRenameFolderMutation()
  const [{fetching: creating}, doCreate] = useCreateFolderMutation()
  const [{fetching: moving}, doMove] = useMoveFolderMutation()
  const [{fetching: movingFile}, doMoveFile] = useMoveFileMutation()
  const [{fetching: deletingFile}, doDeleteFile] = useDeleteFileMutation()
  const [{fetching: deleting}, doDelete] = useDeleteFolderMutation()
  const inProgress = React.useMemo(() => renaming || creating || moving || deleting || movingFile, [renaming, creating, moving, deleting, movingFile])

  const handleRename = React.useCallback(async (item: FileFolderTree, name: string) => {
    if( organizationId) {
      const toSave: IGQLJrnyRenameFolderInput = {id: item.id, name, organizationId}
      const result = await doRename({input: toSave} )
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('Folder renamed', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
    }
    return true
  }, [organizationId])

  const handleCreate = React.useCallback(async (parent: FileFolderTree, name: string) => {
    if( organizationId) {
      const toCreate: IGQLJrnyCreateFolderInput = {parentId: parent.id, name, organizationId}
      const result = await doCreate({input: toCreate})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('Folder created', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])

  const handleMove = React.useCallback(async (item: FileFolderTree, target: FileFolderTree) => {
    if( organizationId) {
      const toMove: IGQLJrnyMoveFolderInput = {id:item.id, sourceFolderId: item.parent!.id, targetFolderId: target.id, organizationId}
      const result = await doMove({input: toMove})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('Folder moved', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])
  const handleFileMove = React.useCallback(async (item: MinimalFileObject, target: FileFolderTree) => {
    if( organizationId) {
      const toMove: IGQLJrnyMoveFileInput = {id:item.id, sourceFolderId: item.folderId, targetFolderId: target.id, organizationId}
      const result = await doMoveFile({input: toMove})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('File moved', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])

  const handleDelete = React.useCallback(async (item: FileFolderTree) => {
    if( organizationId) {
      const toDelete: IGQLJrnyDeleteFolderInput = {id:item.id, organizationId}
      const result = await doDelete({input: toDelete})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('Folder deleted', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])

  const handleDeleteFile = React.useCallback(async (item: MinimalFileObject) => {
    if( organizationId) {
      const toDelete: IGQLJrnyDeleteFileInput = {id:item.id, organizationId}
      const result = await doDeleteFile({input: toDelete})
      if (result.error) {
        enqueueSnackbar(result.error.message, {variant: 'error'})
        return false
      } else {
        enqueueSnackbar('File deleted', {variant: 'success'})
        return true
      }
    } else {
      enqueueSnackbar('Organization mismatch', {variant: 'error'})
      return true
    }
  }, [organizationId])

  return {
    inProgress,
    handleRename,
    handleCreate,
    handleDelete,
    handleMove,
    handleFileMove,
    handleDeleteFile,
  }
}
