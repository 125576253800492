import { IGQLCreateOrganizationMutationVariables } from '@graphql/organizations-hook';
import { generateDebug } from '@/utils';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import { required } from '@components/form/validation';
import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import {IconAdd} from "@/icons";

const debug = generateDebug('CreateOrganizationDialog');

interface CreateOrganizationDialogProps {
  createOrganization: (variables: IGQLCreateOrganizationMutationVariables) => Promise<any>;
}

export default function CreateOrganizationDialog(props: CreateOrganizationDialogProps) {
  const [open, setOpen] = React.useState(false);

  const validate = (values: { [index: string]: string }) => {
    const errors = required(['name'], values);
    return errors;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (values: { name: string }) => {
    debug('handleSubmit', values);
    const result = await props.createOrganization({ input: { name: values.name } });
    debug('Submit result', result);
    if (result.error) {
      return { [FORM_ERROR]: result.error.graphQLErrors[0].message };
    }
    setOpen(false);
  };

  return (
    <div>
      <Fab color='primary' onClick={handleClickOpen} sx={{ position: 'fixed', bottom: 24, right: 24 }}><IconAdd /></Fab>
      <Form
        onSubmit={handleSubmit}
        subscription={{ submitting: true }}
        validate={validate}
      >
        {({ handleSubmit: handleSubmit2, submitting, form }) => (<>
          <Dialog open={open} onClose={submitting?()=>{}:handleClose}>
            <DialogTitle>Create new organization</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter name of organization. Must be unique
              </DialogContentText>
              <Box component='form' onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
                <Field
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label='Organization name'
                  margin='dense'
                  name='name'
                  type='text'
                  required
                  size='normal'
                />
                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
              <Button disabled={submitting} onClick={form.submit}>Create</Button>
            </DialogActions>
          </Dialog>
        </>)}
      </Form>
    </div>
  );
}
