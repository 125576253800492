import {generateDebug} from '@/utils';
import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const debug = generateDebug('NameDialog');

interface NameDialogProps {
  entityType: string
  name?: string
  disabled: boolean
  handler?: ((name: string|null) => void)
  namesInUse?: string[],
  allowCurrentName?: boolean
}

export function NameDialog({entityType, disabled, handler, name, namesInUse, allowCurrentName = true}: NameDialogProps) {
  const [newName, setNewName] = React.useState(name || '')
  React.useEffect(() => setNewName(name||''), [name, handler])
  const handleClose = React.useCallback(() => {
    handler?.(null)
  }, [handler])
  const message = React.useMemo(() => {
    const n = newName.trim()
    if(!n) {
      return null
    }
    if( namesInUse && (namesInUse.includes(n) || (!allowCurrentName && n === name))) {
      return 'Name already taken'
    }
  }, [newName, name])
  return handler ? (
      <Dialog open onClose={disabled ? () => {} : handleClose}
      maxWidth={'sm'}
      fullWidth={true}>


        <DialogTitle>Name {entityType}</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter name for {entityType}.{namesInUse?' Must be unique':''}</DialogContentText>
          <Box sx={{mt: 6}}>
            <TextField disabled={disabled}
                       autoFocus
                       fullWidth
                       label={entityType+' name'}
                       margin='dense'
                       name='name'
                       type='text'
                       required
                       value={newName}
                       error={!!message}
                       helperText={message}
                       onChange={(evt) => setNewName(evt.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={handleClose}>Cancel</Button>
          <Button disabled={disabled || !!message || !newName.trim().length} onClick={() => handler(newName)}>OK</Button>
        </DialogActions>
      </Dialog>)
    : null
}
