import debugFn from 'debug';

import {Logo} from "@/icons";
import * as React from "react";
import {styled} from "@mui/material";

export const LogoWrapper = styled('div')({
  position: 'relative',
  '& .mode': {
    position: 'absolute',
    right: 0,
    fontWeight: 'bolder',
    fontSize: '1em',
    bottom: '-0.3em',
    fontFamily: 'system-ui',
    transform: 'translateX(40%) rotateZ(-11deg)',
  }
})
export const getAppLogo = () => {
  switch (import.meta.env.MODE) {
    case 'development':
      return (<LogoWrapper><Logo/>
        <div className={'mode'} style={{color: '#0f0'}}>DEV</div>
      </LogoWrapper>)
    case 'stage':
      return (<LogoWrapper><Logo/>
        <div className={'mode'} style={{color: 'yellow'}}>STAGE</div>
      </LogoWrapper>)
    default:
      return <Logo/>
  }
}

export const disableEmotionWarnings = () => {
  // if (process.env.NODE_ENV == 'development') {
  //   return;
  // }
  const log = console.error.bind(console);
  console.error = (...args) => {
    if (
      typeof args[0] === 'string' &&
      args[0].includes('The pseudo class') &&
      args[0].includes('is potentially unsafe when doing server-side rendering. Try changing it to')
    ) {
      return;
    }
    log(...args);
  };
};

/**
 * Keeps track of how many tabs of the app are open
 * and which tab this is
 */
export const addTabTracking = () => {
  let idBase = window.localStorage.getItem('id-base')
  if( !idBase) {
    idBase = 'randomString:'
    window.localStorage.setItem('id-base', idBase)
  }
  let id = 0
  let i = 0
  while(id==0) {
    i++
    if( !window.localStorage.getItem(idBase+i)) {
      window.localStorage.setItem(idBase+i, '1')
      window.sessionStorage.setItem('id', idBase+i)
      id=i
      window.sessionStorage.setItem('id-ix', ''+id)
      console.log('Loaded as tab:'+id)
    }
  }
  window.onbeforeunload = () => {
    console.log('Unloading')
    window.localStorage.removeItem(''+idBase+id)
  }

}

export const generateDebug = (name: string) => {
  return debugFn('journey-admin:'+name)
}

export const byOrder = <T extends { order: number }>(a: T, b: T) => a.order > b.order ? 1 : (b.order > a.order ? -1 : 0)
