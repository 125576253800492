import { Chip, Container, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import * as React from 'react';
import CreateOrganizationDialog from './components/CreateOrganizationDialog';
import { useCreateOrganizationMutation, useOrganizationsQuery } from '@graphql/organizations-hook';
import { ShiroCan } from '@/auth/components/Can';
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {IconGoTo} from "@/icons";


interface OrganizationListViewProps {

}

export default function OrganizationListView(props: OrganizationListViewProps) {
  const [organizations] = useOrganizationsQuery();
  const [, createOrganization] = useCreateOrganizationMutation();
  const { currentOrganization, setCurrentOrganization } = useCurrentOrganization();
  return (<Container>
    <Paper>

      <List>
        {organizations.data?.jrnyOrganizations.map(organization => (<ListItem secondaryAction={(<>
          {currentOrganization && currentOrganization.id == organization.id && (<Chip size='small' label='ACTIVE' color='primary' />)}
          <IconButton onClick={() => setCurrentOrganization(organization.id)} edge='end'><IconGoTo /></IconButton>
        </>)
        } key={organization.id}
        >
          <ListItemText secondary={organization.id} primary={organization.name} />
        </ListItem>))}
      </List>
    </Paper>
    <ShiroCan permission='journey-admin:organization:create'><CreateOrganizationDialog createOrganization={createOrganization} /></ShiroCan>
  </Container>);
}
