import { styled } from '@mui/material';
import * as React from 'react'

interface AspectRatioProps {
  ratio: string
  objectFit: string
  minHeight?: number
  maxHeight?: number
  children?: React.ReactNode;
}

const AspectRatioRoot = styled('div', {
  name: 'AspectRatio',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})<{ ownerState: AspectRatioProps }>(({ ownerState }) => {
  const minHeight =
    typeof ownerState.minHeight === 'number' ? `${ownerState.minHeight}px` : ownerState.minHeight;
  const maxHeight =
    typeof ownerState.maxHeight === 'number' ? `${ownerState.maxHeight}px` : ownerState.maxHeight;
  return {
    // a context variable for any child component
    '--AspectRatio-paddingBottom':
      minHeight || maxHeight
        ? `clamp(${minHeight || '0px'}, calc(100% / (${ownerState.ratio})), ${
          maxHeight || '9999px'
        })`
        : `calc(100% / (${ownerState.ratio}))`,
    flexDirection: 'column',
    margin: 'var(--AspectRatio-margin)',
  };
});
const AspectRatioContent = styled('div', {
  name: 'AspectRatio',
  slot: 'Content',
  overridesResolver: (props, styles) => styles.content,
})<{ ownerState: AspectRatioProps }>(({ theme, ownerState }) => [
  {
    flex: 1,
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingBottom: 'var(--AspectRatio-paddingBottom)',
    // use data-attribute instead of :first-child to support zero config SSR (emotion)
    // use nested selector for integrating with nextjs image `fill` layout (spans are inserted on top of the img)
    '& [data-first-child]': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: ownerState.objectFit,
      margin: 0,
      padding: 0,
      '& > img': {
        // support art-direction that uses <picture><img /></picture>
        width: '100%',
        height: '100%',
        objectFit: ownerState.objectFit,
      },
    },
  },
]);
export default function AspectRatio(props: AspectRatioProps) {
  return (<AspectRatioRoot ownerState={props}>
    <AspectRatioContent ownerState={props} >
      {React.Children.map(props.children, (child, index) =>
        index === 0 && React.isValidElement(child)
          ? React.cloneElement(child, { 'data-first-child': '' } as Record<string, string>)
          : child,
      )}
    </AspectRatioContent>
  </AspectRatioRoot>)
}
