import {generateDebug} from '@/utils';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import {email, required} from '@components/form/validation';
import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FORM_ERROR} from 'final-form';
import * as React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {IconAdd} from "@/icons";
import {IGQLCreateParticipationMutationVariables} from "@graphql/participations-hook";
import {useForm} from 'react-hook-form';
import {FormTextField} from "@components/form/FormTextField";
import { DevTool } from '@hookform/devtools';
import {useEffect} from "react";

const debug = generateDebug('AddCourseUserDialog');

interface AddCourseUserDialogProps {
  addUser: (variables: IGQLCreateParticipationMutationVariables) => Promise<any>;
  courseId: string
  role?: string
  onClose: () => void
}

type Values = Omit<IGQLCreateParticipationMutationVariables['input'], 'courseId'>
type FormValues = { email: string, name: string }
export default function AddCourseUserDialog(props: AddCourseUserDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const {handleSubmit, control, reset} = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      name: ''
    }
  });

  useEffect(() => {
    if( !props.role ) {
      reset()
    }
  }, [props.role])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async (values: FormValues) => {
    debug('handleSubmit', values);
    setSubmitting(true);
    const result = await props.addUser({
      input: {
        courseId: props.courseId,
        name: values.name.trim() || null,
        email: values.email.trim(),
        role: props.role!

      }
    });
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message};
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.role} onClose={submitting ? () => {
      } : handleClose}>
        <DialogTitle>Add {props.role||'user'} to course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email is required. Name is optional. When user logs in their name will be set depending on what they use with their id-provider.
          </DialogContentText>
          <Box sx={{mt: 6}}>
            <FormTextField
              control={control}
              autoFocus
              rules={{
                required: 'Email is required',
                validate:  val => email(val as string)||true
              }}

              disabled={submitting}
              fullWidth
              label='Email'
              margin='dense'
              name='email'
            />
            <FormTextField
              control={control}
              disabled={submitting}
              fullWidth
              label='Name (optional)'
              margin='dense'
              name='name'
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={handleSubmit(doSubmit)}>Add</Button>
          {/*<DevTool control={control}/>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}
