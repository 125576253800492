import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { InvitationFieldsFragmentDoc } from './fragment-Invitation-hook';
import { CurrentUserFieldsFragmentDoc } from './fragment-CurrentUser-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLOrganizationInvitationsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLOrganizationInvitationsQuery = { __typename: 'Query', jrnyOrganizationInvitations: Array<{ __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType }> };

export type IGQLInviteToOrganizationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyInviteUserToOrganizationInput;
}>;


export type IGQLInviteToOrganizationMutation = { __typename: 'Mutation', jrnyInviteUserToOrganization: { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType } };

export type IGQLResendInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLResendInvitationMutation = { __typename: 'Mutation', jrnyResendInvitation: { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType } };

export type IGQLRevokeInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLRevokeInvitationMutation = { __typename: 'Mutation', jrnyRevokeInvitation: { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType } };

export type IGQLUserInvitationsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLUserInvitationsQuery = { __typename: 'Query', jrnyUserInvitations: Array<{ __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType }> };

export type IGQLAcceptInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLAcceptInvitationMutation = { __typename: 'Mutation', jrnyAcceptInvitation: { __typename: 'JrnyAcceptInvitation', invitation: { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType }, loginResult: { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } } } };

export type IGQLRejectInvitationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLRejectInvitationMutation = { __typename: 'Mutation', jrnyRejectInvitation: { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType } };

export type IGQLAcceptSiteInvitationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyAcceptSiteInvitationInput;
}>;


export type IGQLAcceptSiteInvitationMutation = { __typename: 'Mutation', jrnyAcceptSiteInvitation: { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } } };


export const OrganizationInvitationsDocument = gql`
    query organizationInvitations($id: ID!) {
  jrnyOrganizationInvitations(organizationId: $id) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useOrganizationInvitationsQuery(options: Omit<Urql.UseQueryArgs<IGQLOrganizationInvitationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationInvitationsQuery, IGQLOrganizationInvitationsQueryVariables>({ query: OrganizationInvitationsDocument, ...options });
};
export const InviteToOrganizationDocument = gql`
    mutation inviteToOrganization($input: JrnyInviteUserToOrganizationInput!) {
  jrnyInviteUserToOrganization(input: $input) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useInviteToOrganizationMutation() {
  return Urql.useMutation<IGQLInviteToOrganizationMutation, IGQLInviteToOrganizationMutationVariables>(InviteToOrganizationDocument);
};
export const ResendInvitationDocument = gql`
    mutation resendInvitation($id: ID!) {
  jrnyResendInvitation(invitationId: $id) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useResendInvitationMutation() {
  return Urql.useMutation<IGQLResendInvitationMutation, IGQLResendInvitationMutationVariables>(ResendInvitationDocument);
};
export const RevokeInvitationDocument = gql`
    mutation revokeInvitation($id: ID!) {
  jrnyRevokeInvitation(invitationId: $id) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useRevokeInvitationMutation() {
  return Urql.useMutation<IGQLRevokeInvitationMutation, IGQLRevokeInvitationMutationVariables>(RevokeInvitationDocument);
};
export const UserInvitationsDocument = gql`
    query userInvitations($id: ID!) {
  jrnyUserInvitations(userId: $id) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useUserInvitationsQuery(options: Omit<Urql.UseQueryArgs<IGQLUserInvitationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLUserInvitationsQuery, IGQLUserInvitationsQueryVariables>({ query: UserInvitationsDocument, ...options });
};
export const AcceptInvitationDocument = gql`
    mutation acceptInvitation($id: ID!) {
  jrnyAcceptInvitation(invitationId: $id) {
    invitation {
      ...InvitationFields
    }
    loginResult {
      ...CurrentUserFields
    }
  }
}
    ${InvitationFieldsFragmentDoc}
${CurrentUserFieldsFragmentDoc}`;

export function useAcceptInvitationMutation() {
  return Urql.useMutation<IGQLAcceptInvitationMutation, IGQLAcceptInvitationMutationVariables>(AcceptInvitationDocument);
};
export const RejectInvitationDocument = gql`
    mutation rejectInvitation($id: ID!) {
  jrnyRejectInvitation(invitationId: $id) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

export function useRejectInvitationMutation() {
  return Urql.useMutation<IGQLRejectInvitationMutation, IGQLRejectInvitationMutationVariables>(RejectInvitationDocument);
};
export const AcceptSiteInvitationDocument = gql`
    mutation acceptSiteInvitation($input: JrnyAcceptSiteInvitationInput!) {
  jrnyAcceptSiteInvitation(input: $input) {
    ...CurrentUserFields
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

export function useAcceptSiteInvitationMutation() {
  return Urql.useMutation<IGQLAcceptSiteInvitationMutation, IGQLAcceptSiteInvitationMutationVariables>(AcceptSiteInvitationDocument);
};