import * as Types from '../../graphql-types';

import { gql } from 'urql';
export type IGQLInvitationFieldsFragment = { __typename: 'JrnyInvitation', id: string, name: string, message: string, email: string, status: Types.IGQLInvitationStatus, validTo: any, type: Types.IGQLInvitationType };

export const InvitationFieldsFragmentDoc = gql`
    fragment InvitationFields on JrnyInvitation {
  id
  name
  message
  email
  status
  validTo
  type
}
    `;