import {TopNavigation} from '@/top-navigation';
import Button from '@components/Button';
import Box from '@mui/material/Box';
import * as React from 'react';
import AppBar from './AppBar';
import Link from './Link';
import Toolbar from './Toolbar';
import {useCurrentUser} from "@/context/CurrentUserProvider";

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppAppBar() {
  const {currentUser, signOut} = useCurrentUser()
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{justifyContent: 'space-between'}}>
          <Box sx={{flex: 1}}/>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            to="/"
            sx={{fontSize: 24}}
          >
            {'home'}
          </Link>
          <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
            {!currentUser && (
              <>
                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  to={TopNavigation.SignIn}
                  sx={rightLink}
                >
                  {'Sign In'}
                </Link>
              </>
            ) || (
              <Button
                color="inherit"
                underline="none"
                sx={rightLink}
                onClick={signOut}
              >
                {'Logout'}
              </Button>

            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar/>
    </div>
  );
}

export default AppAppBar;
