import { keys, mutationUpdates } from "@/graphql-client/GqlClientProvider";
import { generateDebug } from "@/utils";
import {
  CourseDocument,
  IGQLCloneCourseMutation,
  IGQLCloneCourseMutationVariables,
  IGQLCreateCourseMutation,
  IGQLCreateCourseMutationVariables,
  IGQLDeleteCourseMutation,
  IGQLDeleteCourseMutationVariables,
  IGQLUpdateCourseMutation,
  IGQLUpdateCourseMutationVariables,
} from "@graphql/courses-hook";
import {
  IGQLInviteToOrganizationMutation,
  IGQLInviteToOrganizationMutationVariables,
} from "@graphql/invitations-hook";
import {
  IGQLCreateFolderMutation,
  IGQLCreateFolderMutationVariables,
  IGQLCreateOrganizationMutation,
  IGQLDeleteFileMutation,
  IGQLDeleteFileMutationVariables,
  IGQLDeleteFolderMutation,
  IGQLDeleteFolderMutationVariables,
} from "@graphql/organizations-hook";
import {
  IGQLConfirmUploadsSimpleMutation,
  IGQLConfirmUploadsSimpleMutationVariables,
} from "@graphql/uploads-hook";
import {
  IGQLClonePerkMutation,
  IGQLClonePerkMutationVariables,
  IGQLCreatePerkMutation,
  IGQLCreatePerkMutationVariables,
} from "./hooks/perks-hook";
import {
  IGQLRemoveParticipationMutation,
  IGQLCreateParticipationMutation,
  IGQLCreateParticipationMutationVariables,
} from "@graphql/participations-hook";
import {
  IGQLCreateMultipleParticipationsMutation,
  IGQLCreateMultipleParticipationsMutationVariables,
} from "@graphql/participations-hook";
import { IGQLJrnyParticipation } from "@/graphql-types";
import { IGQLRemoveParticipationMutationVariables } from "@graphql/participations-hook";
import {
  IGQLCreateCourseOccasionMutation,
  IGQLCreateCourseOccasionMutationVariables,
  IGQLCreateCourseOccasionsMutation,
  IGQLCreateCourseOccasionsMutationVariables,
  IGQLRemoveCourseOccasionMutation,
  IGQLRemoveCourseOccasionMutationVariables,
} from "./hooks/course-occasions-hook";

const debug = generateDebug("mutation-updates");

// Coordinates is a scalar
keys.JrnyCoordinates = (data) => null;
keys.JrnyParticipation = (data: IGQLJrnyParticipation) =>
  `${data.courseId}_${data.userId}`;

mutationUpdates.jrnyInviteUserToOrganization = (
  result: IGQLInviteToOrganizationMutation,
  args: IGQLInviteToOrganizationMutationVariables,
  cache,
  _
) => {
  const list = cache.resolve("Query", "jrnyOrganizationInvitations", {
    organizationId: args.input.organizationId,
  });
  const key = cache.keyOfEntity(result.jrnyInviteUserToOrganization);
  debug("list %o %o", list, key);
  if (key && Array.isArray(list)) {
    if (!list.includes(key as any)) {
      list.push(result.jrnyInviteUserToOrganization as any);
      cache.link(
        "Query",
        "jrnyOrganizationInvitations",
        { organizationId: args.input.organizationId },
        list as any
      );
    }
  }
};

mutationUpdates.jrnyCreateOrganization = (
  result: IGQLCreateOrganizationMutation,
  _args,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyOrganizations");
  if (Array.isArray(list)) {
    list.push(result.jrnyCreateOrganization as any);
    cache.link("Query", "jrnyOrganizations", list as any);
  }
};

mutationUpdates.jrnyCreateCourse = (
  result: IGQLCreateCourseMutation,
  args: IGQLCreateCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: args.input.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    list.push(result.jrnyCreateCourse as any);
    cache.link(entity, "courses", list as any);
  }
};
mutationUpdates.jrnyCreateFolder = (
  result: IGQLCreateFolderMutation,
  args: IGQLCreateFolderMutationVariables,
  cache,
  _info
) => {
  const organizationId = args.input.organizationId;
  const list = cache.resolve("Query", "jrnyOrganizationFolders", {
    input: { id: organizationId },
  });

  if (Array.isArray(list)) {
    list.push(result.jrnyCreateFolder as any);
    cache.link(
      "Query",
      "jrnyOrganizationFolders",
      { input: { id: organizationId } },
      list as any
    );
  }
};
mutationUpdates.jrnyCreateParticipation = (
  result: IGQLCreateParticipationMutation,
  args: IGQLCreateParticipationMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  debug("jrnyCreateParticipation args: %o", args);
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  const id = `JrnyParticipation:${args.input.courseId}_${result.jrnyCreateParticipation.userId}`;
  if (Array.isArray(list)) {
    if (!list.includes(id as any)) {
      list.push(result.jrnyCreateParticipation as any);
      cache.link(
        "Query",
        "jrnyParticipationsForCourse",
        { courseId },
        list as any
      );
    }
  }
};
mutationUpdates.jrnyCreateMultipleParticipations = (
  result: IGQLCreateMultipleParticipationsMutation,
  args: IGQLCreateMultipleParticipationsMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  if (Array.isArray(list)) {
    const participations = result.jrnyCreateMultipleParticipations;
    for (const p of participations) {
      const id = `JrnyParticipation:${p.courseId}_${p.userId}`;
      if (!list.includes(id as any)) {
        list.push(p);
      }
    }
    cache.link(
      "Query",
      "jrnyParticipationsForCourse",
      { courseId },
      list as any
    );
  }
};

mutationUpdates.jrnyRemoveParticipation = (
  result: IGQLRemoveParticipationMutation,
  args: IGQLRemoveParticipationMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  const id = `JrnyParticipation:${result.jrnyRemoveParticipation.courseId}_${result.jrnyRemoveParticipation.userId}`;
  if (Array.isArray(list)) {
    cache.link(
      "Query",
      "jrnyParticipationsForCourse",
      { courseId },
      (list as string[]).filter((i) => i != id)
    );
  }
};

mutationUpdates.jrnyDeleteFolder = (
  result: IGQLDeleteFolderMutation,
  args: IGQLDeleteFolderMutationVariables,
  cache,
  _info
) => {
  const organizationId = args.input.organizationId;
  const list = cache.resolve("Query", "jrnyOrganizationFolders", {
    input: { id: organizationId },
  });
  if (Array.isArray(list)) {
    const id = "FileFolder:" + result.jrnyDeleteFolder.id;
    cache.link(
      "Query",
      "jrnyOrganizationFolders",
      { input: { id: organizationId } },
      (list as string[]).filter((i) => i != id)
    );
  }
};
mutationUpdates.jrnyDeleteFile = (
  result: IGQLDeleteFileMutation,
  args: IGQLDeleteFileMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "FileFolder",
    id: result.jrnyDeleteFile.folderId,
  };

  const list = cache.resolve(entity, "fileObjects");
  if (Array.isArray(list)) {
    const id = "FileObject:" + result.jrnyDeleteFile.id;
    cache.link(
      entity,
      "fileObjects",
      (list as string[]).filter((i) => i != id)
    );
  }
};

mutationUpdates.jrnyCloneCourse = (
  result: IGQLCloneCourseMutation,
  args: IGQLCloneCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: result.jrnyCloneCourse.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    list.push(result.jrnyCloneCourse as any);
    cache.link(entity, "courses", list as any);
  }
};
mutationUpdates.jrnyRemoveCourse = (
  result: IGQLDeleteCourseMutation,
  args: IGQLDeleteCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: result.jrnyRemoveCourse.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    const id = "JrnyCourse:" + result.jrnyRemoveCourse.id;
    cache.link(
      entity,
      "courses",
      (list as string[]).filter((i) => i != id)
    );
  }
};

mutationUpdates.jrnyCreatePerk = (
  result: IGQLCreatePerkMutation,
  args: IGQLCreatePerkMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyPerks", {
    organizationId: args.input.organizationId,
  });
  if (Array.isArray(list)) {
    list.push(result.jrnyCreatePerk as any);
    cache.link(
      "Query",
      "jrnyPerks",
      { organizationId: args.input.organizationId },
      list as any
    );
  }
};
mutationUpdates.jrnyClonePerk = (
  result: IGQLClonePerkMutation,
  args: IGQLClonePerkMutationVariables,
  cache,
  _info
) => {
  const organizationId = result.jrnyClonePerk.organizationId;
  const list = cache.resolve("Query", "jrnyPerks", { organizationId });
  if (Array.isArray(list)) {
    list.push(result.jrnyClonePerk as any);
    cache.link("Query", "jrnyPerks", { organizationId }, list as any);
  }
};

mutationUpdates.jrnyUpdateCourse = (
  result: IGQLUpdateCourseMutation,
  args: IGQLUpdateCourseMutationVariables,
  cache,
  _info
) => {
  debug(result.jrnyUpdateCourse);
  cache.updateQuery({ query: CourseDocument }, () => {
    return result.jrnyUpdateCourse;
  });
};

mutationUpdates.uploadsConfirmSimple = (
  result: IGQLConfirmUploadsSimpleMutation,
  args: IGQLConfirmUploadsSimpleMutationVariables,
  cache,
  _info
) => {
  debug(result.uploadsConfirmSimple);
  const entity = {
    __typename: "FileFolder",
    id: result.uploadsConfirmSimple[0].folderId,
  };

  const list = cache.resolve(entity, "fileObjects");
  if (Array.isArray(list)) {
    list.push(...(result.uploadsConfirmSimple as any));
    cache.link(entity, "fileObjects", list as any);
  }
};

mutationUpdates.jrnyCreateCourseOccasions = (
  result: IGQLCreateCourseOccasionsMutation,
  args: IGQLCreateCourseOccasionsMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyCourseOccasionsForCourse", {
    courseId: args.input.courseId,
  });
  if (Array.isArray(list)) {
    list.push(...result.jrnyCreateCourseOccasions as any);
    cache.link(
      "Query",
      "jrnyCourseOccasionsForCourse",
      { courseId: args.input.courseId },
      list as any
    );
  }
};
mutationUpdates.jrnyRemoveCourseOccasion = (
  result: IGQLRemoveCourseOccasionMutation,
  args: IGQLRemoveCourseOccasionMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyCourseOccasionsForCourse", {
    courseId: args.input.courseId,
  });
  if (Array.isArray(list)) {
    const id = "JrnyCourseOccasion:" + result.jrnyRemoveCourseOccasion.id;
    cache.link(
      "Query",
      "jrnyCourseOccasionsForCourse",
      { courseId: args.input.courseId },
      (list as string[]).filter((i) => i != id)
    );
  }
};

export {};
