import {TopNavigation} from '@/top-navigation';
import AppAppBar from '@components/AppAppBar';
import AppForm from '@components/AppForm';
import FormButton from '@components/form/FormButton';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import {email, required} from '@components/form/validation';
import Link from '@components/Link';
import Typography from '@components/Typography';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import debugFn from 'debug'
import {FORM_ERROR} from 'final-form';
import * as React from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {useJrnyLoginMutation, useTestOffLimitQuery} from '@graphql/login-hook';
import {useCurrentUser} from "@/context/CurrentUserProvider";

const debug = debugFn('SignIn')


function SignIn() {
  const [, doLogin] = useJrnyLoginMutation();
  const [, doTest] = useTestOffLimitQuery({pause: true});
  const {currentUser, setCurrentUser, signOut} = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()
  let from = (location.state as { from: Location })?.from?.pathname || TopNavigation.Admin;

  const validate = (values: { [index: string]: string }) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async (values: { email: string, password: string }) => {
    debug('Signing in', values)
    const result = await doLogin({input: values})

    debug('Login result', result)
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message}
    }
    await setCurrentUser((result.data?.jrnyLogin) || null)
    debug('Login success, navigating to', from)
    navigate(from, {replace: true})
    return null
  };
  const json = JSON.stringify(currentUser)
  return (
    <React.Fragment>
      <AppAppBar/>
      <div>{json}</div>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{submitting: true}}
          validate={validate}
        >
          {({handleSubmit: handleSubmit2, submitting}) => (
            <Box component="form" onSubmit={handleSubmit2} noValidate sx={{mt: 6}}>
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={submitting}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{submitError: true}}>
                {({submitError}) => {
                  debug('submitError', submitError)
                  return submitError ? (
                    <FormFeedback error sx={{mt: 2}}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }}
              </FormSpy>
              <FormButton
                sx={{mt: 3, mb: 2}}
                disabled={submitting}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting ? 'In progress…' : 'Sign In'}
              </FormButton>
            </Box>
          )}
        </Form>
        <Typography align="center">
          <Link underline="always" to={TopNavigation.ForgotPassword}>
            Forgot password?
          </Link>
          <Button onClick={doTest}>TEST</Button>
        </Typography>
      </AppForm>
    </React.Fragment>
  );
}

export default SignIn;
